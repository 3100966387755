import React from 'react';
import { useState, useEffect } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { format } from "date-fns";
import {NavLink, useParams, useHistory }  from "react-router-dom";
import UserService from '../../services/UserService'
import axios from 'axios';
import {FormattedMessage} from 'react-intl';

const Files = () => {

  const {id} = useParams();

  const [totalPost, setTotalPost] = useState([])
  const [data, addImages] = useState([])
  const [page, setPage] = useState(0)  
  const [size] = useState(5)
  const [sort, setSort] = useState("uploadDate")
  const [filter, setFilter] = useState("")
  const [hideUploadComponent, setHideUploadComponent] = useState(true);
  const [hideButtonComponent, setHideButtonComponent] = useState(true);
  const [hideMessageComponent, setHideMessageComponent] = useState(true);
  const [hideErrorComponent, setHideErrorComponent] = useState(true);
  const [delId,setDelId] = useState("")
  const [delImageName, setDelImageName] = useState("")
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  

  const handleChange = (file) => {
    onFileUpload(file);
  };

  let history = useHistory();
  
  useEffect(() => {
    
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES, { params: {pageIndex: page, pageSize: size, sortBy: sort, filter: filter}})
      .then(res => {
        getTotalNumberOfFiles(filter)
        addImages(res.data);
        if(id === "1") setHideButtonComponent(false)
      })
    },[id]);
  
  function getTotalNumberOfFiles(nfilter) {
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES + "/numFiles", { params: {filter: nfilter}})
    .then((response) => {
      setTotalPost(response.data);
    })
  }

  function onFileUpload(file){
      
    if(valFileType(file.name)){
      const formData = new FormData();

      formData.append('filedata', file);
      formData.append('filename', file.name);

      
      const html = axios.create({
        baseURL: process.env.REACT_APP_URL_FULL_FILES,
        headers: {
          //Fix auth problem
            "Content-Security-Policy": "frame-ancestors none",
              //
          "Content-type": "application/json",
          "Authorization": "Bearer " + UserService.getToken()
        }
      });
      setShow(true);
      html.post(process.env.REACT_APP_URL_FULL_FILES, formData)
        .then(response => goToPage(0))
        .catch(error  =>  {
          showMsgError(error.response.status);
          setShow(false);
        });
    }else showMsgError();
  }
  function valFileType(fileName){
    var fileType = fileName.substring(fileName.lastIndexOf(".")+1);
    var validTypes = ["jpg", "png", "gif", "tiff", "heic", "avi", "mp4"];
    if(validTypes.includes(fileType)) return true;
    else return false;
  }
  function showMsgError(msgCode){
    if(msgCode === 422) setErrorMsg(<FormattedMessage id="media.typeFile" defaultMessage="El tipo de fichero no está entre los formatos aceptados."/>)
    else if(msgCode === 423) setErrorMsg(<FormattedMessage id="media.typeSize" defaultMessage="El fichero excede el tamaño máximo."/>)
    else if(msgCode === 424) setErrorMsg(<FormattedMessage id="media.typeName" defaultMessage="Ya existe un fichero con el mismo nombre."/>)
    else setErrorMsg(<FormattedMessage id="media.typeFile" defaultMessage="El tipo de fichero no está entre los formatos aceptados."/>)
    setHideErrorComponent(false)
  }
  function showDeleteMessage(id, imageName){
    setShow(true)
    setHideMessageComponent(false);
    setDelId(id);
    setDelImageName(imageName);
  }
  function hideDeleteMessage(){
    setShow(false)
    setHideMessageComponent(true);
  }

  function deleteFile(id, fileName) {
    setShow(false)
    const html = axios.create({
        baseURL: process.env.REACT_APP_URL_FULL_FILES,
        headers: {
          //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
          "Content-type": "application/json",
          "Authorization": "Bearer " + UserService.getToken()
        }
      }); 
    html.put(process.env.REACT_APP_URL_FULL_FILES, null, { params: {id, fileName}}).then(response => goToPage(0));
    setHideMessageComponent(true);
  }

  function goToPage(nPage) {
    setShow(false);
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES, { params: {pageIndex: nPage, pageSize: size, sortBy: sort, filter: filter}})
      .then(res => {
        getTotalNumberOfFiles(filter)
        setPage(nPage)
        addImages(res.data);
      })
  }
  function goToSort(nPage, nSort){
    setSort(nSort)
    const html = axios.create({
      baseURL: process.env.REACT_APP_URL_FULL_FILES,
      headers: {
        //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES, { params: {pageIndex: nPage, pageSize: size, sortBy: nSort, filter: filter}})
      .then(res => {
        getTotalNumberOfFiles(filter)
        setPage(nPage)
        addImages(res.data);
      })
  }
  function goToFilter(nPage, nFilter){
    const html = axios.create({
        baseURL: process.env.REACT_APP_URL_FULL_FILES,
        headers: {
          //Fix auth problem
             "Content-Security-Policy": "frame-ancestors none",
              //
          "Content-type": "application/json",
          "Authorization": "Bearer " + UserService.getToken()
        }
    });
    html.get(process.env.REACT_APP_URL_FULL_FILES, { params: {pageIndex: nPage, pageSize: size, sortBy: sort, filter: nFilter}})
        .then(res => {
          getTotalNumberOfFiles(nFilter)
          setPage(nPage)
          setFilter(nFilter)
          addImages(res.data);
    })
  }
  const pageNumbers = [];
  function nextList() {
    var pageNumber = pageNumbers.length + 1;
    if(pageNumber === Number(page)+1){
      goToPage(Number(page));
    }else{
      goToPage(Number(page)+1);
    }
  }
  function prevList() {
    goToPage(Number(page)-1);
  }

  function formateDate(uploadDate){
    var date = new Date(uploadDate);    
    return  format(date, "dd-MM-yyyy");
  }
  
  function fileClass(fileName){
    var fileType = fileName.substring(fileName.lastIndexOf(".")+1);
    var imageTypes = ["jpg", "jpeg", "png", "gif", "tiff", "heic"];
    var videoTypes = ["avi", "mp4"];
    if(imageTypes.includes(fileType)) return "Imagen";
    else if(videoTypes.includes(fileType)) return "Video"
    else return  "-";
  }

  function selectImage(value){
      window["urlImage"] = value
      history.goBack()
  }

  let emptyTable;

  if(data.length >0 ){
    emptyTable = 
      data.map(item => {
        return (
          <tr key={item.imageName}>
            <td><img className="thumbnailImageMini" src={item.urlImageMini} alt={item.imageName} /></td>
            <td className="center aligned row">{item.imageName.replace(".min","")}</td>
            <td className="center aligned row">{fileClass(item.imageName)}</td>
            <td className="center aligned row">{formateDate(item.uploadDate)}</td>
            <td>
              <NavLink to={`view-file/${item.id}`}>
              <button className="button-icon"><i className="ui base-3 eye icon"></i></button>
              </NavLink>
              <button className="button-icon" onClick={() => showDeleteMessage(item.id, item.imageName)}><i className="ui base-3 trash alternate outline icon"></i></button>
            </td>
            <td className={`${hideButtonComponent ? "hide-buttonComponent" : "buttonComponent"}`}>
              <button className="button-icon" onClick={() => selectImage(item.urlImage)}><i className="ui base-3 check icon"></i></button>
            </td>
          </tr>
        );
      });
  }else{
    
    pageNumbers.splice(1);
    emptyTable = <tr><td colSpan="5" style={{textAlign: 'center', fontSize: '25px', padding: '20px'}}>
    <FormattedMessage id="mediaManager.noexistenficherosmedia" defaultMessage={"No existen archivos media"}/></td></tr>;

  }
  
  return loadTable()

  function loadTable() {

      for (let i = 1; i <= Math.ceil(totalPost / size); i++) {
        pageNumbers.push(i);
      }
      const BackDrop = ({show}) => 
      <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>
      return (
          <div className="mainDiv">
            <label className="title" style={{paddingTop: 10}}>
              
              <FormattedMessage id="media.mediaManager" defaultMessage="Media Manager" />
            </label>
            <div className='headerLine'>
                <div>
                  <button className="ui lantik-color-button button" onClick={() => setHideUploadComponent(!hideUploadComponent)}>
                  <FormattedMessage id="media.anadirMedia" defaultMessage="Añadir Media"/>
                  </button>
                  <span style={{paddingLeft: 550}}>
                  <select value={filter} className="ui select" name="select" onChange={e => goToFilter(0,e.target.value)} >
                    <FormattedMessage id="media.todo" defaultMessage="Todo">
                      {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="media.imagen" defaultMessage="Imagen">
                      {(message) => <option value="Image">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="media.video" defaultMessage="Video">
                      {(message) => <option value="Video">{message}</option>}
                    </FormattedMessage>
                  </select>
                  <select value={sort} className="ui select" name="select" onChange={e => goToSort(0,e.target.value)} >
                    <FormattedMessage id="media.fecha" defaultMessage="Fecha">
                      {(message) => <option value="uploadDate">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="media.nombre" defaultMessage="Nombre">
                      {(message) => <option value="imageName">{message}</option>}
                    </FormattedMessage>
                  </select>
                  </span>
                </div>
                <div className={`${hideUploadComponent ? "hide-uploadComponent" : "uploadComponent"}`}>
                  <FileUploader handleChange={handleChange} name="file" />
                </div>
            </div>
            <div className='paddingTableNews '>
            <table className="ui table">
            <thead>
              <tr className="center aligned row">
                <th></th>
                <th><FormattedMessage id="media.nombre" defaultMessage="Nombre"/></th>
                <th><FormattedMessage id="media.tipo" defaultMessage="Tipo"/></th>
                <th><FormattedMessage id="media.fecha" defaultMessage="Fecha"/></th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                {emptyTable}
              </tbody>
            </table>
            </div>
            <div className="content" style={{textAlign:"center"}}>
            <div>
              <button className="button-icon" onClick={prevList}><i className="ui chevron left icon"></i></button>
              {pageNumbers.map(number => (
                page === (number-1) ? <button className="ui lantik-color-button button" style={{padding:'auto'}} key={{number}} onClick={() => goToPage(number-1)}>{number}</button> : <button className="button-icon" key={{number}} onClick={() => goToPage(number-1)}>{number}</button> 
              ))}
              <button className="button-icon" onClick={nextList} ><i className="ui chevron right icon"></i></button>
            </div>
            </div>
            <div className={`${hideButtonComponent ? "hide-buttonComponent" : "buttonComponent"}`}>
            <button className="ui noBorder button" onClick={() => history.goBack()}>Cancelar</button>
            </div>
            <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
              <div><span>{errorMsg}</span></div>
              <div>
                  <button className="ui lantik-color-button button" onClick={() => setHideErrorComponent(true)}>
                      <FormattedMessage id="media.aceptar" defaultMessage="Aceptar"/>
                  </button>
              </div>
            </div>
            <BackDrop show={show}></BackDrop> 
            <img className={`${show ? "loading" : "hide-MessageComponent"}`} alt=""></img>
            <div className={`${hideMessageComponent ? "hide-MessageComponent" : "MessageComponent"}`}>
              <div><span className='msgHead'><i className="ui headIcon trash alternate outline icon"></i></span></div>
              <div className='msgBody'>
                <span>
                    <label>
                      <FormattedMessage id="media.mensajeEliminar1" defaultMessage="¿Seguro que quiere eliminar este fichero?"/>
                    </label>
                    <br></br>
                    <FormattedMessage id="media.mensajeEliminar2" defaultMessage="Esta acción es irrversible, por lo que se perderán todos los datos."/>
                </span>
              </div>
              <div className='msgBottom'>
                <span>               
                <button className="ui noBorder button" onClick={() => hideDeleteMessage(true)}>
                  <FormattedMessage id="media.cancelar" defaultMessage="Cancelar"/>
                </button>
                <button className="ui lantik-color-button button" onClick={() => deleteFile(delId, delImageName)}>
                 <FormattedMessage id="media.eliminar" defaultMessage="Eliminar"/>
                </button>
                </span>
              </div>
          </div>
        </div>
      );
  }
}

export default Files;
