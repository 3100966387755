import React, {useState} from 'react'; 
import { useParams } from 'react-router-dom';
import { requestGetBeaches, requestGetBeachEdit } from '../../services/BeachesService';
import {FormattedMessage} from 'react-intl';
import {useHistory} from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import { useIntl } from 'react-intl';


  const ViewBeaches = () => {

    const FIELDS = {
      ID: "idExternal",
      BEACH_NAME: "beachName",
      CITY_NAME: "cityName",
      HK_IMAGE: "image",
      INPUT_PHOTOGRAPH: "photograph",
      SHOW_CUSTOM_IMAGE: "showCustomImage",
      CUSTOM_IMAGE: "customMainMobileImage",
  };

  const getBeachesInitialState = () => {
    return {
        [FIELDS.ID]: 0, // Hardcode ID biblioteca
        [FIELDS.BEACH_NAME]: "",
        [FIELDS.CITY_NAME]: "",
        [FIELDS.HK_IMAGE]: null,
        [FIELDS.INPUT_PHOTOGRAPH]: "",
        [FIELDS.SHOW_CUSTOM_IMAGE]: false,
        [FIELDS.CUSTOM_IMAGE]: null
    };
};


  const {id} = useParams();
  const intl = useIntl();
  const [beaches, setBeaches] = useState(getBeachesInitialState());
  
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [viewBeaches, setViewBeaches] = useState(null);
  const [iconFlag, setIconFlag] = useState("ui icon icon-flag-grey");
  const [iconWeather, setIconWeather] = useState("ui icon icon-weather-NoData");
  const [iconWind, setIconWind] = useState("ui icon icon-wind-noData");
  const [iconOccupation, setIconOccupation] = useState("ui icon icon-occupation-noData");
  const [iconParking, setIconParking] = useState("ui icon icon-parking-noData");
  const [iconWaves, setIconWaves] = useState("ui icon icon-waves-noData");
  const [iconTemp, setIconTemp] = useState("ui icon icon-temperature-NoData");
  const [language, setLanguage] = useState("ui icon icon-temperature-NoData");
  let history = useHistory();

  React.useEffect(() => {
      setLanguage(localStorage.getItem('lang'));
      requestGetBeaches(setViewBeaches, setIconsClasses, id);
      getBeachData();
  },[id])

  const getBeachData = () => {
    requestGetBeachEdit(id)
        .then(result => {
            console.log("getBeaches(): ");
            console.log(result);
            let beachesData = result.data;
    
            console.log("beachesData:");
            console.log(beachesData);

            setBeaches(beachesData);
        }).catch(error => {
            showErrorAlert(error, "Error al obtener la configuración de playas: ");                  
        });
  };

  function setIconsClasses(res){
    if(res.flag.code === "02") setIconFlag("ui icon icon-flag-green");
    else if(res.flag.code === "01") setIconFlag("ui icon icon-flag-red");
    else if(res.flag.code === "03") setIconFlag("ui icon icon-flag-yellow");

    if(res.weather.code === "01") setIconWeather("ui icon icon-cloud");
    else if(res.weather.code === "00") setIconWeather("ui icon icon-variable");
    else if(res.weather.code === "02") setIconWeather("ui icon icon-rain");
    else if(res.weather.code === "03") setIconWeather("ui icon icon-sun");

    if(res.wind.code === "01") setIconWind("ui icon icon-wind-low");
    else if(res.wind.code === "02") setIconWind("ui icon icon-wind-mod");
    else if(res.wind.code === "03") setIconWind("ui icon icon-wind-strong");

    if(res.occupation.code === "03") setIconOccupation("ui icon icon-occupation-high");
    else if(res.occupation.code === "01") setIconOccupation("ui icon icon-occupation-low");
    else if(res.occupation.code === "02") setIconOccupation("ui icon icon-occupation-medium");

    if(res.parkingEs === "Completo") setIconParking("ui icon icon-parking-full");
    else if(res.parkingEs === "Baja") setIconParking("ui icon icon-parking-low");
    else if(res.parkingEs === "Media") setIconParking("ui icon icon-parking-mid");
    else if(res.parkingEs === "SinRegistro") setIconParking("ui icon icon-parking-not");

    if(res.waves.code === "01") setIconWaves("ui icon icon-waves-low");
    else if(res.waves.code  === "02") setIconWaves("ui icon icon-waves-mod");
    else if(res.waves.code  === "03") setIconWaves("ui icon icon-waves-strong");

    else if(res.ambientTemperature.code !== "") setIconTemp("ui icon icon-temperature");
  }

  function cancel(){
        history.goBack();
  }

  function handleSelect(){
    history.push("/beaches");
  }

  const showErrorAlert = (error, errorMsg) => {
    console.log(error);
    if(error.response.status == 0){
        errorMsg += error.code + " " + error.message;
    }else{
        errorMsg +=  error.response.status + " " + error.response.statusText;
    }
    setErrorMessage(errorMsg);
    setShowErrorMessage(true); 
}
  
const getGeneralInfoSection = (generalInfo) => {
  return (
    <div className="section-container">
      <h2><FormattedMessage id="playas.informacionGeneral" defaultMessage="Información general"/></h2>
      <div className="general-info-grid-row-1">
        {
        <>
          {/* City name */}
          <div className="general-info-item">
            <img src={generalInfo.cityName.icon} alt="prohibición icono" className="info-icon" />
            <div className="info-text">
              <div className="info-title">
                <FormattedMessage id="playas.municipio" defaultMessage="Municipio"/>
              </div>
              <div className="info-value">{generalInfo.cityName.value}</div>
            </div>
          </div>

          {/* Longitude */}
          <div className="general-info-item">
            <img src={generalInfo.longitude.icon} alt="prohibición icono" className="info-icon" />
            <div className="info-text">
              <div className="info-title">
                <FormattedMessage id="playas.longitudTotal" defaultMessage="Longitud total"/>
              </div>
              <div className="info-value">{generalInfo.longitude.value} m</div>
            </div>
          </div>
          
          {/* Width */}
          <div className="general-info-item">
            <img src={generalInfo.width.icon} alt="prohibición icono" className="info-icon" />
            <div className="info-text">
              <div className="info-title">
                <FormattedMessage id="playas.anchura" defaultMessage="Anchura"/>
              </div>
              <div className="info-value">{generalInfo.width.value} m</div>
            </div>
          </div>

          {/* Grain size */}
          <div className="general-info-item">
            <img src={generalInfo.grainSize.icon} alt="prohibición icono" className="info-icon" />
            <div className="info-text">
              <div className="info-title">
                <FormattedMessage id="playas.granulometria" defaultMessage="Granulometría"/>
              </div>
              <div className="info-value">{generalInfo.grainSize.value}</div>
            </div>
          </div>

          </>
        }
      </div>
      <div className="general-info-grid-row-2">
        {
        <>

          {/* Waves */}
          <div className="general-info-item">
            <img src={generalInfo.waves.icon} alt="prohibición icono" className="info-icon" />
            <div className="info-text">
              <div className="info-title">
                <FormattedMessage id="playas.oleaje" defaultMessage="Oleaje"/>
              </div>
              <div className="info-value">{generalInfo.waves.value}</div>
            </div>
          </div>

          {/* HighTide Area */}
          <div className="general-info-item">
            <img src={generalInfo.highTideArea.icon} alt="prohibición icono" className="info-icon" />
            <div className="info-text">
              <div className="info-title">
                <FormattedMessage id="playas.areaPleamar" defaultMessage="Área pleamar"/>
              </div>
              <div className="info-value">{generalInfo.highTideArea.value} m²</div>
            </div>
          </div>

          {/* LowTide Area */}
          <div className="general-info-item">
            <img src={generalInfo.lowTideArea.icon} alt="prohibición icono" className="info-icon" />
            <div className="info-text">
              <div className="info-title">
                <FormattedMessage id="playas.areaBajamar" defaultMessage="Área bajamar"/>
              </div>
              <div className="info-value">{generalInfo.lowTideArea.value} m²</div>
            </div>
          </div>

        </>
        }
      </div>
    </div>
  );
};



  const getBansSection = (bans) => {    
    return (
      <>
        <div className="section-container">
          <h2><FormattedMessage id="playas.prohibiciones" defaultMessage="Prohibiciones"/></h2>
          <div className="ban-grid">
            {bans.map((ban, index) => (
                <div key={index} className="ban-item">
                    <img src={ban.icon} alt="prohibición icono" className="ban-icon" />
                    <span className="ban-text">{getParseBan(ban.code)}</span>
                </div>
            ))}
          </div>
        </div>
      </>
  );
  }


  const getActivitiesSection = (activities) => {    
    return (
      <>
        <div className="section-container">
          <h2><FormattedMessage id="playas.actividades" defaultMessage="Actividades"/></h2>
          <div className="activity-grid">
            {activities.map((activity, index) => (
                <div key={index} className="activity-item">
                    <i className="caret right icon activity-icon"></i>
                    <span className="activity-text">{activity.value}</span>
                </div>
            ))}
          </div>
        </div>
      </>
  );
  }


  const getWarningSection = (warning) => {    
    return (
      <>
        <div className="section-container">
          <h2><FormattedMessage id="playas.avisoInformativo" defaultMessage="Aviso informativo"/></h2>
          <div className="warning-grid">
              {(warning.code !== '00') ? 
              <>
                <div className="warning-item-warning">
                  <i className="exclamation triangle icon warning-icon large orange"></i>
                  <span className="warning-text">{warning.value}</span>
                </div>
              </> : 
              <>
                <div className="warning-item-info">
                  <i className="info circle icon warning-icon large blue"></i>
                  <span className="warning-text">
                    <FormattedMessage id="playas.noExisteAviso" defaultMessage="No existe ningún aviso"/>
                  </span> 
                </div>
              </>
              }
          </div>
        </div>
      </>
  );
  }


  const getEquipmentSection = (equipments, idText) => {
    return (
      <div className="section-container">
        <h2>{intl.formatMessage({ id: idText })}</h2>      
        <div className="equipment-grid">
          {equipments.map(equipment => (
            <div key={equipment.id} className="equipment-item">
              {(equipment.value !== 'true') 
              ? <div className="equipment-count">{equipment.value}</div> 
              : ""}
              <div className="equipment-name">{equipment.name}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const getWaterQualitySection = (waterQuality) => {

    return (
      <div className="section-container">
        <h2><FormattedMessage id="playas.calidadAgua" defaultMessage="Calidad del agua"/></h2>      
        {
        <>
        
      <div className="quality-content">
        <div className="icon-container">
          <div className="icon-quality-value">

          {(waterQuality.code == "03") ? 
            <> 
              <i className="ui icon icon-water-quality-star"></i>
              <i className="ui icon icon-water-quality-star"></i>
              <i className="ui icon icon-water-quality-star"></i>
            </> : (waterQuality.code == "02") ?
            <> 
              <i className="ui icon icon-water-quality-star"></i>
              <i className="ui icon icon-water-quality-star"></i>
            </> : (waterQuality.code == "01") ?
            <> 
              <i className="ui icon icon-water-quality-star"></i>
            </> : (waterQuality.code == "01") ?
            <> 
              <i className="ui icon icon-water-quality-line"></i>
            </>  
            : ""
          }
          </div>
          <div>
            <i className="ui icon icon-water-quality-wave"></i>
          </div>
        </div>
        <div className="quality-details">
          <div className="quality-item">
            <span><FormattedMessage id="playas.calidadAgua" defaultMessage="Excelente"/></span>
            <span className="stars">
              <i className="ui icon icon-water-quality-star"></i>
              <i className="ui icon icon-water-quality-star"></i>
              <i className="ui icon icon-water-quality-star"></i>
            </span>
          </div>
          <div className="quality-item">
            <span><FormattedMessage id="playas.buena" defaultMessage="Buena"/></span>
            <span className="stars">
              <i className="ui icon icon-water-quality-star"></i>
              <i className="ui icon icon-water-quality-star"></i>
            </span>
          </div>          
          <div className="quality-item">
            <span><FormattedMessage id="playas.suficiente" defaultMessage="Suficiente"/></span>
            <span className="stars">
              <i className="ui icon icon-water-quality-star"></i>
            </span>
          </div>   
          <div className="quality-item">
            <span><FormattedMessage id="playas.insuficiente" defaultMessage="Insuficiente"/></span>
            <span className="stars">
              <i className="ui icon icon-water-quality-line"></i>
            </span>
          </div>             

        </div>
      </div>
        </>
        }
      </div>
    );
  };

  const getServicesSection = (services) => {
    return (
        <div className="section-container">
        <h2><FormattedMessage id="playas.servicios" defaultMessage="Servicios"/></h2>
        <div className="services-grid">
        {
        <>
          {/* Disabled parking */}
          {(services.disabledParking.value !== '0' 
            && services.disabledParking.value !== 'false'
            && services.disabledParking.value !== '') ? 
              <>
                <div className="services-item">
                  <img src={services.disabledParking.icon} alt="prohibición icono" className="services-icon" />
                  <div className="services-title">
                    <FormattedMessage id="playas.aparcamientosDiscapacitados" defaultMessage="Aparcamientos discapacitados"/>
                  </div>
                </div>
              </>
              : ""}

          {/* Guarded swimming water */}
          {(services.guardedSwimmingWater.value !== '0' 
            && services.guardedSwimmingWater.value !== 'false'
            && services.guardedSwimmingWater.value !== '') ? 
              <>
                <div className="services-item">
                  <img src={services.guardedSwimmingWater.icon} alt="prohibición icono" className="services-icon" />
                  <div className="services-title">
                    <FormattedMessage id="playas.aguaBanoVigilada" defaultMessage="Agua baño vigilada"/>
                  </div>
                </div>
              </>
              : ""}

          {/* Hondartzainak */}
          {(services.hondartzainak.value !== '0' 
            && services.hondartzainak.value !== 'false'
            && services.hondartzainak.value !== '') ? 
              <>
                <div className="services-item">
                  <img src={services.hondartzainak.icon} alt="prohibición icono" className="services-icon" />
                  <div className="services-title">Hondartzainas</div>
                </div>
              </>
              : ""}

          {/* Lifeguard */}
          {(services.lifeguard.value !== '0' 
            && services.lifeguard.value !== 'false'
            && services.lifeguard.value !== '') ? 
              <>
                <div className="services-item">
                  <img src={services.lifeguard.icon} alt="prohibición icono" className="services-icon" />
                  <div className="services-title">{services.lifeguard.value}</div>
                </div>
              </>
              : ""}

          {/* Parking */}
          {(services.parking.value !== '0' 
            && services.parking.value !== 'false'
            && services.parking.value !== '') ? 
              <>
                <div className="services-item">
                  <img src={services.parking.icon} alt="prohibición icono" className="services-icon" />
                  <div className="services-title">{services.parking.value}</div>
                </div>
              </>
              : ""}

          {/* Sanitary Control */}
          {(services.sanitaryControl.value !== '0' 
            && services.sanitaryControl.value !== 'false'
            && services.sanitaryControl.value !== '') ? 
              <>
                <div className="services-item">
                  <img src={services.sanitaryControl.icon} alt="prohibición icono" className="services-icon" />
                  <div className="services-title">
                    <FormattedMessage id="playas.controlSanitario" defaultMessage="Control sanitario"/>
                  </div>
                </div>
              </>
              : ""}          

          {/* Zone Coordinator */}
          {(services.zoneCoordinator.value !== '0' 
            && services.zoneCoordinator.value !== 'false'
            && services.zoneCoordinator.value !== '') ? 
              <>
                <div className="services-item">
                  <img src={services.zoneCoordinator.icon} alt="prohibición icono" className="services-icon" />
                  <div className="services-title">
                    <FormattedMessage id="playas.coordinadorZona" defaultMessage="Coordinador de zona"/>
                  </div>
                </div>
              </>
              : ""}           

          </>
        }
        </div>
      </div>
    );
  };


  const getParseBan = (ban) => {
    switch (ban) {
      case "P02":
        return "Está prohibido la pesca en horario dispuesto (10h/21h)";
      case "P04":
        return "Está prohibido la acampada en la zona de playas";
      case "P05":
        return "Está prohibido el acceso a vehículos motorizados";
      case "P06":
        return "Está prohibido arrojar residuos fuera de las zonas autorizadas";
      case "P07":
        return "Está prohibido encender fuegos";
      case "P08":
        return "Está prohibido utilizar jabones en las duchas";
      case "P09":
        return "Están prohibidos los juegos o ejercicios molestos para las personas";
      case "P10":
        return "Está prohibido el acceso a animales domésticos";
      case "P11":
        return "Está prohibido realizar ruidos molestos";
      case "P15":
        return "Está prohibido el acceso a los animales";        
      default:
        return "";
    }
  }  


  return (
    <>
    <div className="ui large breadcrumb" style={{paddingBottom:15}}>
        <i className="home red icon"></i>
        <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="playas.tituloMinus" defaultMessage="Playas"/></a>
        <i className="right large angle icon divider"></i>
        <i className="section" >{(viewBeaches == null) ? "" : viewBeaches.beachName}</i>
    </div>
    <div className="mainDiv" style={{paddingTop: 15, paddingRight: 15}}>

      {/* Mensaje alerta error */}
      <Collapse in={showErrorMessage}>
          <Alert 
              style={{marginLeft: 10, marginRight: 10,  marginBottom: 15, display: 'flex', alignItems: 'center'}} 
              onClose={() => {setShowErrorMessage(false)}} 
              severity="error"
          >{errorMessage}
          </Alert>
      </Collapse>

      <div className='ui grid' style={{paddingBottom: 10}}>
        <div className="middle aligned content" >
          <div><h1 className='title'>{(viewBeaches == null) ? "" : viewBeaches.beachName}</h1></div>
        </div>
      </div>

      {viewBeaches != null ? (
        <>

        <div className="beach-header-container">
          <div className="beach-header-grid">
              <div className='beach-header-image'>
                <img src={viewBeaches.mainMobileImage} alt="Imagen" />
                <div className='beach-header-image-footer'>
                  <i className="camera icon"/>
                  <FormattedMessage id="playas.fotografo" defaultMessage="Fotógrafo" />
                  {beaches[FIELDS.INPUT_PHOTOGRAPH] != null ? (
                    <>
                      {(beaches[FIELDS.SHOW_CUSTOM_IMAGE]) ? beaches[FIELDS.INPUT_PHOTOGRAPH] : "Diputación foral de Bizkai"}
                    </>
                  ) : ("")}
                  
                  
                </div>
              </div>
              <div>
                {viewBeaches.warning != null ? getWarningSection(viewBeaches.warning) : ""}
                {viewBeaches.waterQuality != null ? getWaterQualitySection(viewBeaches.waterQuality) : ""}
              </div>
          </div>
        </div>

        {/* <div>

        </div> */}

        <div className="section-container">
          <h2><FormattedMessage id="playas.informacionBasica" defaultMessage="Información básica"/></h2>
          <div className="basic-info-grid-row-1">
            {
            <>
              {/* Beach name */}
              <div className="basic-info-item">
                <i className="basic-info-icon ui icon info large "></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.nombre" defaultMessage="Nombre"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.beachName}</div>
                </div>
              </div>

              {/* City name */}
              <div className="basic-info-item">
                <i className="basic-info-icon ui icon university large "></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.poblacion" defaultMessage="Población"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.generalInfo.cityName.value}</div>
                </div>
              </div>

              {/* Update date */}
              <div className="basic-info-item">
                <i className="basic-info-icon ui icon clock large "></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.fechaActualizacion" defaultMessage="Fecha actualización"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.updateTime.split(".")[0].replaceAll("-","/").replace(" ", " - ")}</div>
                </div>
              </div>

              {/* Wheather */}
              <div className="basic-info-item">
                <i className={"basic-info-icon " + iconWeather}></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.tiempo" defaultMessage="Tiempo"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.weather.value}</div>
                </div>
              </div>

              {/* Water temperature */}
              <div className="basic-info-item">
                <i className={"basic-info-icon " + iconTemp}></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.temperatura" defaultMessage="Temperatura"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.ambientTemperature.code+" ºC"}</div>
                </div>
              </div>

              {/* Wind */}
              <div className="basic-info-item">
                <i className={"basic-info-icon " + iconWind}></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.viento" defaultMessage="Viento"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.windSpeed} km/h</div>
                </div>
              </div>

              {/* Occupation */}
              <div className="basic-info-item">
                <i className={"basic-info-icon " + iconOccupation}></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.ocupacion" defaultMessage="Ocupación"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.occupation.value}</div>
                </div>
              </div>

              {/* Parking */}
              <div className="basic-info-item">
                <i className={"basic-info-icon " + iconParking}></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.aparcamiento" defaultMessage="Aparcamiento"/>                  
                  </div>
                  <div className="basic-info-value">
                    {(viewBeaches.services.parking.value) == '' ? "Sin datos" : viewBeaches.services.parking.value}
                  </div>
                </div>
              </div>

              {/* Assisted Bath */}
              <div className="basic-info-item">
                <i className="ui icon-asistido icon"></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.banoAsistido" defaultMessage="Baño asistido"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.assistedBath.value}</div>
                </div>
              </div>

              {/* Flag */}
              <div className="basic-info-item">
              <i className={"basic-info-icon " + iconFlag}></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.bandera" defaultMessage="Bandera"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.flag.value}</div>
                </div>
              </div>

              {/* Water temperature */}
              <div className="basic-info-item">
              <i className="ui icon-temp-water icon"></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.agua" defaultMessage="Agua"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.waterTemperature.value+" ºC"}</div>
                </div>
              </div>

              {/* Waves */}
              <div className="basic-info-item">
              <i className={"basic-info-icon " + iconWaves}></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.oleaje" defaultMessage="Oleaje"/>
                  </div>
                  <div className="basic-info-value">{viewBeaches.waves.value}</div>
                </div>
              </div>  

              {/* High tide */}
              <div className="basic-info-item">
              <i className="ui icon-hightide icon"></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.pleamar" defaultMessage="Pleamar"/>
                  </div>
                  <div className="basic-info-value">
                    {viewBeaches.highTide.highTideTime1+"h "}
                    {"("+viewBeaches.highTide.highTide1+"m) / "} 
                    {viewBeaches.highTide.highTideTime2+"h "} 
                    {"("+viewBeaches.highTide.highTide2+"m)"}                    
                  </div>
                </div>
              </div>      

              {/* Low tide */}
              <div className="basic-info-item">
              <i className="ui icon-hightide icon"></i>
                <div className="basic-info-text">
                  <div className="basic-info-title">
                    <FormattedMessage id="playas.bajamar" defaultMessage="Bajamar"/>
                  </div>
                  <div className="basic-info-value">
                  {viewBeaches.lowTide.lowTideTime1+"h "}
                  {"("+viewBeaches.lowTide.lowTide1+"m) / "}
                  {viewBeaches.lowTide.lowTideTime2+"h "}
                  {"("+viewBeaches.lowTide.lowTide2+"m)"}                
                  </div>
                </div>
              </div>                                                                             

            </>
            }
          </div>
        </div>
        </>
      ) : ("")}
      
    {viewBeaches != null && viewBeaches.generalInfo != null ? (
      <>
      {getGeneralInfoSection(viewBeaches.generalInfo)}
      </>
    ) : ("")}

    {viewBeaches != null && viewBeaches.activities != null && viewBeaches.activities.length > 0 ? (
      <>
      {getActivitiesSection(viewBeaches.activities)}
      </>
    ) : ("")}
 
    {viewBeaches != null && viewBeaches.services != null ? (
      <>
      {getServicesSection(viewBeaches.services)}
      </>
    ) : ("")}
    
    {viewBeaches != null && viewBeaches.beachEquipment != null && viewBeaches.beachEquipment.length > 0 ? (
      <>
      {getEquipmentSection(viewBeaches.beachEquipment, "playas.equipamientosPlaya")}
      </>
    ) : ("")}

    {viewBeaches != null && viewBeaches.buildingServiceEquipment != null && viewBeaches.buildingServiceEquipment.length > 0 ? (
      <>
      {getEquipmentSection(viewBeaches.buildingServiceEquipment, "playas.equipamientosEdificio")}
      </>
    ) : ("")}

    {viewBeaches != null && viewBeaches.supportEquipment != null && viewBeaches.supportEquipment.length > 0 ? (
      <>
      {getEquipmentSection(viewBeaches.supportEquipment, "playas.equipamientosAreaApoyo")}
      </>
    ) : ("")}

    {viewBeaches != null && viewBeaches.bans != null && viewBeaches.bans.length > 0 ? (
      <>
      {getBansSection(viewBeaches.bans)}
      </>
    ) : ("")}

    {/* Botón volver */}
    <div style={{ marginTop:25, marginLeft:14, marginBottom: 15}}>
      <button className="ui lantik-color-button button" onClick={() => cancel()}>
        <FormattedMessage id="playas.volver" defaultMessage="Volver" />
      </button>
    </div> 

  </div>
  </>
)}
         
export default ViewBeaches;
