import React, {useState} from 'react'; 
import { useParams, useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import {NavLink} from "react-router-dom";
import Moment from 'moment';
import axios from 'axios';
import UserService from '../../services/UserService'
import {requestGetNewById} from '../../services/NewsService';
import {FormattedMessage} from 'react-intl';

const ViewFeatured = () => {
  
    const {id} = useParams();

    const [viewFeature, setViewFeature] = useState([]) 
    const [hideNoticiaComponent, setHideNoticiaComponent] = useState(true);
    const [hideOtroComponent, setHideOtroComponent] = useState(true);
    const [hideVideoComponent, setHideVideoComponent] = useState(true);
    const [hideAppComponent, setHideAppComponent] = useState(false);
    const [hideFunComponent, setHideFunComponent] = useState(true);
    const [news, setNews] = useState("");

    let history = useHistory();

    React.useEffect(() => {
      getData();
    },[id])  

    const getData = async () => {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + UserService.getToken()
      }
      const url = process.env.REACT_APP_URL_FULL_FEATURES + `/${id}`; 
      const data = await axios.get(url, { 'headers':  headers })   
      const dataFeature = await data.data;
      setViewFeature(dataFeature);
      controlType(dataFeature.type);

      if(dataFeature.type === "NOTICIA") requestGetNewById(setNews, dataFeature.idNoticia);

    } 

    function copyTextToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
          return navigator.clipboard.writeText(text);
      } else {
          let textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
              document.execCommand('copy') ? res() : rej();
              textArea.remove();
          });
      }
    }

    function controlType(type){
      
      if(type === "NOTICIA"){
          setHideNoticiaComponent(false)
          setHideOtroComponent(true)
          setHideAppComponent(true)
          setHideVideoComponent(true)
          setHideFunComponent(true)
      }else if(type === "OTRO"){
          setHideNoticiaComponent(true)
          setHideOtroComponent(false)
          setHideAppComponent(true)
          setHideVideoComponent(true)
          setHideFunComponent(true)
      }else if(type === "VIDEO"){
          setHideNoticiaComponent(true)
          setHideOtroComponent(true)
          setHideAppComponent(true)
          setHideVideoComponent(false)
          setHideFunComponent(true)
      }else if(type === "FUN_APP"){
          setHideNoticiaComponent(true)
          setHideOtroComponent(true)
          setHideAppComponent(true)
          setHideVideoComponent(true)
          setHideFunComponent(false)
      }else{
          setHideNoticiaComponent(true)
          setHideOtroComponent(true)
          setHideAppComponent(false)
          setHideVideoComponent(true)
          setHideFunComponent(true)
      }
    }

    function controlFunApp(idFun){

      if(idFun === "agendaFragment") return "Agenda Cultural";
      else if(idFun === "servicesFragment") return "Servicios";
      else if(idFun === "beachesFragment") return "Playas";
      else if(idFun === "planesFragment") return "Planes y Rutas";
      else if(idFun === "piscinasFragment") return "Piscinas";
      else if(idFun === "noticiasFragment") return "Noticias";
      else return "";
    }

    function controlDate(date){

      if(date !== "" && date !== undefined && date !== null) return Moment(date).format("DD/MM/YYYY  HH:mm:ss");
      else return "-";

    }
    function handleSelect(){
      history.push("/featured");
    }
    return (
      <>
      <div className="ui large breadcrumb" style={{paddingBottom:15}}>
          <i className="home red icon"></i>
          <a className="section" style={{color: "red"}} onClick={handleSelect}><FormattedMessage id="destacados.destacados" defaultMessage="Destacados"/></a>
          <i className="right large angle icon divider"></i>
          <i className="section" >{viewFeature.titleEs}</i>
      </div>
      <div className="mainDiv">
          <div className='ui grid'>
            <div className="middle aligned content">
              <div><h1 className='title' key={viewFeature.id}>{viewFeature.titleEs}</h1></div>
            </div>
          </div>
            <div className="posLeft">
              <div className='ui grid' >
                <div className="ui divided items">
                  <Popup trigger={<button className="search-icon" ><i className="ui white search icon"></i></button>}>
                    <img style={{width:500}} src={viewFeature.imageUrl} alt="Imagen" />
                  </Popup>
                  <img style={{width:400, paddingLeft:16, float:"left"}} src={viewFeature.imageUrl} alt="Imagen" />
                </div>
              </div>
            </div>
            <div className="posRight">
              <div className='ui grid'>
                <table className='playasTable'>
                <tbody>
                  <tr>
                    <td><label><FormattedMessage id="destacados.detalle.estado" defaultMessage="ESTADO"/></label></td>
                    <td><label><FormattedMessage id="destacados.detalle.orden" defaultMessage="ORDEN"/></label></td>
                  </tr>
                  <tr>
                    <td>{viewFeature.active}</td>
                    <td>{viewFeature.priority}</td>
                  </tr>
                  <tr>
                    <td><label><FormattedMessage id="destacados.detalle.fechPublica" defaultMessage="FECHA PUBLICACIÓN"/></label></td>
                    <td><label><FormattedMessage id="destacados.detalle.fechExpirac" defaultMessage="FECHA EXPIRACIÓN"/></label></td>
                  </tr>
                  <tr>
                    <td>{controlDate(viewFeature.publishingDate)}</td>
                    <td>{controlDate(viewFeature.expirationDate)}</td>
                  </tr>
                  <tr>
                    <td><label><FormattedMessage id="destacados.detalle.fechUltiActu" defaultMessage="FECHA ÚLTIMA ACTUALIZACIÓN"/></label></td>
                    <td><label><FormattedMessage id="destacados.detalle.tipo" defaultMessage="TIPO"/></label></td>
                  </tr>
                  <tr>
                    <td>{Moment(viewFeature.creationDate).format("DD/MM/YYYY")}</td>
                    <td>{viewFeature.type}</td>
                  </tr>
                  <tr className={`${hideOtroComponent ? "hide-OtroComponent" : ""}`}>
                    <td>
                      <label><FormattedMessage id="destacados.detalle.otro" defaultMessage="OTRO (ENLACES EXTERNOS)"/></label>
                      <button className="button-icon" onClick={() => copyTextToClipboard(viewFeature.linkUrl)}><i className="ui red copy icon"></i></button>
                    </td>
                  </tr>
                  <tr className={`${hideOtroComponent ? "hide-OtroComponent" : ""}`}>
                    <td>{viewFeature.linkUrlEs}</td>
                  </tr>
                  <tr className={`${hideVideoComponent ? "hide-VideoComponent" : ""}`}>
                    <td>
                      <label><FormattedMessage id="destacados.detalle.video" defaultMessage="Video (ENLACES EXTERNOS)"/></label>
                      <button className="button-icon" onClick={() => copyTextToClipboard(viewFeature.linkUrl)}><i className="ui red copy icon"></i></button>
                    </td>
                  </tr>
                  <tr className={`${hideVideoComponent ? "hide-VideoComponent" : ""}`}>
                    <td>{viewFeature.linkUrlEs}</td>
                  </tr>
                  <tr className={`${hideNoticiaComponent ? "hide-NoticiaComponent" : ""}`}>
                    <td>
                      <label><FormattedMessage id="destacados.detalle.titulo" defaultMessage="Titulo"/></label>
                    </td>
                  </tr>
                  <tr className={`${hideNoticiaComponent ? "hide-NoticiaComponent" : ""}`}>
                    <td>{news.titleEs}</td>
                  </tr>
                  <tr className={`${hideAppComponent ? "hide-AppComponent" : ""}`}>
                    <td>
                      <label><FormattedMessage id="destacados.detalle.appDiputacion" defaultMessage="App de la Diputación"/></label>
                    </td>
                  </tr>
                  <tr className={`${hideAppComponent ? "hide-AppComponent" : ""}`}>
                    <td>
                      <label><FormattedMessage id="destacados.detalle.android" defaultMessage="Android"/>: </label>{viewFeature.txtAndroid} - 
                      <label><FormattedMessage id="destacados.detalle.ios" defaultMessage="Ios"/>: </label>{viewFeature.txtIos}
                    </td>
                  </tr>
                  <tr className={`${hideFunComponent ? "hide-FunComponent" : ""}`}>
                    <td>
                      <label><FormattedMessage id="destacados.detalle.funcionalidad" defaultMessage="Funcionalidad"/></label>
                    </td>
                  </tr>
                  <tr className={`${hideFunComponent ? "hide-FunComponent" : ""}`}>
                    <td>{controlFunApp(viewFeature.idFun)}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
          </div>
        <div className='grid' style={{marginTop:250, marginLeft:15, marginBottom:15}}>
            <NavLink to="/featured">
              <button className="ui lantik-color-button button">
                <FormattedMessage id="destacados.detalle.atras" defaultMessage="Atras"/>
              </button>
            </NavLink>
        </div>
      </div>
      </>
  )
   
}

export default ViewFeatured;