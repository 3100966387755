import UserService from './UserService';
import axios from "axios";

export const requestGetNewSearch = async (state, page, size, beachName, cityName) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES_NEW + "/backofficeList";
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        } 
    })
    
   await authAxios.get(apiUrl, { params: {page: page, size: size, beachName: beachName, cityName: cityName}})
   .then(response =>  {
    console.log("####################");
    console.log(response.data);
       state(response.data);
    }).catch(error  =>  {
        console.log(error);
    }) 

}


export const requestGetBeaches = async (state, setIconsClasses, id) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        }  
    })
 
   await authAxios.get(process.env.REACT_APP_URL_FULL_BEACHES_NEW+`/${id}`,id)
   .then(response =>  {
    console.log("####################");
    console.log(response.data);
        state(response.data);
        setIconsClasses(response.data);
   }).catch(error  =>  {
        console.log(error);
    }) 
}


export const requestUpdateBeaches = async (body) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES_NEW;
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    await authAxios.put(process.env.REACT_APP_URL_FULL_BEACHES_NEW + "/update", body )
    .catch(error  =>  {
        console.log(error);
    }) 
}

export const requestGetConfiguration = async () => {
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES + '/configuration';
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        }  
    })
    return authAxios.get(apiUrl, "")
}

export const requestGetBeachEdit = async (id) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES_NEW + `/${id}` + '/edit';
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        }  
    })
    return authAxios.get(apiUrl, "")
}


export const requestUpdateConfiguration = async (body) => {
    const apiUrl = process.env.REACT_APP_URL_FULL_BEACHES + '/configuration';
    const authAxios = axios.create ({
        baseURL: apiUrl,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
    
        }  
    })

    await authAxios.post(process.env.REACT_APP_URL_FULL_BEACHES + '/configuration', body)
    .catch(error  =>  {
        console.log(error);
    }) 
}
