import React, { useContext, useState } from 'react';
import UserService from "../services/UserService";
import {langContext} from './../context/langContext';
import {FormattedMessage} from 'react-intl';

const TopBar = () => {
    const idioma = useContext(langContext);
    
    const [lastLanguage, setlastLanguage] = useState(idioma.localePorDefecto);

    const handleLangChange = (e) => {
        idioma.establecerLenguaje(e);
    };
    

    return (
        <div className="t-bar">
             <div className='item hamburger_icon'>
                 <i className='hamburger large icon' ></i>
            </div>
            <div className='dropdown-Lang-div'>               
                <select value={lastLanguage} style={{width:80}} name="gender" className="ui dropdown dropdown-Lang" onChange={event => {handleLangChange(event.target.value); setlastLanguage(event.target.value)}}>
                    <option value='es-ES'>ES</option>
                    <option value='eus-ES'>EUS</option>
                </select>
            </div>
            
            <div className="navbar-text navbar-right" style={{marginRight: 15,color:'white'}}>
                <FormattedMessage 
                    id="menu.logueadoComo"
                    defaultMessage={"Hola, " + UserService.getUsername()}
                    values={{name:UserService.getUsername()}}
                />
            </div>


            <p>
                <button className="ui lantik-color-button button" 
                        onClick={() => UserService.doLogout()}>
                    <FormattedMessage 
                        id="menu.cerrarSesion"
                        defaultMessage="Cerrar Sesión"
                    />
                </button>
            </p>


        </div>
    );
};

export default TopBar;