import React, { useState}   from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory, useParams} from "react-router-dom";
import SelectFile from "../media/SelectFile"
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import {requestUpdateBeaches, requestGetBeachEdit} from '../../services/BeachesService';
import { Button, Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useIntl } from 'react-intl';


const ImageBox = styled(Box)(({ theme, selected }) => ({
    border: selected ? `2px solid black` : `2px solid transparent`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    padding: theme.spacing(2),
  }));


const EditBeaches = () => {

    const FIELDS = {
        ID: "idExternal",
        BEACH_NAME: "beachName",
        CITY_NAME: "cityName",
        HK_IMAGE: "image",
        INPUT_PHOTOGRAPH: "photograph",
        SHOW_CUSTOM_IMAGE: "showCustomImage",
        CUSTOM_IMAGE: "customMainMobileImage",
    };

    const getBeachConfigInitialState = () => {
        return {
            [FIELDS.ID]: 0, // Hardcode ID biblioteca
            [FIELDS.BEACH_NAME]: "",
            [FIELDS.CITY_NAME]: "",
            [FIELDS.HK_IMAGE]: null,
            [FIELDS.INPUT_PHOTOGRAPH]: "",
            [FIELDS.SHOW_CUSTOM_IMAGE]: false,
            [FIELDS.CUSTOM_IMAGE]: null
        };
    };

    const getBeachConfigValidationInitialState = () =>{
        return {
            [FIELDS.INPUT_PHOTOGRAPH]: true,
            [FIELDS.CUSTOM_IMAGE]: true,
        };        
    }

    const {id} = useParams();    
    const intl = useIntl();
    const [hideGaleryComponent, setGaleryComponent] = useState(true);
    const [show, setShow] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [beachConfigValidation, setBeachConfigValidation] = useState(getBeachConfigValidationInitialState());
    const [beachConfig, setBeachConfig] = useState(getBeachConfigInitialState());
    let history = useHistory();

    React.useEffect(() => {
        getBeachData();
    },[])

    const getBeachData = () => {
        requestGetBeachEdit(id)
            .then(result => {
                console.log("getBeachConfig(): ");
                console.log(result);
                let beachConfigData = result.data;
        
                console.log("beachConfigData:");
                console.log(beachConfigData);

                setBeachConfig(beachConfigData);
            }).catch(error => {
                showErrorAlert(error, "Error al obtener la configuración de playas: ");                  
            });
    };

    const showSuccessAlert = (successMsg) => {
        setSuccessMessage(successMsg);
        setShowSuccessMessage(true);
        // Timer para ocultar el mensaje tras 5 segundos
        setTimeout (() => {
            setShowSuccessMessage(false);
        }, 5000); 
    }

    const showErrorAlert = (error, errorMsg) => {
        console.log(error);
        if(error.response.status == 0){
            errorMsg += error.code + " " + error.message;
        }else{
            errorMsg +=  error.response.status + " " + error.response.statusText;
        }
        setErrorMessage(errorMsg);
        setShowErrorMessage(true); 
    }

    function selectedImage(){
        let selImage = window["urlImage"]
        // let selImageMini = window["urlImageMini"];
        let updatedBeach = { ...beachConfig};
        updatedBeach[FIELDS.CUSTOM_IMAGE] = selImage;
        setBeachConfig(updatedBeach);
        setBeachConfigValidationValue(FIELDS.CUSTOM_IMAGE, selImage);
        hideGallery();
    }

    function downloadImage(url){
        window.open(url, '_blank');
    }

    function openMediaManager(){
        setShow(true);
        setGaleryComponent(false);
    }

    function deleteImage(){
        let updatedBeach = { ...beachConfig};
        updatedBeach[FIELDS.CUSTOM_IMAGE] = null;
        setBeachConfig(updatedBeach);
    }

    function hideGallery(){
        setShow(false);
        setGaleryComponent(true);
    }

    function handleGoBack(){
        history.push("/beaches");
    }

    const BackDrop = ({show}) => 
    <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>

    const handlePhotographChange = (event) => {
        const {value} = event.target;
        if (value.length <= 50) {
            let updatedBeach = { ...beachConfig};
            updatedBeach[FIELDS.INPUT_PHOTOGRAPH] = value;
            setBeachConfig(updatedBeach);
            setBeachConfigValidationValue(FIELDS.INPUT_PHOTOGRAPH, value);
        }
    };

    const handleSelectedImage = (imageSelected) => {
        let updatedBeach = { ...beachConfig};
        if(imageSelected === FIELDS.HK_IMAGE){
            updatedBeach[FIELDS.SHOW_CUSTOM_IMAGE] = false;
            setBeachConfigValidation(getBeachConfigValidationInitialState());
        }else if(imageSelected === FIELDS.CUSTOM_IMAGE){
            updatedBeach[FIELDS.SHOW_CUSTOM_IMAGE] = true;
            setBeachConfigValidationValue(FIELDS.INPUT_PHOTOGRAPH, beachConfig[FIELDS.INPUT_PHOTOGRAPH]);
            setBeachConfigValidationValue(FIELDS.CUSTOM_IMAGE, beachConfig[FIELDS.CUSTOM_IMAGE]);
        }
        setBeachConfig(updatedBeach);
    };


  const setBeachConfigValidationValue = (name, value) =>{
        if(name == FIELDS.INPUT_PHOTOGRAPH && value !== "") {
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_PHOTOGRAPH]: true }));
        }
        if(name == FIELDS.CUSTOM_IMAGE && value !== null){
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.CUSTOM_IMAGE]: true }));
        }     
    }

    const handleSave = () => {
        setBeachConfigValidation(getBeachConfigValidationInitialState());
        let isRequiredFieldsValidate = true;

        if(beachConfig[FIELDS.SHOW_CUSTOM_IMAGE]){
            if (beachConfig[FIELDS.INPUT_PHOTOGRAPH] === "" || beachConfig[FIELDS.INPUT_PHOTOGRAPH] === null) {
                setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_PHOTOGRAPH]: false }));
                isRequiredFieldsValidate = false;
            }
            if(beachConfig[FIELDS.CUSTOM_IMAGE] === null){
                setBeachConfigValidation(prev => ({ ...prev, [FIELDS.CUSTOM_IMAGE]: false }));
                isRequiredFieldsValidate = false;
            }
        }

        if (isRequiredFieldsValidate) {
            requestUpdateBeaches(beachConfig)
            .then(result =>{
                showSuccessAlert("Se ha actualizado la configuración de playas correctamente");              
            })
            .catch(error => {
                showErrorAlert(error, "Error al actualizar la configuración de playas: ");                  
            });
        }
    };


    return (
        <>
            <div className="ui large breadcrumb" style={{paddingBottom:15}}>
                <i className="home red icon"></i>
                <a className="section" style={{color: "red"}} onClick={handleGoBack}><FormattedMessage id="playas.tituloMinus" defaultMessage="Playas"/></a>
                <i className="right large angle icon divider"></i>
                <i className="section" ><FormattedMessage id="playas.editarPlaya" defaultMessage="Editar playa"/></i>
            </div>

            <div className='mainDiv' style={{paddingTop: 15, paddingRight: 15}}>

            {/* Mensaje alerta error */}
            <Collapse in={showErrorMessage}>
                <Alert 
                    style={{marginLeft: 10, marginRight: 10,  marginBottom: 15, display: 'flex', alignItems: 'center'}} 
                    onClose={() => {setShowErrorMessage(false)}} 
                    severity="error"
                >{errorMessage}
                </Alert>
            </Collapse>

            {/* Mensaje alerta exito */}
            <Collapse in={showSuccessMessage}>
                <Alert 
                    style={{marginLeft: 10, marginRight: 10, marginBottom: 15, display: 'flex', alignItems: 'center'}} 
                    onClose={() => {setShowSuccessMessage(false)}} 
                    severity="success"
                >{successMessage}
                </Alert>
            </Collapse>

            {/* Titulo */}
            <div style={{paddingTop: 10}}>
                <label className="title" >
                    <FormattedMessage id="null" defaultMessage={beachConfig[FIELDS.BEACH_NAME]} />
                </label>
            </div>

            <BackDrop show={show}></BackDrop> 
            <div className={`${hideGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
                <SelectFile></SelectFile>
                <div>
                    <button className="ui lantik-color-button button" onClick={() => selectedImage()}>
                        <FormattedMessage id="playas.guardar" defaultMessage="Guardar" />
                    </button>
                    <button className="ui noBorder button" onClick={() => hideGallery(true)}>
                        <FormattedMessage id="playas.cancelar" defaultMessage="Cancelar" />
                    </button>
                </div>        
            </div>

    <Grid container>
        <Grid item xs={6}>
          <ImageBox>
            {/* Titulo */}
            <div className='input-group ui form' style={{paddingBottom: 5}}>
                <label className="edit-beach-title" >
                    <FormattedMessage id="playas.imagenHK" defaultMessage={"Imagen por defecto de HK"} />
                </label>
            </div>
            <div className="beach-edit">
                <button className="edit-image-button download-button" onClick={() => downloadImage(beachConfig[FIELDS.HK_IMAGE])} >
                    <i className="ui white eye icon"></i>
                </button>
                <img src={beachConfig[FIELDS.HK_IMAGE]} />
            </div>

            <div className='image-group ui form' style={{paddingBottom: 10, paddingTop: 5}}>
                <label className='label baseline' htmlFor="default-photograph">
                    <i className="camera icon"/>
                    <FormattedMessage 
                        id="playas.fotografoDiputacion" 
                        defaultMessage="Fotógrafo: Diputación foral de Bizkai"/>
                    <input type="text" style={{width: '50px', visibility: 'hidden'}}/> 
                </label>
            </div>

            { (!beachConfig[FIELDS.SHOW_CUSTOM_IMAGE])
            ? <>
            <Button className='ui green button' fullWidth>
                <i className="ui white check circle icon"></i>
                <FormattedMessage id="playas.seleccionado" defaultMessage="Seleccionado"/>   
            </Button>
            </> : <>
            <Button className='ui blue button' onClick={() => handleSelectedImage(FIELDS.HK_IMAGE)} fullWidth>
                <FormattedMessage id="playas.seleccionar" defaultMessage="Seleccionar"/>   
            </Button>
            </> }

          </ImageBox>
        </Grid>

        <Grid item xs={6}>
          <ImageBox>
            {/* Titulo */}
            <div className='input-group ui form' style={{paddingBottom: 5}}>
                <label className="edit-beach-title" >
                    <FormattedMessage id="playas.imagenPersonalizada" defaultMessage="Imagen personalizada" />
                </label>
            </div>

            <div className="beach-edit" >
                {(beachConfig[FIELDS.CUSTOM_IMAGE] !== null) 
                ? <>
                    <div className='button-image-group'>
                        <button className="edit-image-button download-button" onClick={() => downloadImage(beachConfig[FIELDS.CUSTOM_IMAGE])} >
                            <i className="ui white download icon"></i>
                        </button>
                        <button className="edit-image-button edit-button" onClick={() => openMediaManager()}>
                            <i className="ui white pencil icon"></i>
                        </button>
                        <button className="edit-image-button delete-button" >
                            <i className="ui white trash icon" onClick={() => deleteImage()}></i>
                        </button>
                    </div>
                    <img src={beachConfig[FIELDS.CUSTOM_IMAGE]} />                     
                </>
                : <>
                    <button className="edit-image-button add-button" onClick={() => openMediaManager(false)}>
                        <i className="ui white plus icon"></i>
                    </button>
                    <img src={require('../../images/placeholder-image.png')} className={(beachConfigValidation[FIELDS.CUSTOM_IMAGE])?'':'required-image'} />
                </>
                }
            </div>

            <div className='image-group ui form' style={{paddingBottom: 10, paddingTop: 5}}>
                <label className='label baseline required' htmlFor="default-photograph"  >
                    <i className="camera icon"/>
                    <FormattedMessage 
                        id="playas.fotografo" 
                        defaultMessage="Fotógrafo:"/>
                </label>
                <input 
                    type="text" 
                    id={FIELDS.INPUT_PHOTOGRAPH}
                    name={FIELDS.INPUT_PHOTOGRAPH}
                    value={beachConfig[FIELDS.INPUT_PHOTOGRAPH]}
                    className={(beachConfigValidation[FIELDS.INPUT_PHOTOGRAPH])?'':'required-field'}
                    onChange={handlePhotographChange}
                    placeholder={intl.formatMessage({ id: 'playas.placeholderFotografia' })}
                /> 
            </div>

            { (beachConfig[FIELDS.SHOW_CUSTOM_IMAGE])
            ? <>
            <Button className='ui green button' fullWidth>
                <i className="ui white check circle icon"></i>
                <FormattedMessage id="playas.seleccionado" defaultMessage="Seleccionado"/>   
            </Button>
            </> : <>
            <Button className='ui blue button' onClick={() => handleSelectedImage(FIELDS.CUSTOM_IMAGE)} fullWidth>
                <FormattedMessage id="playas.seleccionar" defaultMessage="Seleccionar"/>   
            </Button>
            </> }
          </ImageBox>
        </Grid>
    </Grid>

    {/* Botón Guardar */}
    <div style={{marginLeft:14, marginBottom: 15}}>
        <button className="ui lantik-color-button button" onClick={() => handleSave()}>
            <FormattedMessage id="playas.guardar" defaultMessage="Guardar" />
        </button>
        <button className="ui noBorder button" onClick={() => handleGoBack()}>
            <FormattedMessage id="playas.volver" defaultMessage="Volver" />
        </button>
    </div>
    </div>

    </>
    )
}

export default EditBeaches