import React, { useState } from 'react';
import Menu from "../components/Menu";
import Main from "../components/Main";
import TopBar from "../components/TopBar";
import Swal from 'sweetalert2'; 
import UserService from '../services/UserService';


import Countdown from 'react-countdown';
import ModalSesion from './ModalSesion';
import ReactDOM from 'react-dom';


const funcionTimeout = () => {
    const sessionTime = 1000 * 60;
    const timerSesion = setTimeout (() => { 
        console.log('SE HA CADUCADO LA SESION');
        alert(UserService.checkTokenExpiration());
        
        let segundos = 10
        let timerInterval
        Swal.fire({
            title: 'Atención',
            icon: "warning",
            iconColor:"gold",
            //text: "La sesión está apunto de caducar",
            html: 'La sesión va a caducar en <b>' + segundos + '</b> segundos.',
            confirmButtonColor: "red",
            confirmButtonText: "Renovar sesión",
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 1000 * segundos,
            timerProgressBar: true,
            didOpen: () => {
                //Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = Math.ceil(Swal.getTimerLeft()/1000);
                }, 1000)
            },
            // willClose: () => {
            //     clearInterval(timerInterval);
            // }
        }).then((result) => {

            console.log(result);

            if(result.isConfirmed){
                clearInterval(timerSesion);
                // UserService.updateToken();
                UserService.refreshToken();
                funcionTimeout();
            }else if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                    icon: "warning",
                    title:"Atención",
                    text: "La sesión ha caducado",
                    iconColor:"red",
                    confirmButtonColor: "red",
                    confirmButtonText: "Entendido",
                }).then(function(isConfirm){
                    if(isConfirm){
                        // UserService.doLogout();
                    }
                }) 
            }else if(result.dismiss === 'backdrop'){
                console.log("backdrop");
            }
        })
    }, sessionTime); //1140000 //10000
}
// funcionTimeout();


// Default session control
setTimeout (() => { 
    console.log('SE HA CADUCADO LA SESION');
    
    Swal.fire({
    icon: "warning",
    title:"Atención",
    text: "La sesión ha caducado",
    isConfirmed: "aceptar",
    iconColor:"red",
    confirmButtonColor: "red",
    confirmButtonText: "Entendido",

    }).then(function(isConfirm){
    if(isConfirm){
        UserService.doLogout();
    }
    }) 
}, 1200000);



const Homepage = () => {
    return (
        <>
            <div className="container">
                <div className="main-sidebar">
                    <Menu/> 
                </div>

                <div className="main-top" >
                    <TopBar/>
                </div>

                <div className="main-content">
                    <Main/>
                </div>
            </div>
        </>
    );
};

export default Homepage;