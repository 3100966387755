import React, { useState, useEffect}   from 'react';
import {NavLink} from "react-router-dom";
import {requestGetNewSearch, requestGetConfiguration} from '../../services/BeachesService';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';
import BeachGrid from './BeachGrid';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

const Beaches = () => {

  const FIELDS = {
    ID: "id",
    INPUT_MENSAJE_CASTELLANO: "warningEs",
    INPUT_MENSAJE_EUSKERA: "warningEu",
    INPUT_FECHA_INICIO: "initialDate",
    INPUT_FECHA_FIN: "finalDate",
  };


  const [beaches, setBeaches] = useState([]);
  const [searchBeach, setSearchBeach] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [page, setPage] = useState(0);
  const [size] = useState(50);
  const [inSeason, setInSeason] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  

  useEffect(() => {
    requestGetNewSearch(setBeaches, page, size, searchBeach, searchCity);
    loadBeachesList();
  },[])


  const loadBeachesList = () => {
    requestGetConfiguration()
        .then(result => {
            console.log("loadBeachesList(): ");
            console.log(result);
            let beachConfigData = result.data[0];

            const formattedStartDate = moment(beachConfigData[FIELDS.INPUT_FECHA_INICIO]).format('YYYY-MM-DD');
            beachConfigData[FIELDS.INPUT_FECHA_INICIO] = formattedStartDate;
            
            const formattedEndDate = moment(beachConfigData[FIELDS.INPUT_FECHA_FIN]).format('YYYY-MM-DD');
            beachConfigData[FIELDS.INPUT_FECHA_FIN] = formattedEndDate;
            
            setSeasonMessage(beachConfigData);
        }).catch(error => {
            showErrorAlert(error, "Error al obtener la configuración de playas: ");                  
        });
  };


  const showErrorAlert = (error, errorMsg) => {
    console.log(error);
    if(error.response.status == 0){
        errorMsg += error.code + " " + error.message;
    }else{
        errorMsg +=  error.response.status + " " + error.response.statusText;
    }
    setErrorMessage(errorMsg);
    setShowErrorMessage(true); 
  } 


  const setSeasonMessage = (beachConfigData) => {
    console.log("setSeasonMessage()");
    console.log(beachConfigData);
    let momentCurrentDate = moment().startOf('day');
    let momentStartDate = moment(beachConfigData[FIELDS.INPUT_FECHA_INICIO]).startOf('day');
    let momentEndDate = moment(beachConfigData[FIELDS.INPUT_FECHA_FIN]).startOf('day');

    if(momentCurrentDate.isBefore(momentStartDate)){
        //  actual < inicio *** false 
        setInSeason(false);
    } else if(momentCurrentDate.isAfter(momentStartDate)){
        if(momentCurrentDate.isSame(momentEndDate)){
            //  actual > inicio & actual = fin *** true
            setInSeason(true);
        } else if(momentCurrentDate.isBefore(momentEndDate)){
            //  actual > inicio & actual < fin *** true
            setInSeason(true);                     
        } else if(momentCurrentDate.isAfter(momentEndDate)){
          //  actual > inicio & actual > fin *** false
            setInSeason(false); 
        }
    } else if(momentCurrentDate.isSame(momentStartDate)){
        setInSeason(true);
    }
}


return (
<>
  <div className='mainDiv' style={{paddingTop: 20}}>

    {/* Mensaje alerta error */}
    <Collapse in={showErrorMessage}>
      <Alert 
          style={{marginLeft: 10, marginRight: 10,  marginBottom: 15, display: 'flex', alignItems: 'center'}} 
          onClose={() => {setShowErrorMessage(false)}} 
          severity="error"
      >{errorMessage}
      </Alert>
    </Collapse>

    <div className='input-group' style={{gap: 10}}>

      {/* Titulo */}
      <label className="title">
          <FormattedMessage id="playas.titulo" defaultMessage="Playas" />
      </label>

      {/* Estado temporada */}
      <div className={(inSeason) ? "inSeasson" : "outSeasson"}>
        <i className={(inSeason) ? "icon-check-green icon" : "icon-alert yellow icon"} ></i>
        <label className=''>
        {inSeason ? (
          <FormattedMessage id="playas.enTemporada" defaultMessage="En temporada" />
        ) : (
          <FormattedMessage id="playas.fueraTemporada" defaultMessage="Fuera de temporada" />
        )}
        </label>
      </div>

      {/* Botón configurar */}
      <NavLink to={"conf-beaches"}>
        <button className="ui lantik-color-button button">
          <FormattedMessage id="playas.configurarPlaya" defaultMessage="CONFIGURAR"/>
        </button>
      </NavLink>
    </div>
    <div>
        <BeachGrid beaches={beaches} />
    </div>
  </div>
  </>
    
) 
       
} 
 export default Beaches

