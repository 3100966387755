import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {Oval} from 'react-loader-spinner'
import { useIntl } from 'react-intl';

const BeachGrid = ({ beaches }) => {

  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const beachesPerPage = 10; 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (beaches.length > 0 || !loading) {
      setLoading(false); // Ocultar la animación de carga cuando se reciben las playas
    }
  }, [beaches, loading]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Resetear a la primera página en cada nueva búsqueda
  };

  // Filtrar las playas por nombre de playa o nombre de ciudad
  const filteredBeaches = beaches.filter(
    (beach) =>
      beach.beachName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      beach.cityName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calcular el índice de las playas mostradas en la página actual
  const indexOfLastBeach = currentPage * beachesPerPage;
  const indexOfFirstBeach = indexOfLastBeach - beachesPerPage;
  const currentBeaches = filteredBeaches.slice(indexOfFirstBeach, indexOfLastBeach);

  // Cambiar de página
  const paginate = pageNumber => setCurrentPage(pageNumber);

  let content;

  if(loading){
    content = (
    <div className="loader-container">
      <Oval
        visible={true}
        height="150"
        width="150"
        color="#057F8C"
        secondaryColor="#98d1d6"
        ariaLabel="loading"
      />
    </div>
    );
  }else if (filteredBeaches.length > 0) {
    content = (
      <div>
        <div className="beach-grid">
          {currentBeaches.map(data => (
            <div key={data.idExternal} className="beach-card">
              <img src={data.image} alt={data.beachName} />
              <div className="info">
                <h3>{data.beachName}</h3>
                <p>{data.cityName}</p>
              </div>
              <div className="buttons">
                <NavLink to={`view-beaches/${data.idExternal}`}>
                  <button className="ui button">
                    <i className="ui black eye icon centered-icon"></i>
                  </button>
                </NavLink>
                <NavLink to={`edit-beaches/${data.idExternal}`}>
                  <button className="ui button">
                    <i className="ui black pencil alternate icon centered-icon"></i>
                  </button>
                </NavLink>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredBeaches.length / beachesPerPage) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`ui button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    );
  } else {
    content = (
      <div style={{ textAlign: 'center', fontSize: '25px', padding: '20px' }}>
        <FormattedMessage
          id="playas.noExistenPlayas"
          defaultMessage="No existen playas"
        />
      </div>
    );
  }

  return (
    <div>
      <div className="search-bar">
        {/* Buscador */}
        <input
          type="text"
          placeholder={intl.formatMessage({ id: 'playas.buscador' })}
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      {/* Listado playas */}
      {content}
    </div>
  );
};

export default BeachGrid;
