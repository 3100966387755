import Keycloak from "keycloak-js";
import jwtDecode from 'jwt-decode';

//TODO those data should be env variables not parsed from json

// const _kc = new Keycloak('/keycloak.json');
const _kc = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID
    });

const initKeycloak = (onAuthenticatedCallback) => {
    //onAuthenticatedCallback();
    _kc.init({
        //onLoad: 'check-sso,',
        //silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        //Fix auth problem
        onLoad: 'login-required',
        checkLoginIframe: false,
        //
        pkceMethod: 'S256',
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log("user is not authenticated..!");
            }

            /*
            // Usa el token de acceso para hacer una solicitud a la API de Keycloak y obtener información del usuario
            _kc.loadUserInfo().then(userInfo => {
                // La información del usuario, incluidos los grupos a los que pertenece, estará disponible en userInfo
                console.log('Información del usuario:', userInfo);

                // Los grupos a los que pertenece el usuario estarán en la propiedad roles
                const grupos = userInfo.roles;
                console.log('Grupos a los que pertenece el usuario:', grupos);
            });

            // _kc.loadGroups()
            // .then(groups => {
            //     // Los grupos a los que pertenece el usuario estarán disponibles en la variable groups
            //     console.log('Grupos a los que pertenece el usuario:', groups);
            // })
            */

            onAuthenticatedCallback();
        })
        .catch(console.error);
        
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getRealm = () => _kc.realm;

/*
const getGroups = () => {

    const decodedToken = jwtDecode(_kc.token);
    console.log("decodedToken:");
    console.log(decodedToken);
    console.log("decodedToken.groups:" +  decodedToken.groups);
    
    console.log("_kc.realmAccess:");
    console.log(_kc.realmAccess);

    console.log("_kc.resourceAccess:");
    console.log(_kc.resourceAccess);

    console.log("_kc.tokenParsed:");
    console.log(_kc.tokenParsed);

    _kc.loadUserInfo().then((data)=>{
        console.log("_kc.loadUserInfo()");
        console.log(data);
    });
    
    _kc.loadUserProfile().then((data)=>{
        console.log("_kc.loadUserProfile()");
        console.log(data);
    });

    console.log("hasRole()");
    console.log(_kc.hasRealmRole("adminbiz"))
}
*/

const isLoggedIn = () => !!_kc.token;

 const updateToken = (successCallback) =>
     _kc.updateToken(5) 
        .then(successCallback)
        .catch(doLogin); 


const refreshToken = () =>
    _kc.updateToken(300)
        .then( (response) => {
            console.log("updateToken OK:");
            console.log(response);
        })
        .catch( (error) => {
            console.log("updateToken ERROR:");
            console.log(error)
            //doLogin
        });

const getTokenExpiration = () => _kc.tokenParsed?.exp;

const checkTokenExpiration = () => {
    const expiration = UserService.getTokenExpiration();
    if (!expiration) return;

    const currentTime = Math.floor(Date.now() / 1000); // tiempo actual en segundos
    const timeRemaining = expiration - currentTime;
    return timeRemaining;
}
    
const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    refreshToken, 
    getTokenExpiration,
    checkTokenExpiration,
    getUsername,
    hasRole,
    getRealm,
};

export default UserService;
