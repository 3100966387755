import React from 'react';
import {Route, Switch} from "react-router-dom";
import Intro from "./Intro";
import News from "./news/News";
import Featured from "./featured/Featured";
import CreateFeature from "./featured/CreateFeature";
import EditFeature from "./featured/EditFeature";
import ViewFeatured from "./featured/ViewFeatured";
import Files from "./media/Files";
import ViewFile from "./media/ViewFile";
import LabPushNotification from "./laboratory/LabPushNotification";
import LabVideocall from './laboratory/LabVideocall';
import LabVideocallNotice from './laboratory/LabVideocallNotice';
import LabCloudant from './laboratory/LabCloudant';
import LabSolidusCloudantDevices from './laboratory/LabSolidusCloudantDevices';
import NoMatch from "./NoMatch";
import ViewNews from './news/ViewNews';
import CreateNews from './news/CreateNews';
import EditNews from './news/EditNews';
import SwimmingPools from './pools/SwimmingPools';
import CreatePool from './pools/CreatePool';
import EditSwimmingPool from './pools/EditSwimmingPool';
import ViewSwimmingPools from './pools/ViewSwimmingPools';
import Beaches from './beaches/Beaches';
import ViewBeaches from './beaches/ViewBeaches';
import ConfBeaches from './beaches/ConfBeaches';
import EditBeaches from './beaches/EditBeaches';
import ConfPools from './pools/ConfPools';
import Library from './library/Library';
import AnonymousHome from '../pages/AnonymousPage';
import ConfigureHome from './configureHome/ConfigureHome';
import NotificationSending from '../notifications/NotificationSending';
import NotificationLogs from '../notifications/NotificationLogs';
import NotificationReports from '../notifications/NotificationReports';
import Parameters from './parameters/Parameters';
import Users from './users/Users';
import Groups from './groups/Groups';
import UserForm from './users/UserForm';
import GroupForm from './groups/GroupForm';
import AppTexts from './appTexts/AppTexts';
import AppTextsForm from './appTexts/AppTextsForm';

function Main(props) {
    return (

        <div>
            <Switch>
                <Route exact path="/">
                    <Intro/>
                </Route>
                <Route exact path="/configureHome">
                    <ConfigureHome/>
                </Route>
                <Route exact path="/userForm/:action">
                    <UserForm/>
                </Route>
                <Route exact path="/users">
                    <Users/>
                </Route>
                <Route exact path="/groups">
                    <Groups/>
                </Route>
                <Route exact path="/groupForm/:action">
                    <GroupForm/>
                </Route>
                <Route exact path="/appTexts">
                    <AppTexts/>
                </Route>
                <Route exact path="/appTextsForm/:action">
                    <AppTextsForm/>
                </Route>
                <Route exact path="/parameters">
                    <Parameters/>
                </Route>
                <Route exact path="/notificationSending">
                    <NotificationSending/>
                </Route>
                <Route exact path="/notificationLogs">
                    <NotificationLogs/>
                </Route>                
                <Route exact path="/notificationReports">
                    <NotificationReports/>
                </Route>
                <Route exact path="/news">
                    <News/>
                </Route>
                <Route exact path="/view-news/:id">
                    <ViewNews />
                </Route>
                <Route exact path="/create-news">
                    <CreateNews />
                </Route>
                <Route exact path="/edit-news/:id">
                    <EditNews />
                </Route>
                <Route exact path="/featured">
                    <Featured/>
                </Route>
                <Route exact path="/create-feature">
                    <CreateFeature />
                </Route>
                <Route exact path="/view-feature/:id">
                    <ViewFeatured />
                </Route>
                <Route exact path="/edit-feature/:id">
                    <EditFeature />
                </Route>
                <Route exact path="/login">
                    <AnonymousHome />
                </Route>
                <Route path="/library">
                    <Library/>
                </Route>
                <Route path="/swimming-pools">
                    <SwimmingPools/>
                </Route>
                <Route path="/editSwimmingPool/:id">
                    <EditSwimmingPool/>
                </Route>
                <Route exact path="/viewSwimmingPool/:id">
                    <ViewSwimmingPools />
                </Route>
                <Route path="/create-pool">
                    <CreatePool/>
                </Route>
                <Route exact path="/conf-pools">
                    <ConfPools />
                </Route>
                <Route path="/lab-push-notification">
                    <LabPushNotification/>
                </Route>
                <Route path="/lab-videocall">
                    <LabVideocall/>
                </Route>
                <Route path="/lab-videocall-notice">
                    <LabVideocallNotice/>
                </Route>      
                <Route path="/lab-cloudant">
                    <LabCloudant/>
                </Route>    
                <Route path="/lab-solidus-cloudant">
                    <LabSolidusCloudantDevices/>
                </Route>                           
                <Route path="/files/:id">
                    <Files/>
                </Route>
                <Route path="/files">
                    <Files/>
                </Route>
                <Route path="/view-file/:id">
                    <ViewFile/>
                </Route>
                <Route exact path="/Beaches">
                    <Beaches/>
                </Route>
                <Route exact path="/view-beaches/:id">
                    <ViewBeaches />
                </Route>
                <Route exact path="/conf-beaches">
                    <ConfBeaches />
                </Route>
                <Route exact path="/edit-beaches/:id">
                    <EditBeaches />
                </Route>
                <Route path="*">
                    <NoMatch/>
                </Route>
            </Switch>
        </div>

    );
}

export default Main;