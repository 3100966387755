import React, {useContext, useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import Moment from 'moment';
import {langContext} from '../../context/langContext';
import {requestGetFeatured, requestDeleteFeatured, getTotalNumberOfFiles} from '../../services/FeaturedService'
import {FormattedMessage} from 'react-intl';

const Featured = () => {

  const [totalPost, setTotalPost] = useState()
  const [featured, setFeatured] = useState([])
  const [page, setPage] = useState(0)  
  const [size] = useState(10)
  const [active, setActive] = useState("")
  const [search, setSearch] = useState("")
  const [hideMessageComponent, setHideMessageComponent] = useState(true);
  const [delId,setDelId] = useState("")
  const [show, setShow] = useState(false);
  const idioma = useContext(langContext);
  const [lastLanguage, setlastLanguage] = useState(idioma.localePorDefecto);

  useEffect(() => {
    requestGetFeatured(setFeatured, page, size, active, search);
    getTotalNumberOfFiles(setTotalPost, "", "");
    window["urlImage"] = ""
    window["urlImageMini"] = ""
  },[])
  
 

  function goToPage(nPage) {
    if(nPage < 0)
    nPage = 0
    requestGetFeatured(setFeatured, nPage, size, active, search);
    getTotalNumberOfFiles(setTotalPost, active, search);
    setPage(nPage)
  }

  function nextList() {
    var pageNumber = pageNumbers.length + 1;
    if(pageNumber === Number(page)+1){
      goToPage(Number(page));
    }else{
      goToPage(Number(page)+1);
    }
  }
  
  function prevList() {
    goToPage(Number(page)-1);
  }

  function deleteFeature(id){
    requestDeleteFeatured(id,setFeatured)
    setShow(false)
    setHideMessageComponent(true);
  }

  function showDeleteMessage(id){
    setShow(true)
    setHideMessageComponent(false);
    setDelId(id);
  }
  function hideDeleteMessage(){
    setShow(false)
    setHideMessageComponent(true);
  }

  function controlState(data){

    if(data.active === "BORRADOR") return controlDate(data.creationDate);
    else return controlDate(data.publishingDate);
  }

  function controlDate(date){

    if(date !== "" && date !== undefined && date !== null) return Moment(date).format("DD/MM/YYYY");
    else return "-";

  }

  function searchByActive(sActive){
    setPage(0);
    setActive(sActive)
    requestGetFeatured(setFeatured, 0, size, sActive, search);
    getTotalNumberOfFiles(setTotalPost, sActive, search);
  }
  function searchByText(){
    setPage(0);
    requestGetFeatured(setFeatured, 0, size, active, search)
    getTotalNumberOfFiles(setTotalPost, active, search);
  }
  function limpiaBusqueda(){
    setPage(0);
    setActive("");
    setSearch("");
    requestGetFeatured(setFeatured, 0, size, "", "");
    getTotalNumberOfFiles(setTotalPost, "", "");
  }
  const BackDrop = ({show}) => 
  <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPost / size); i++) {
    pageNumbers.push(i);
  }
  

let emptyTable;

if(featured.length > 0){
  emptyTable = 
    featured.map(data => (
      <tbody >   
        <tr >
        <td key={data.id}>{lastLanguage === 'eus-ES' ? data.titleEu : data.titleEs}</td>
        <td className='center aligned row'>
          {data.type !== null && data.type === 'VIDEO' ?  <b><FormattedMessage id="destacados.video" defaultMessage="Video(Enlaces externos)"/></b> : 
           data.type !== null && data.type === 'OTRO' ?  <b><FormattedMessage id="destacados.otro" defaultMessage="Otro (Enlaces externos)"/></b> :
           data.type !== null && data.type === 'FUN_APP' ?  <b><FormattedMessage id="destacados.funciApp" defaultMessage="Funcionalidad de la App"/></b> : 
           data.type !== null && data.type === 'APP' ?  <b><FormattedMessage id="destacados.appDipu" defaultMessage="App de la Diputación"/></b> : 
           data.type !== null && data.type === 'NOTICIA' ?  <b><FormattedMessage id="destacados.noticia" defaultMessage="Noticia"/></b> : 
           <b><FormattedMessage id="desconocido.estadoDesconocido" defaultMessage="Desconocido"/></b>}
        </td> 
        <td className='center aligned row'>
          {data.active !== null && data.active === 'PUBLICADO' ?  <FormattedMessage id="destacados.publicado" defaultMessage="Publicado"/> : data.active !== null && data.active === 'PROGRAMADO' ?  <FormattedMessage id="destacados.programado" defaultMessage="Programado"/> : data.active !== null && data.active === 'BORRADOR' ?  <FormattedMessage id="destacados.borrador" defaultMessage="Borrador"/> : <FormattedMessage id="destacados.estadoDesconocido" defaultMessage="Estado desconocido"/>}
        </td>
        <td className='center aligned row'>{data.priority}</td>
        <td className='center aligned row'>{controlState(data)}</td>
        <td> 
          <div className="ui small basic icon buttons">
            <NavLink to={`view-feature/${data.id}`}>  
            <button className="ui button" >
            <i className="ui black eye icon"></i>
            </button>
          </NavLink>
          <NavLink to={`edit-feature/${data.id}`}>  
          <button className="ui button"><i className="ui black pencil alternate icon"></i></button>
          </NavLink>
            <button className="ui button" onClick={() => showDeleteMessage(data.id)}><i className="ui black trash alternate outline icon"></i></button>
            </div>
        </td>      
        </tr>
      </tbody>
    ))
}else{
  pageNumbers.splice(1);
  emptyTable = <tbody><tr><td colSpan="5" style={{textAlign: 'center', fontSize: '25px', padding: '20px'}}><FormattedMessage id="destacados.noexistendestacados" defaultMessage={"No existen destacados"}/></td></tr></tbody>;
}

function cambiolistaIdioma(){
  if(lastLanguage !== idioma.localePorDefecto || lastLanguage === ""){
    setlastLanguage(idioma.localePorDefecto);
  }
}
  return (


    <div className="mainDiv">
      <div>
      <label className="title" style={{paddingTop: 10}}> <FormattedMessage id="destacados.destacados" defaultMessage="Destacados" onChange={cambiolistaIdioma()} /></label>  
      </div>

      <div>
        <NavLink to={"create-feature"}>
        <button className="ui lantik-color-button button">
          <FormattedMessage id="destacados.anadir.anadirDestacado" defaultMessage="AÑADIR DESTACADO"/>
        </button>
        </NavLink>
        <span style={{float:'right', marginRight:30}}>
        <div className="ui icon input">
          <select value={active} className="ui select" name="select" onChange={e => searchByActive(e.target.value)} >
            <FormattedMessage id="destacados.anadir.tipo.seleccione" defaultMessage="Seleccione...">
              {(message) => <option value="">{message}</option>}
            </FormattedMessage>
            <FormattedMessage id="destacados.anadir.estado.publicado" defaultMessage="Publicado">
              {(message) => <option value="PUBLICADO">{message}</option>}
            </FormattedMessage>
            <FormattedMessage id="destacados.anadir.estado.programado" defaultMessage="Programado">
              {(message) => <option value="PROGRAMADO">{message}</option>}
            </FormattedMessage>
            <FormattedMessage id="destacados.anadir.estado.borrador" defaultMessage="Borrador">
              {(message) => <option value="BORRADOR">{message}</option>}
            </FormattedMessage>
          </select>

        </div>
        <div className="ui icon input" style={{marginLeft:30, marginRight:30}}>
          <FormattedMessage id="destacados.buscar" defaultMessage="Buscar">
            {placeholder=> <input type="text" placeholder={placeholder} value={search} onChange={e => setSearch(e.target.value)}/> }
          </FormattedMessage>
          <i className="search link icon" onClick={e => searchByText(e.target.value)}></i>
        </div>
        <button className="ui lantik-color-button button" onClick={() => limpiaBusqueda()}>
            <FormattedMessage id="destacados.limpiarBusqueda" defaultMessage="Limpiar filtro"/>
          </button>
          
        </span>
      </div>
    
      <div className='paddingTableNews '>    
        <table className="ui table">       
          <thead>
            <tr className='center aligned row'>
            <th><FormattedMessage id="destacados.titulo" defaultMessage="Titulo"/></th>
            <th><FormattedMessage id="destacados.tipo" defaultMessage="Tipo"/></th>
            <th><FormattedMessage id="destacados.estado" defaultMessage="Estado"/></th>
            <th><FormattedMessage id="destacados.orden" defaultMessage="Orden"/></th>
            <th><FormattedMessage id="destacados.fecha" defaultMessage="Fecha"/></th>
            <th></th>
            </tr>
          </thead>
            {emptyTable}
        </table>
      </div>
      <div className="content" style={{textAlign:"center"}}>
        <div>
          <button className="button-icon" onClick={prevList}><i className="ui chevron left icon"></i></button>
          {pageNumbers.map(number => (
            page === (number-1) ? <button  key={number.toString()}  className="ui red button" style={{padding:'auto'}} onClick={() => goToPage(number-1)}>{number}</button> : <button  key={number.toString()}  className="button-icon" onClick={() => goToPage(number-1)}>{number}</button> 
          ))}
          <button className="button-icon" onClick={nextList}><i className="ui chevron right icon"></i></button>
        </div>
      </div>
      <BackDrop show={show}></BackDrop> 
      <div className={`${hideMessageComponent ? "hide-MessageComponent" : "MessageComponent"}`}>
        <div><span className='msgHead'><i className="ui headIcon trash alternate outline icon"></i></span></div>
          <div className='msgBody'>
            <span>
              <label><FormattedMessage id="destacados.mensajeEliminar1" defaultMessage="¿Seguro que quiere eliminar este destacado?"/></label>
              <br></br>
              <FormattedMessage id="destacados.mensajeEliminar2" defaultMessage="Esta acción es irrversible, por lo que se perderán todos los datos."/>
            </span>
          </div>
          <div className='msgBottom'>
            <span>               
              <button className="ui noBorder button" onClick={() => hideDeleteMessage(true)}>
              <FormattedMessage id="destacados.cancelar" defaultMessage="Cancelar"/>
              </button>
              <button className="ui lantik-color-button button" onClick={() => deleteFeature(delId)}>
              <FormattedMessage id="destacados.eliminar" defaultMessage="Eliminar"/>
              </button>
            </span>
          </div>
      </div>
    </div>
  )
}

 export default Featured