import React, { useState,useContext}   from 'react';
import {NavLink} from "react-router-dom";
import {requestGetNewSearch,requestDeleteNew,getTotalNumberOfFiles} from '../../services/NewsService';
import Moment from 'moment';
import {FormattedMessage} from 'react-intl';
import {langContext} from '../../context/langContext';





const News = () => {

  const [sort, setSort] = useState("publishingDate");
  const [status,setStatus] = useState("");
  const [totalPost, setTotalPost] = useState();
  const [news, setNews] = useState([]);
  const [dinamicStatus, setDinamicStatus] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);  
  const [size] = useState(8);
  const [hideMessageComponent, setHideMessageComponent] = useState(true);
  const [delId,setDelId] = useState("");
  const [show, setShow] = useState(false);
  const idioma = useContext(langContext);
  const [lastLanguage, setlastLanguage] = useState(idioma.localePorDefecto);
  
  React.useEffect(() => {

    getTotalNumberOfFiles(setTotalPost);
    requestGetNewSearch(setNews,setDinamicStatus, page, size, sort);  
   
  },[])
  

 function formateDate(publishingDate){
  var date = new Date(publishingDate);    
  return  Moment(date).format("DD/MM/YYYY HH:mm");
} 


function goToSort(nPage, nSort){
  requestGetNewSearch(setNews,setDinamicStatus, nPage, size, sort)
  getTotalNumberOfFiles()
  setPage(nPage);
   setSort(nSort); 
  setNews(news);
}


function nextList() {
  var pageNumber = pageNumbers.length + 1;
  if(pageNumber === Number(page)+1){
    goToPage(Number(page));
  }else{
    goToPage(Number(page)+1);
  }
}


function prevList() {
  goToPage(Number(page)-1);
}

function goToPage(nPage) {

  if(nPage < 0){
    nPage = 0
  }

  requestGetNewSearch(setNews,setDinamicStatus, nPage, size, "publishingDate", status)
  getTotalNumberOfFiles()
  setPage(nPage)
 
}

function deleteNew(id){
    
  requestDeleteNew(id,setNews);
  setShow(false);
  setHideMessageComponent(true);

}

function showDeleteMessage(id){
  setShow(true)
  setHideMessageComponent(false);
  setDelId(id);
}
function hideDeleteMessage(){
  setShow(false)
  setHideMessageComponent(true);
}



function searchByStatus(nPage,state){
  if(nPage < 0)
    nPage = 0

  if(status !== state)
    nPage = 0

  setPage(nPage);
  setNews(news);
  setStatus(state);
 /*  getTotalNumberOfFiles(setTotalPost,status); */
  requestGetNewSearch(setNews,setDinamicStatus, nPage, size, "publishingDate", state, search);
  getTotalNumberOfFiles(setTotalPost, state, search);
}

const handleChange = e => {
  setSearch(e.target.value);
  searchByText(e.target.value);
}
function searchByText(sSearch){
  setPage(0);
  requestGetNewSearch(setNews,setDinamicStatus, page, size, "publishingDate", status, sSearch);
  getTotalNumberOfFiles(setTotalPost, status, sSearch);
}

const filterTitle = (searchTerms) => {
  var searchResult = dinamicStatus.filter((e) => {
      if(e.titleEs.toString().toLowerCase().includes(searchTerms.toLowerCase())) {
        return e;
      }
  })
  setNews(searchResult);
}




  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPost / size); i++) {
    pageNumbers.push(i);
  }


const BackDrop = ({show}) => 
<div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>




let emptyTable;

if(news.length > 0){

  emptyTable = 
 
      news.map(data => (
        <tbody>   
          <tr>
            <td key={data.id} style={{minWidth:500}}>{lastLanguage === 'eus-ES' ? data.titleEu : data.titleEs}</td>
            {(data.author !== undefined && data.author !== null) &&   <td className='center aligned row'>{data.author}</td> }
            {(data.author === undefined || data.author === null) &&  <td className='center aligned row'><FormattedMessage id="noticias.sinAutor" defaultMessage="sin autor/a"/></td> }
            {/* {data.categories.map((cat, index)=> (
             index === 0 &&  <td className='center aligned row' key={cat.id}>{lastLanguage === 'eus-ES' ? cat.nameEu : cat.nameEs}</td> 
           
             
          
            ))}   */}
         

          
            <td className='center aligned row' style={{paddingRight:30}}>
              <div>
              {data.status !== null && data.status === 'PUBLICADO' ?  <b><FormattedMessage id="noticias.publicado" defaultMessage="Publicado"/></b> : data.status !== null && data.status === 'PROGRAMADO' ?  <b><FormattedMessage id="noticias.programado" defaultMessage="Programado"/></b> : data.status !== null && data.status === 'BORRADOR' ?  <b><FormattedMessage id="noticias.borrador" defaultMessage="Borrador"/></b> : data.status !== null && data.status === 'EXPIRADO' ?  <b><FormattedMessage id="noticias.borrador" defaultMessage="Expirado"/></b>:<b><FormattedMessage id="noticias.estadoDesconocido" defaultMessage="Expirado"/></b>}
               
              </div>
              <div>
              {data.publishingDate !== null ?  Moment(data.publishingDate).format("DD/MM/YYYY HH:mm ") : <FormattedMessage id="noticias.sinFecha" defaultMessage="sin fecha"/>}
              </div>
            </td>
          
            <td > 
              <div className="ui small basic icon buttons" >
                <NavLink to={`view-news/${data.id}`}>  
                  <button className="ui button" >
                    <i className="ui black eye icon"></i>
                  </button>
                </NavLink>
                <NavLink to={`edit-news/${data.id}`}>  
                  <button className="ui button"><i className="ui black pencil alternate icon"></i></button>
                </NavLink>
                <button className="ui button" onClick={() => showDeleteMessage(data.id)}><i className="ui black trash alternate outline icon"></i></button>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      ));

  }else if(null !== status && status !== "") {
    pageNumbers.splice(1);
    emptyTable = <tbody><tr><td colSpan="5" style={{textAlign: 'center', fontSize: '25px', padding: '20px'}}>
      <FormattedMessage id="noticias.noexistennoticiasstatus" defaultMessage={"No existen noticias para el estado " + status} values={{status:status}}/> </td></tr></tbody>;
  }else{
    
    pageNumbers.splice(1);
    emptyTable = <tbody><tr><td colSpan="5" style={{textAlign: 'center', fontSize: '25px', padding: '20px'}}> <FormattedMessage id="noticias.noexistennoticias" defaultMessage={"No existen noticias"}/></td></tr></tbody>;
  }

  function limpiaBusqueda(){
    setPage(0);
    setSearch("");
    setStatus("");
    requestGetNewSearch(setNews,setDinamicStatus, 0, size, sort);
    getTotalNumberOfFiles(setTotalPost, "", "");
  }


  function cambiolistaIdioma(){
    if(lastLanguage !== idioma.localePorDefecto || lastLanguage === ""){
      setlastLanguage(idioma.localePorDefecto);
    }
   
  }
  return (

<>
  <div className='mainDiv'>
 
   
    <label className="title" style={{paddingTop: 10}}> <FormattedMessage id="noticias.noticiasMayus" defaultMessage="NOTICIAS" onChange={cambiolistaIdioma()}/></label>  
    
    
      <div className="ui icon input search" style={{paddingTop:20, marginRight:20}}>
      
        <select value={status} className="ui select" style={{marginRight:15}} name="selectState" onChange={e => searchByStatus(page,e.target.value)} >
          <FormattedMessage id="noticias.seleccione" defaultMessage="Seleccione...">
            {(message) => <option value="">{message}</option>}
          </FormattedMessage>
          <FormattedMessage id="noticias.publicado" defaultMessage="Publicado">
            {(message) => <option value="PUBLICADO">{message}</option>}
          </FormattedMessage>
          <FormattedMessage id="noticias.programado" message="noticias.programado" defaultMessage="Programado">
            {(message) => <option value="PROGRAMADO">{message}</option>}
          </FormattedMessage>
          <FormattedMessage id="noticias.borrador" message="noticias.borrador" defaultMessage="Borrador">
            {(message) => <option value="BORRADOR">{message}</option>}
          </FormattedMessage>
        </select>

        <div className='ui icon input'>
          <FormattedMessage id="noticias.buscarTitulo" defaultMessage="Buscar por título">
              {placeholder=> <input value={search} type="text" placeholder={placeholder} onChange={handleChange}/> }
            </FormattedMessage>
          <i className="search link black icon"></i> 
        </div>
        
        <button className="ui lantik-color-button button" style={{marginLeft:15}} onClick={() => limpiaBusqueda()}>
            <FormattedMessage id="noticias.limpiarBusqueda" defaultMessage="Limpiar"/>
        </button>
        
      </div>


    <div style={{paddingLeft:10}}>
      <NavLink to={"create-news"}>
      <button className="ui lantik-color-button button" style={{marginTop:30}}><FormattedMessage id="noticias.anadirNoticias" defaultMessage="AÑADIR NOTICIA"/></button>
      </NavLink>
      </div>
     <div className='paddingTableNews '>    
      <table className="ui  table">       
        <thead >
          <tr className='center aligned row'>
            <th><FormattedMessage id="noticias.titulo" defaultMessage="Título"/></th>
            <th><FormattedMessage id="noticias.autor" defaultMessage="Autor/a"/></th>
      {/*       <th><FormattedMessage id="noticias.categoria" defaultMessage="Categoría"/></th>  */}
            <th><FormattedMessage id="noticias.fecha" defaultMessage="Fecha"/></th>
            <th> </th>
            <th> </th>
          </tr>
        
     
        </thead>
    
          {emptyTable}
    
      </table>

    <div className="content" style={{textAlign:"center"}}>
        <div>
          <button className="button-icon" onClick={prevList}><i className="ui chevron left icon"></i></button>
          {pageNumbers.map(number => (
              page === (number-1) ? <button key={number.toString()} className="ui red button" style={{padding:'auto'}} onClick={() => goToPage(number-1)}>{number}</button> : <button  key={number.toString()}  className="button-icon" onClick={() => goToPage(number-1)}>{number}</button> 
          ))}
          <button className="button-icon" onClick={nextList}><i className="ui chevron right icon"></i></button>
        </div>
      </div>
      <BackDrop show={show}></BackDrop> 
      <div className={`${hideMessageComponent ? "hide-MessageComponent" : "MessageComponent"}`}>
      <div><span className='msgHead'><i className="ui headIcon trash alternate outline icon"></i></span></div>
      <div className='msgBody'>
            <span>
              <label><FormattedMessage id="noticias.confEliminar" defaultMessage="¿Seguro que quiere eliminar esta Noticia?"/></label><br></br>
              <FormattedMessage id="noticias.avisoEliminar" defaultMessage="Esta acción es irrversible, por lo que se perderán todos los datos."/>
            </span>
            </div>
          <div className='msgBottom'>
            <span>               
              <button className="ui noBorder button" onClick={() => hideDeleteMessage(true)}><FormattedMessage id="noticias.cancelar" defaultMessage="Cancelar"/></button>
              <button className="ui lantik-color-button button" onClick={() => deleteNew(delId)}><FormattedMessage id="noticias.eliminar" defaultMessage="Eliminar"/></button>
            </span>
          </div>

        </div>
      </div>
    </div>
    
  </>

  ) 
 
} 


 export default News
