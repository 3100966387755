import React, {useState} from 'react'; 
import DatePicker,{registerLocale} from "react-datepicker";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import es from "date-fns/locale/es";
import Moment from 'moment';
import {FormattedMessage} from 'react-intl';
import { NavLink, useParams,useHistory} from 'react-router-dom';
import {requestGetPoolById, RequestPutPool, requestDeletePool,requestDeleteSeasson,requestGetTown} from '../../services/PoolsService';
import UserService from '../../services/UserService';
import axios from "axios";


const EditSwimmingPool = () => {

    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    const [hideMessageComponent, setHideMessageComponent] = useState(true);
    const [delId,setDelId] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [seassons, setSeassons] = useState([]);
    const [seassonsInit, setSeassonsInit] = useState([]);
    const [show, setShow] = useState(false);
    const [nameES, setNameES] = useState("");
    const [nameEU, setNameEU] = useState("");
    const [nameESAPI, setNameESAPI] = useState("");
    const [nameEUAPI, setNameEUAPI] = useState("");
    const [town, setTown]  = useState([]);
    const [townCode, setTownCode] = useState("");
    const [facilityCode, setFacilityCode] = useState("");
    const [urlWeb, setUrlWeb]= useState("");
    const [nameWebES, setNameWebES]= useState("");
    const [nameWebEU, setNameWebEU]= useState("");
    const [descriptionES, setDescriptionES] = useState("");
    const [descriptionEU, setDescriptionEU] = useState("");
    const [editPools, setEditPools] = useState("");
    const [contadorGeneral, setContadorGeneral] = useState(0);
    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [townsBBDD, setTownsBBDD] = useState([]);


    const {id} = useParams();
    let selImage = "";

    function setLang(lang){

        if(lang === "ES") {
            setHideEsComponent(false)
            setHideEuComponent(true)

        } else {

            setHideEsComponent(true)
            setHideEuComponent(false)

        }
    }
    const fieldsMessageCamposObligatorios = 
    <div >
        <i class="big exclamation red circle icon"></i>
        <p><FormattedMessage id="piscinas.editar.error.camposObligatorios" defaultMessage="Los campos marcados con asterisco son "/><b><FormattedMessage id="piscinas.editar.error.obligatorios" defaultMessage="obligatorios."/></b></p>
    </div>
    
    const fieldsMessageURLNoValida = 
    <div >
        <i class="big exclamation red circle icon"></i>
        <p><FormattedMessage id="piscinas.editar.errorLink" defaultMessage="El campo URL web tiene un valor erróneo."/></p>
    </div>

    React.useEffect(() => {
        registerLocale("es", es);
        getData();
        requestGetTown(setTownsBBDD);
    },[id])  


    const getData = async() => {

        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + UserService.getToken()
        }
      
        const url = process.env.REACT_APP_URL_FULL_POOLS + `/${id}`; 
        const data = await axios.get(url, { 'headers':  headers })   
        const dataPool = await data.data;
       
      
          requestGetPoolById(setEditPools, id);
         
          setImageUrl(dataPool.urlImg);
          setNameES(dataPool.poolNameEs);
          setNameESAPI(dataPool.poolNameEsApi);
          setNameEU(dataPool.poolNameEu);
          setNameEUAPI(dataPool.poolNameEuApi);
          setDescriptionES(dataPool.descriptionEs);
          setDescriptionEU(dataPool.descriptionEu);
          setTown(dataPool.cityName);
          setSeassons(dataPool.poolsSeasson);
          setSeassonsInit(dataPool.poolsSeasson);
          setTownCode(dataPool.cityCode);
          setFacilityCode(dataPool.facilityCode);
          setUrlWeb(dataPool.webUrl);
          setNameWebES(dataPool.webNameEs);
          setNameWebEU(dataPool.webNameEu);

          if(selImage === undefined || selImage === "") setImageUrl(dataPool.urlImg)
          else setImageUrl(selImage)
      
          window["urlImage"] = ""
       
      
      }

    const BackDrop = ({show}) => 
    <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>
    
    let history = useHistory();

    function handleClick() {

        history.push("/swimming-pools");
  
    } 

    function cambiarDataNuevoAVacio(seassons){
        let seassons2 = seassons.slice();
        for(let i = 0; seassons2.length > i; i++){
            if(seassons2[i].idSeasson.toString().substr(0,1) == "-"){
                seassons2[i].idSeasson = '';
            }
            for(let x = 0; seassons2[i].poolsSchedule.length > x ;x++){
                if(seassons2[i].poolsSchedule[x].idSchedule.toString().substr(0,1) == "-"){
                    seassons2[i].poolsSchedule[x].idSchedule = '';
                }
            }
        }
       setSeassons(seassons2);
    }

    function validateFields () {

        let valError = false;
        if(townCode  === "" || townCode  === undefined || townCode === null
        || facilityCode  === "" || facilityCode  === undefined || facilityCode  === null
        ||town  === "" || town  === undefined || town === null) {
    
            valError = true;
        }
    
        if(valError){
            setErrorMsg(fieldsMessageCamposObligatorios);
            setHideErrorComponent(false);
        }else if("" == urlWeb || null == urlWeb || isValidHttpUrl(urlWeb)){
            updateSwimmingPool();
        }else{
            setErrorMsg(fieldsMessageURLNoValida);
            setHideErrorComponent(false);
        } 
    }

    function isValidHttpUrl(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ 
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
        '(\\#[-a-z\\d_]*)?$','i');
      return !!pattern.test(str);
    }
    
    function updateSwimmingPool () {

        window["urlImage"] = ""
       
        cambiarDataNuevoAVacio(seassons);

        const body = {
            urlImg: imageUrl,
            poolNameEs: nameES,
            poolNameEsApi:nameESAPI,
            poolNameEu: nameEU,
            poolNameEuApi: nameEUAPI,
            descriptionEs: descriptionES,
            descriptionEu: descriptionEU,
            cityName: town,
            cityCode: townCode,
            facilityCode: facilityCode, 
            webUrl: urlWeb,
            webNameEs: nameWebES,
            webNameEu: nameWebEU,
            poolsSeasson: seassons

        }  
    

        requestDeleteSeasson(id,body)

        setTimeout (() => {
            history.push("/swimming-pools");
        }, 500);
     
      }

    function añadirTemporada(){
        let seasson=[{idSeasson:-contadorGeneral, init:new Date(),finale:new Date(),poolsSchedule:[{idSchedule:-(contadorGeneral+1),open:"00:00",close:"00:00"}]}];
        setSeassons(seassons.concat(seasson));
        setContadorGeneral(contadorGeneral+2);
        
    }

    function añadirSchedule(eSeasson){
     
        var seassons2 = seassons.slice();
        var item_schedule = {idSchedule:-contadorGeneral,open:"",close:""};
        setContadorGeneral(contadorGeneral+1);
        
        let indexSeasson = seassons2.findIndex(i => i.idSeasson === eSeasson);
        seassons2[indexSeasson].poolsSchedule.push(item_schedule);

        setSeassons(seassons2);
        
    }

    function borrarSchedule(idSeasson,idSchedule){
        var seassons2 = seassons.slice();
        let indexSeasson = seassons2.findIndex(i => i.idSeasson === idSeasson);
        let indexSchedule = seassons2[indexSeasson].poolsSchedule.findIndex(i => i.idSchedule === idSchedule);
        seassons2[indexSeasson].poolsSchedule.splice(indexSchedule,1);
        setSeassons(seassons2);
       
    }

    function borrarTemporada(id){
      
        var newArray = seassons.filter((item) => item.idSeasson !== id);
        setSeassons(newArray);
    }

    function hideDeleteMessage(){
        setShow(false)
        setHideMessageComponent(true);
    }

    function deletePool(id){
        setShow(false);
        setHideMessageComponent(true);
        requestDeletePool(id,setEditPools,"", "", "", "", "");
        setTimeout (() => {
            history.push("/swimming-pools");
        }, 500);
    }

    function cambiarInit(idSeasson, event){
        var seassons2 = seassons.slice();
        let index = seassons2.findIndex(i => i.idSeasson === idSeasson);
        seassons2[index].init = event;
        setSeassons(seassons2);

    }
    function cambiarFinale(idSeasson, event){
        var seassons2 = seassons.slice();
        let index = seassons2.findIndex(i => i.idSeasson === idSeasson);
        seassons2[index].finale = event;
        setSeassons(seassons2);

    }

    function cambiarOpen(idSeasson, idSchedule, event){
        var seassons2 = seassons.slice();
        let indexSeassons = seassons2.findIndex(i => i.idSeasson === idSeasson);
        let indexSchedule = seassons2[indexSeassons].poolsSchedule.findIndex(i => i.idSchedule === idSchedule);
        seassons2[indexSeassons].poolsSchedule[indexSchedule].open = (event.getHours()<10?'0':'') + event.getHours() + ":" + (event.getMinutes()<10?'0':'') + event.getMinutes()+ ":"+  (event.getSeconds()<10?'0':'') + event.getSeconds();

        setSeassons(seassons2);
    }

    function cambiarClose(idSeasson, idSchedule, event){
        var seassons2 = seassons.slice();
        let indexSeassons = seassons2.findIndex(i => i.idSeasson === idSeasson);
        let indexSchedule = seassons2[indexSeassons].poolsSchedule.findIndex(i => i.idSchedule === idSchedule);
        seassons2[indexSeassons].poolsSchedule[indexSchedule].close = (event.getHours()<10?'0':'') + event.getHours() + ":" + (event.getMinutes()<10?'0':'') + event.getMinutes() + ":"+  (event.getSeconds()<10?'0':'') + event.getSeconds();

        setSeassons(seassons2);
    }

    ClassicEditor.defaultConfig = {
        toolbar: {
          items: [
  
            'link',
            '|',
            'bold',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo'
          ]
        },
        image: {
          toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative'
          ]
        },
        wordCount: {
            showParagraphs: false,
            showWordCount: true,
            showCharCount: true,
            countSpacesAsChars: false,
            countHTML: true,
            maxWordCount: -1,
            maxCharCount: 10,
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },
        language: 'es'
    };

    function handleSelectAction(action){

        if(action === "CREAR"){
            setTimeout (() => {
                history.push("/create-pool"); 
            }, 100); 

        }else if(action === "ELIMINAR"){
          showDeleteMessage(id)
          
        }
    }
    function showDeleteMessage(id){
        setShow(true)
        setHideMessageComponent(false);
        setDelId(id);
      }
 
    let emptyseasson;
    

    if(seassons.length > 0){
      
        emptyseasson = seassons.map(data => ( 
                <div className="ui three column divided grid"> 
                    <div className= "one wide column" style={{ marginLeft:20, marginRight:20, marginTop:16, padding:0,textAlign:'center',}}>
                        <div className="ui segment">
                            <button className="ui black button" style={{paddingLeft:15,paddingRight:8}} onClick={() => borrarTemporada(data.idSeasson)}><i className="trash alternate outline icon" /></button>
                        </div>
                    </div>
                    <div className="three wide column">
                        <div className="ui segment">
                            <label><b><FormattedMessage id="piscinas.editar.temporada.inicio" defaultMessage="Inicio"/></b></label>
                            <DatePicker dateFormat="dd/MM/yyyy" value={Moment(data.init).format("DD/MM/YYYY")} onChange={event => cambiarInit(data.idSeasson, event)} />
                        </div>
                    </div>
                    <div  className="three wide column">
                        <div className="ui segment">
                            <label><b><FormattedMessage id="piscinas.editar.temporada.fin" defaultMessage="Fin"/></b></label>
                            <DatePicker dateFormat="dd/MM/yyyy" value={Moment(data.finale).format("DD/MM/YYYY")} onChange={event => cambiarFinale(data.idSeasson, event)}/>
                        </div>
                    </div>
                    <div  className="seven wide column">
                        <div className="ui segment" style={{float:'left'}}>
                            {data.poolsSchedule.map(dataS => (
                                <div className="one wide column">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{width:110}}>
                                                    <label><b><FormattedMessage id="piscinas.editar.horario.desde" defaultMessage="Desde"/></b></label>
                                                    <DatePicker showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="hh:mm" value={(dataS.open)} onChange={event => cambiarOpen(data.idSeasson,dataS.idSchedule, event)} />
                                                </td>
                                                <td  style={{width:110}}>
                                                    <label><b><FormattedMessage id="piscinas.editar.horario.hasta" defaultMessage="Hasta"/></b></label>
                                                    <DatePicker showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="hh:mm" value={(dataS.close)} onChange={event => cambiarClose(data.idSeasson,dataS.idSchedule, event)} /> 
                                                </td>
                                                <td><button className="ui lantik-color-button button" style={{paddingLeft:15,paddingRight:8,marginLeft:5, marginTop:18}} onClick={() => añadirSchedule(data.idSeasson)}><i className="plus icon" /></button></td>
                                                <td>{data.poolsSchedule.length !== 1 ? <button className="ui black button" style={{paddingLeft:15,paddingRight:8, marginTop:18}} onClick={() => borrarSchedule(data.idSeasson,dataS.idSchedule)}><i className="trash alternate outline icon" /></button>: ""}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ));
        
    }else {
        emptyseasson =  <table><tbody><tr><td colSpan="5" style={{textAlign: 'center', fontSize: '25px', padding: '20px'}}> <FormattedMessage id="piscinas.editar.noExistenTemporadas" defaultMessage="No existen temporadas"/></td></tr></tbody></table>;

    }

     function handlePushNavigation(){
        history.push("/swimming-pools");
    }


    return (
        <>
         <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handlePushNavigation}><FormattedMessage id="piscinas.editar.piscinas" defaultMessage="Piscinas" /></a>
            <i className="right large angle icon divider"></i>
            <i className="section"><FormattedMessage id="piscinas.editar" defaultMessage="Editar piscinas" /></i>
        </div>
        <div className="mainDiv"> 

            <div className="div-inLine">
                <label className="title"><FormattedMessage id="piscinas.editar" defaultMessage="Editar piscina"/></label>  
                <div className="red-box">
                    <select className="ui red-Select select" style={{backGroundColor:"red"}} defaultValue="ES" name="selectType" onChange={event => setLang(event.target.value)}>
                        <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                            {(message) => <option value="ES">{message}</option>}
                        </FormattedMessage>
                            <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                            {(message) => <option value="EU">{message}</option>}
                        </FormattedMessage>
                    </select>
                
                    <select className="ui select " style={{backgroundColor:"black", color:"white", fontFamily:"serif bold",width:'auto', float:'right'}} defaultValue="" onChange={event => handleSelectAction(event.target.value)} name="selectState">
                        <FormattedMessage id="piscinas.editar.acciones" defaultMessage="Acciones">
                            {(message) => <option value="" disabled>{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="piscinas.editar.acciones.eliminar" defaultMessage="Eliminar">
                            {(message) => <option value="ELIMINAR" style={{color:'black'}}>{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="piscinas.editar.acciones.crearNuevo" defaultMessage="Crear Nueva">
                            {(message) => <option value="CREAR" style={{color:'black'}}>{message}</option>}
                        </FormattedMessage>
                    </select>

                </div> 
            </div>
      
  
            <div className="ui form">
                <br></br>
                <label style={{fontSize:20, marginLeft:14}}><b><FormattedMessage id="piscinas.editar.datosAPI" defaultMessage="Datos de la API"/></b></label>    
                <div className="ui grid" style={{height:'auto', paddingBottom:20, marginTop:10, marginLeft:0}}>
                    <div className="five wide column">
                        <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}><b><FormattedMessage id="piscinas.editar.nombreES" defaultMessage="Nombre en Castellano *"/></b></label>
                        <input type="text" style={{background:'#eaeaea'}} className={`${hideEsComponent ? "hide-EsComponent" : ""}`}  disabled value={nameESAPI} />
                        <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}><b><FormattedMessage id="piscinas.editar.nombreEUS" defaultMessage="Nombre en Euskera *"/></b></label>
                        <input type="text" style={{background:'#eaeaea'}} className={`${hideEuComponent ? "hide-EuComponent" : ""}`} disabled value={nameEUAPI}/>
                    </div>  
                    <div className= "seven wide column">
                        <label><b><FormattedMessage id="piscinas.editar.imagenUrl" defaultMessage="Url de la imagen *"/></b></label>
                        <input type="text" style={{background:'#eaeaea'}} disabled value={imageUrl} />
                    </div>
                </div>

                <div className="ui grid" style={{marginTop:'auto', paddingTop:10}}>
                    <div className= "five wide column" style={{paddingLeft:30}}>
                        <label><b><FormattedMessage id="piscinas.editar.municipio" defaultMessage="Municipio *"/></b> </label>                
                        <select className="ui select" style={{background:'#eaeaea'}} value={townCode} name="selectTown"  disabled >
                            {townsBBDD.map(data => (
                                <option key={data.idTown}  value={data.townCode}>{data.townNameEs}</option>
                            ))
                            }
                        </select>
                    </div>
                    <div className="four wide column">
                        <label><b><FormattedMessage id="piscinas.editar.codigoMunicipio" defaultMessage="Código de municipio *"/> </b></label>
                        <input type="text" style={{background:'#eaeaea'}} value={townCode} disabled />
                    </div>
                    <div className="three wide column">
                        <label><b><FormattedMessage id="piscinas.editar.idInstalacion" defaultMessage="ID instalación *"/></b></label>
                        <input type="text" style={{background:'#eaeaea'}} value={facilityCode} disabled />
                    </div>
                </div>
                <div className="ui grid">
                    <div className="twelve wide column" >
                        <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                            <div  style={{paddingLeft:17, paddingBottom:15}}>
                                <label><b><FormattedMessage id="piscinas.editar.descripcionES" defaultMessage="Descripción Castellano"/></b></label>
                                <div contentEditable='false'  className="divContentEditableFalse" disabled dangerouslySetInnerHTML={{__html: descriptionES}}></div>
                            </div>
                        </div>
                        <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                            <div  style={{paddingLeft:17, paddingBottom:15}}>
                                <label><b><FormattedMessage id="piscinas.editar.descripcionEU" defaultMessage="Descripción Euskera"/></b></label>
                                <div contentEditable='false' className="divContentEditableFalse" disabled dangerouslySetInnerHTML={{__html: descriptionEU}}></div>
                             </div>
                        </div>
                    </div>
                </div>
                <br/><br/>
                <div><label style={{fontSize:20,paddingLeft:15}}><b><FormattedMessage id="piscinas.editar.datosNoAPI" defaultMessage="Datos modificables"/></b></label>  </div>
                <br/>
                <div className="ui grid">
                    <div className="twelve wide column" style={{paddingLeft:30}}>
                        <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}><b><FormattedMessage id="piscinas.editar.nombreES" defaultMessage="Nombre en Castellano *"/></b></label>
                        <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}  value={nameES}  onChange={event => setNameES(event.target.value)}/>
                        <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}><b><FormattedMessage id="piscinas.editar.nombreEUS" defaultMessage="Nombre en Euskera *"/></b></label>
                        <input type="text" className={`${hideEuComponent ? "hide-EuComponent" : ""}`} value={nameEU}  onChange={event => setNameEU(event.target.value)}/>
                    </div>
                </div>
                <div className="ui grid">
                    <div className= "six wide column" style={{paddingLeft:30}}>
                    <label><b><FormattedMessage id="piscinas.editar.urlWeb" defaultMessage="URL web"/></b></label>
                        <input type="text" value={urlWeb} onChange={event => setUrlWeb(event.target.value)}/>
                    </div>
                    <div className= "six wide column" style={{paddingLeft:30}}>
                        <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}><b><FormattedMessage id="piscinas.editar.nombreWebES" defaultMessage="Nombre Web Castellano"/></b></label>
                        <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}  value={nameWebES} onChange={event => setNameWebES(event.target.value)}/>
                        <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}><b><FormattedMessage id="piscinas.editar.nombreWebEU" defaultMessage="Nombre Web Euskera"/></b></label>
                        <input type="text" className={`${hideEuComponent ? "hide-EuComponent" : ""}`}  value={nameWebEU} onChange={event => setNameWebEU(event.target.value)}/>
                    </div>
                </div>
                <br/><br/>
                <div><label style={{fontSize:18,paddingLeft:15}}><b><FormattedMessage id="piscinas.editar.temporada" defaultMessage="Temporada"/></b></label>  </div>
                <br/>
                   
                {emptyseasson}
                
                <div className="ui grid">
                    <div className= " twelve wide column" style={{marginLeft:0}}>
                        <button className="ui lantik-color-button button" style={{backgroundColor:'white', color:'red',borderStyle:'solid', borderWidth:2, borderRadius:10,borderColor:'red', float:'right'}} onClick={() => añadirTemporada()}><FormattedMessage id="piscinas.editar.anadirFranja" defaultMessage="Añadir Franja"/></button>
                    </div>
                </div>
                <div style={{paddingLeft:15, paddingTop:18, paddingBottom:5}}>
                    <button className="ui lantik-color-button button"  onClick={() => validateFields()}><FormattedMessage id="piscinas.editar.guardar" defaultMessage="GUARDAR"/></button>
                    <button className="ui noBorder button"  onClick={() => handleClick()}><FormattedMessage id="piscinas.editar.cancelar" defaultMessage="CANCELAR"/></button>
                </div>
                <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
                    <div><span>{errorMsg}</span></div>
                    <div><button className="ui lantik-color-button button" style={{marginTop:17}} onClick={() => setHideErrorComponent(true)}><FormattedMessage id="piscinas.editar.aceptar" defaultMessage="Aceptar"/></button></div>
                </div> 
            </div>
            <BackDrop show={show}></BackDrop> 
            <div className={`${hideMessageComponent ? "hide-MessageComponent" : "MessageComponent"}`}>
                <div><span className='msgHead'><i className="ui headIcon trash alternate outline icon"></i></span></div>
                <div className='msgBody'>
                    <span>
                    <label><FormattedMessage id="piscinas.editar.preguntaBorrado" defaultMessage="¿Seguro que quiere eliminar esta Piscina?"/></label><br></br>
                    <FormattedMessage id="piscinas.editar.avisoBorrado" defaultMessage="Esta acción es irrversible, por lo que se perderán todos los datos."/>
                    </span>
                </div>
                <div className='msgBottom'>
                    <span>               
                    <button className="ui noBorder button" onClick={() => hideDeleteMessage(true)}><FormattedMessage id="piscinas.editar.cancelarMinus" defaultMessage="Cancelar"/></button>
                    <button className="ui lantik-color-button button" onClick={() => deletePool(delId)}><FormattedMessage id="piscinas.editar.eliminarMinus" defaultMessage="Eliminar"/></button>
                    </span>
                </div>

            </div>
        </div>
       
        </>
     
    )
}
export default EditSwimmingPool;
