import React, {useState} from 'react'; 
import {useParams,useHistory} from 'react-router-dom';
import {requestGetNewById, RequestPutNew,requestDeleteNew} from '../../services/NewsService';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker,{registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import UserService from '../../services/UserService';
import axios from "axios";
import SelectFile from "../media/SelectFile"
import { requestGetCategories } from '../../services/CategoriesService';
import {FormattedMessage} from "react-intl";
import {requestSendNotification} from '../../services/NotificationService'





  const EditNews = () => {

  
    const [editNews, setEditNews] = useState("");
    const [titleEs, setTitleEs] = useState("");
    const [titleEu, setTitleEu] = useState("");
    const [bodyEs, setBodyEs] = useState("");
    const [bodyEu, setBodyEu] = useState("");
    const [status, setStatus] = useState("");
    const [publishingDate, setPublishingDate] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [hideStatusComponent, setHideStatusComponent] = useState(true);
    const [hideImageEuComponent, setHideImageEuComponent] = useState(true);
    const [hideImageEsComponent, setHideImageEsComponent] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [imageUrlEu, setImageUrlEu] = useState("");
    const [errorMsg, setErrorMsg] = useState();
    const [statusMsg, setStatusMsg] = useState();
    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    const [hideGaleryComponent, setGaleryComponent] = useState(true);
    const [hideGaleryEuComponent, setGaleryEuComponent] = useState(true);
    const [show, setShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [categoriesNew, setCategoriesNew] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [categoriesNameNewEs, setCategoriesNameNewEs] = useState([]);
    const [hideMessageComponent, setHideMessageComponent] = useState(true);
    const [hideConfirmationMessageComponent, setHideConfirmationMessageComponent] = useState(true);
    const [delId, setDelId] = useState("");
    const [statusNotification, setStatusNotification] = useState(false);


    let history = useHistory();
    const { id } = useParams();
    let selImage = "";
    let selImageEu = "";


    const dateMessage =
      <div>
        <i className="big exclamation red circle icon"></i>
        <p><FormattedMessage id="noticias.editar.error.fechayhoraIncorrectas" defaultMessage="La fecha y hora seleccionada es incorrecta"/></p>
      </div>

    const lostChangeMessage =
      <div>
        <i className="big exclamation red circle icon"></i>
        <p><FormattedMessage id="noticias.editar.avisoPerderCambios" defaultMessage="Los cambios realizados hasta ahora se perderán ¿desea continuar?"/></p>
      </div>

    const statusMessagePublished =
       <div>
        <i className="big exclamation red circle icon"></i>
        <p><FormattedMessage id="noticias.editar.error.estadoaPublicado" defaultMessage="El estado ha pasado a PUBLICADO"/></p>
      </div>  

    const statusMessageDraft =
      <div>
        <i className="big exclamation red circle icon"></i>
        <p><FormattedMessage id="noticias.editar.error.estadoaProgramado" defaultMessage="El estado ha pasado a PROGRAMADO"/></p>
      </div>


    const confirmationNotificationMessage =
      <div>
        <i className="big check green circle icon"></i>
        <p><FormattedMessage id="noticias.editar.noticiaNotificada" defaultMessage="La noticia ha sido notificada" /></p>
      </div>

    const fieldsMessage = 
    <div >
    <i className="big exclamation red circle icon"></i>
    <p><FormattedMessage id="noticias.editar.error.camposObligatorios" defaultMessage="Los campos marcados con asterisco son obligatorios. Por favor revise que esten completos en los dos idiomas. "/></p>
      
    </div>

  
    React.useEffect(() => {
      requestGetCategories(setCategories);
      registerLocale("es", es);
      getData();

    }, [id])  
   

    const getData = async () => {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + UserService.getToken()
      }

  const url = process.env.REACT_APP_URL_FULL_NEWS + `/${id}`;
  const data = await axios.get(url, { 'headers': headers })
  const dataNew = await data.data;


  requestGetNewById(setEditNews, id);
  setImageUrl(dataNew.mainImage);
  setImageUrlEu(dataNew.mainImageEu);
  setTitleEs(dataNew.titleEs);
  setTitleEu(dataNew.titleEu);
  setCategoriesNew(dataNew.categories);
  setBodyEs(dataNew.bodyEs);
  setBodyEu(dataNew.bodyEu);
  setPublishingDate(new Date(dataNew.publishingDate));
  setExpirationDate(new Date(dataNew.expirationDate));
  setStatus(dataNew.status);
    

  if (selImage === undefined || selImage === "") {
    setImageUrl(dataNew.mainImage)


  } else if (selImageEu === undefined || selImageEu === "") {
    setImageUrlEu(dataNew.mainImageEu)

  } else {
    setImageUrl(selImage)
    setImageUrlEu(selImageEu)

  }
     
    
    window["urlImage"] = ""
 

}

function validateFields () {

  let valError = false;
  if( 
      titleEs === "" || titleEs === undefined ||
      titleEu === "" || titleEu === undefined ||
      bodyEs  === "" || bodyEs  === undefined ||
      bodyEu  === "" || bodyEu  === undefined ||
      status  === "" || status  === undefined
    
    )
  {

        valError = true;

  }

  if(valError){
    setErrorMsg(fieldsMessage);
    setHideErrorComponent(false);
    
  }else {
    updateNews();
  } 
} 

    function updateNews() {

      window["urlImage"] = ""

      const body = {

        imageUrl: imageUrl,
        imageUrlEu: imageUrlEu,
        titleEs: titleEs,
        bodyEs: bodyEs,
        titleEu: titleEu,
        bodyEu: bodyEu,
        categories: arr,
        publishingDate: new Date(publishingDate),
        expirationDate: new Date(expirationDate),
        status: status

      }  
  

      RequestPutNew(id, body)
        .then(response => showConfirmationMessage())
      


    }

 
    function selectedImage() {
      selImage = window["urlImage"]
      setImageUrl(selImage)
      hideGallery()
    }

    function selectedImageEu() {
      selImageEu = window["urlImage"]
      setImageUrlEu(selImageEu)
      hideGalleryEu()

    }

    function showGallery() {
      setShow(true)
      setGaleryComponent(false);
    }
    function hideGallery() {
      setShow(false)
      setGaleryComponent(true);
    }

    function showGalleryEu() {
      setShow(true);
      setGaleryEuComponent(false);
    }

    function hideGalleryEu() {
      setShow(false);
      setGaleryEuComponent(true);
    }

  
    function setLang(lang) {

      if (lang === "ES") {
        setHideEsComponent(false)
        setHideEuComponent(true)

        setHideImageEsComponent(false)
        setHideImageEuComponent(true)


      } else {

        setHideEsComponent(true)
        setHideEuComponent(false)

        setHideImageEsComponent(true)
        setHideImageEuComponent(false)

      }
    }



    function handleClick() {

      history.push("/news");

    }

    let categoriesNameEs = 
  

      categories.map(cat => (
        {
          value: cat.id,
          label: cat.nameEs
        }
      ))

    let categoriesNewNameEs =

      categoriesNew.map(cat => (
        {

          value: cat.id,
          label: cat.nameEs
        }
      ))
    if (categoriesNewNameEs != null && categoriesNewNameEs.length > 0 && categoriesNameNewEs.length == 0) {
      setCategoriesNameNewEs(categoriesNewNameEs);
    }

    const selectedCategoriesEs = (e) => {

      setCategoriesNameNewEs(Array.isArray(e) ? e : []);

    }
    
    const arr = categoriesNameNewEs.map(item => {
      return { id: item.value, nameEs: item.label }

    })
        
    const BackDrop = ({ show }) =>
      <div className="fullscreen-container"
        style={{ display: show ? "block" : "none" }}></div>

    const BackDropConfirmation = ({ showConfirmation }) =>
      <div className="fullscreen-container"
        style={{ display: showConfirmation ? "block" : "none" }}></div>



    function deleteNew(id) {
      setShow(false);
      setHideMessageComponent(true);
      requestDeleteNew(id, setEditNews)
      setTimeout (() => {
        history.push("/news");
    }, 500);
    

    }

    function showConfirmationMessage() {
      setShowConfirmation(true);
      setHideConfirmationMessageComponent(false);

    }

    function hideConfirmationMessage() {
      setShowConfirmation(false);
      setHideConfirmationMessageComponent(true);
      if(status === "PUBLICADO"){
        setStatusNotification(true);
      }
      
    }

    function showDeleteMessage(id) {
      setShow(true);
      setHideMessageComponent(false);
      setDelId(id);
    }



    function hideDeleteMessage() {
      setShow(false)
      setHideMessageComponent(true);
    }

     function handleSelectAction(action) {
      let valError = false;

      if (action === "CREAR") {
        valError = true;

      } if (valError) {
        setErrorMsg(lostChangeMessage);
        setHideErrorComponent(false)
       
      } else if (action === "ELIMINAR") {
        showDeleteMessage(id)

      }
    } 
    

 
    function handlePushNavigation() {
      history.push("/news");
    }

      
      function goToNewsList (){
        history.push("/news");
      }


    function changePublishingDate(date) {
      let valError = false;
      if (date < Date.now()) {
        valError = true;

      } if (valError) {
        setErrorMsg(dateMessage);
        setHideErrorComponent(false);
        setPublishingDate().setDate(0)
      }
      setPublishingDate(date)

      let newExpirationDate = new Date(date);
      var d = newExpirationDate.getDate();
      newExpirationDate.setMonth(newExpirationDate.getMonth() + + 6);

      if (newExpirationDate.getDate() !== d) {
        newExpirationDate.setDate(0);
      }
      setExpirationDate(newExpirationDate)
    }

//Text editor configuration
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [

          'link',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      },
      language: 'es'
    };

   
    function sendNotification() {

      if(statusNotification === true){
          const data = {
          
            id: id,
            title: titleEs,
            token: "",
            topic:process.env.REACT_APP_SPANISH_TOPIC_NOTIFICATION
            
          }

       requestSendNotification(data);

       const dataEu = { 
        id: id,
        title: titleEu,
        token: "",
        topic: process.env.REACT_APP_BASQUE_TOPIC_NOTIFICATION
        
      }  

       requestSendNotification(dataEu);
       setErrorMsg(confirmationNotificationMessage);
       setHideErrorComponent(false);

      }
    }


    function statusMessage(action) {

      if (action === "PUBLICADO") {
        setErrorMsg(statusMessagePublished);
        setHideErrorComponent(false);

      } else if (action === "PROGRAMADO") {
        setErrorMsg(statusMessageDraft);
        setHideErrorComponent(false);
      }

      setStatus(action)
    }

     

    return (

      <>

        <div className="ui large breadcrumb" style={{ paddingBottom: 15 }}>
          <i className="home red icon"></i>
          <a className="section" style={{ color: "red" }} onClick={handlePushNavigation}><FormattedMessage id="noticias.editar.noticias" defaultMessage="Noticias" /></a>
          <i className="right large angle icon divider"></i>
          <i className="section" ><FormattedMessage id="noticias.editar.editarNoticia" defaultMessage="Editar noticia" /></i>
        </div>
        <div className="mainDiv" >

          <div className="div-inLine">
            <label className="title" ><FormattedMessage id="noticias.editar.editarNoticia" defaultMessage="Editar Noticia" /></label>
            <div className="red-box">

              <select className="ui red-Select select" style={{ backGroundColor: "red" }} defaultValue="ES" name="selectType" onChange={event => setLang(event.target.value)}>
                <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                  {(message) => <option value="ES">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                  {(message) => <option value="EU">{message}</option>}
                </FormattedMessage>
              </select>



              {/* 
       <button class="ui red button" style={{marginLeft:1000, marginTop:10, width:5, height:40}}><i class="bell large outline icon " style={{paddingRight: 10}}></i></button>
       */}




<select className="ui select " style={{marginTop:20, paddingRight:10, backgroundColor:"black", color:"white", fontFamily:"serif bold",width:'auto', float:'right'}} defaultValue={""} onChange={event => handleSelectAction(event.target.value)} name="selectState">
                        <FormattedMessage id="piscinas.editar.acciones" defaultMessage="Acciones">
                            {(message) => <option value="" disabled>{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="piscinas.editar.acciones.eliminar" defaultMessage="Eliminar">
                            {(message) => <option value="ELIMINAR" style={{color:'black'}}>{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="piscinas.editar.acciones.crearNuevo" defaultMessage="Crear Nueva">
                            {(message) => <option value="CREAR" style={{color:'black'}}>{message}</option>}
                        </FormattedMessage>
                    </select>

            </div>
           

          </div>
        


          <div className="ui form">
            {/* <button className='ui black button' style={{ float: "right", height: 40, marginRight: 60 }} disabled={statusNotification === false} onClick={() => sendNotification()}><FormattedMessage id="noticias.editar.notificar" defaultMessage="Notificar" /></button> */}
            <div className="ui grid" style={{ height: 100 }}>

              <div className="seven wide column" style={{ marginLeft: 5 }}>
                <label><b><FormattedMessage id="noticias.editar.estado"  defaultMessage="Estado" /> *</b></label>
                <select value={status} className="ui select " name="selectState" onChange={event => statusMessage(event.target.value)}>
                  <FormattedMessage id="noticias.editar.estado.seleccione" defaultMessage="Seleccione...">
                    {(message) => <option value="">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="noticias.editar.estado.publicado" defaultMessage="Publicado">
                    {(message) => <option value="PUBLICADO">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="noticias.editar.estado.programado" defaultMessage="Programado">
                    {(message) => <option value="PROGRAMADO" style={{ color: "black" }}>{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="noticias.editar.estado.noPublicado" defaultMessage="No Publicado">
                    {(message) => <option value="BORRADOR" style={{ color: "black" }}>{message}</option>}
                  </FormattedMessage>
                </select>
              </div>


              <div className="six wide column">
                <div className={`${hideImageEsComponent ? "hide-ImageEscomponent" : ""}`}  >
                <button className="pencil-icon" style={{ marginLeft: 250, marginTop: 5 }} onClick={() => showGallery(false)}><i className="ui white pencil icon"></i></button>
                  <img className="formImage" style={{ width: 250, height: 150, marginLeft: 50, marginTop: 10 }} src={imageUrl} alt="Imagen" />
                </div>


                <div className={`${hideImageEuComponent ? "hide-ImageEucomponent" : ""}`} >
                  <button className="pencil-icon" style={{ marginLeft: 250, marginTop: 5 }} onClick={() => showGalleryEu(false)}><i className="ui white pencil icon"></i></button>
                  <img className="formImage" style={{ width: 250, height: 150, marginLeft: 50, marginTop: 10 }} src={imageUrlEu} alt="Imagen" />
                </div>
              </div>

            </div>


            <div className="ui grid">
              <div className="three wide column" style={{ paddingLeft: 30 }}>
                <label><b><FormattedMessage id="noticias.editar.fechPublica" defaultMessage="Fecha de publicación" /></b> </label>
                <DatePicker
                  locale="es"
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  showTimeSelect
                  minDate={new Date()}
                  selected={publishingDate} 
                  onChange={(date) => changePublishingDate(date)} />
              </div>


              <div className="three wide column">
                <label><b><FormattedMessage id="noticias.editar.fechExpirac" defaultMessage="Fecha de expiración" /> </b></label>
                <DatePicker
                  locale="es"
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  showTimeSelect
                  minDate={new Date()}
                  selected={expirationDate} 
                  onChange={(date) => setExpirationDate(date)} />
              </div>
            </div>


            <div className="ui grid">
              <div className="fifteen wide column" style={{ paddingLeft: 30 }}>
                <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}><b><FormattedMessage id="noticias.editar.tituloEs" defaultMessage="Título Castellano *" /></b></label>
                <input
                  onChange={event => setTitleEs(event.target.value)}
                  type="text"
                  className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                  value={titleEs}>


                </input>
                <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}><b><FormattedMessage id="noticias.editar.tituloEus" defaultMessage="Título Euskera *" /></b></label>
                <input className={`${hideEuComponent ? "hide-EuComponent" : ""}`}
                  value={titleEu}
                  onChange={event => setTitleEu(event.target.value)}>
                </input>
              </div>
            </div>


    {/*         <div className='ui divided items' style={{ paddingLeft: 19 }}>
              <div className="ui grid">
                <div className="fifteen wide column">
                  <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                    <label><FormattedMessage id="noticias.editar.categoriaES" defaultMessage="Categoría Castellano " /></label>
                    <div>

                      <Select placeholder="agregar categoría ..."
                        isMulti
                        options={categoriesNameEs}
                        value={categoriesNameNewEs}
                        onChange={selectedCategoriesEs}>
                      </Select>
                    </div>
                  </div>
                </div>


                <div className="fifteen wide column">
                  <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                    <label><FormattedMessage id="noticias.editar.categoriaEUS" defaultMessage="Categoria Euskera " /></label>
                    <div>

                      <Select placeholder="agregar categoría ..."

                        isMulti
                        options={categoriesNameEs}
                        value={categoriesNewNameEs}
                        onChange={selectedCategoriesEs}>
                      </Select>
                    </div>
                  </div>
                </div>


              </div> 

            </div> */}


            <div className='ui grid'>
              <div className='fifteen wide column'>

                <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                  <div style={{ paddingLeft: 17, paddingBottom: 15, }}>
                    <label><b><FormattedMessage id="noticias.editar.contenidoEs" defaultMessage="Contenido en castellano *" /></b></label>
                    <CKEditor
                      style={{ paddingRight: 10 }}
                      editor={ClassicEditor}
                      data={bodyEs}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setBodyEs(data)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='ui grid'>
              <div className='fifteen wide column'>
                <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                  <div style={{ paddingLeft: 17, paddingBottom: 15 }}>
                    <label><b><FormattedMessage id="noticias.editar.contenidoEus" defaultMessage="Contenido en euskera *" /></b></label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={bodyEu}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setBodyEu(data)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: 15, paddingTop: 18, paddingBottom: 5 }}>
              <button className="ui lantik-color-button button" onClick={() => validateFields()}><FormattedMessage id="noticias.editar.guardar" defaultMessage="Guardar" /></button>
              <button className="ui noBorder button" onClick={() => handleClick()}><FormattedMessage id="noticias.editar.cancelar" defaultMessage="Volver" /></button>
            </div>
            {/* <p><FormattedMessage id="noticias.editar.notificarAviso" defaultMessage="* Para poder notificar la noticia debe de estar guardada y en estado publicado"/></p> */}
            <div className={`${hideGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>
              <SelectFile></SelectFile>
              <div>
                <button className="ui lantik-color-button button" onClick={() => selectedImage()}><FormattedMessage id="noticias.editar.guardar" defaultMessage="Guardar" /></button>
                <button className="ui noBorder button" onClick={() => hideGallery(true)}><FormattedMessage id="noticias.editar.cancelar" defaultMessage="Cancelar" /></button>
              </div>
            </div>

            <div className={`${hideGaleryEuComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>
              <SelectFile></SelectFile>
              <div>
                <button className="ui lantik-color-button button" onClick={() => selectedImageEu()}><FormattedMessage id="noticias.editar.guardar" defaultMessage="Guardar" /></button>
                <button className="ui noBorder button" onClick={() => hideGalleryEu(true)}><FormattedMessage id="noticias.editar.cancelar" defaultMessage="Cancelar" /></button>
              </div>
            </div>
            <BackDrop show={show}></BackDrop>
            <div className={`${hideMessageComponent ? "hide-MessageComponent" : "MessageComponent"}`}>
              <div><span className='msgHead'><i className="ui headIcon trash alternate outline icon"></i></span></div>
              <div className='msgBody'>
                <span>
                  <label><FormattedMessage id="noticias.editar.confEliminar" defaultMessage="¿Seguro que quiere eliminar esta Noticia?" /></label><br></br>
                  <FormattedMessage id="noticias.editar.avisoEliminar" defaultMessage="Esta acción es irreversible, por lo que se perderán todos los datos." />
                </span>
              </div>
              <div className='msgBottom'>
                <span>
                  <button className="ui noBorder button" onClick={() => hideDeleteMessage(true)}><FormattedMessage id="noticias.editar.cancelar" defaultMessage="Cancelar" /></button>
                  <button className="ui lantik-color-button button" onClick={() => deleteNew(delId)}><FormattedMessage id="noticias.editar.eliminar" defaultMessage="Eliminar" /></button>
                </span>
              </div>
            </div>
            <BackDropConfirmation showConfirmation={showConfirmation}></BackDropConfirmation>
            <div className={`${hideConfirmationMessageComponent ? "hide-ConfirmationMessageComponent" : "ConfirmationMessageComponent"}`}>
              <div><span className='msgHead'><i className="ui headIcon check icon"></i></span></div>
              <div className='msgBody'>
                <span>
                  <label><FormattedMessage id="noticias.editar.noticiaGuardada" defaultMessage="La noticia ha sido guardada correctamente" /></label><br></br>
                </span>
              </div>
              <div className='msgBottom'>
                <span>
                  <button className="ui noBorder button" onClick={() => goToNewsList()}><FormattedMessage id="noticias.editar.listado" defaultMessage="Ir al listado" /></button>
                  <button className="ui lantik-color-button button" onClick={() => hideConfirmationMessage()}><FormattedMessage id="noticias.editar.continuarEditando" defaultMessage="Continuar editando" /></button>
                </span>
              </div>
            </div>
            <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
              <div><span>{errorMsg}</span></div>
              <div><button className="ui lantik-color-button button" style={{ marginTop: 5 }} onClick={() => setHideErrorComponent(true)}><FormattedMessage id="noticias.editar.aceptar" defaultMessage="Aceptar" /></button></div>
            </div>
            <div className={`${hideStatusComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
              <div><span>{statusMsg}</span></div>
              <div><button className="ui lantik-color-button button" style={{ marginTop: 5 }} onClick={() => setHideStatusComponent(true)}><FormattedMessage id="noticias.editar.aceptar" defaultMessage="Aceptar" /></button></div>
            </div>
          </div>
        </div>


      </>


    )


}
            
export default EditNews;
