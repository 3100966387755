import {FormattedMessage} from 'react-intl';
import React, { useState} from "react";
import { Box, Grid } from '@mui/material';
import { sendVideocallNotice } from '../../services/VideocallService'; 
import { color } from 'echarts';


const LabVideocallNotice = (props) => {

    const FIELDS = {
        INPUT_PERSON_MASTER_ID: "bupPersonMasterId",
        INPUT_DNI: "dni",
        INPUT_TITLE: "title",
        INPUT_BODY: "body",
    };

    const MAX_LENGTH = {
        [FIELDS.INPUT_PERSON_MASTER_ID]: 10,
        [FIELDS.INPUT_DNI]: 9,
        [FIELDS.INPUT_TITLE]: 50,
        [FIELDS.INPUT_BODY]: 150,
    }

    const getVideocallInitialState = () => {
        return {
            [FIELDS.INPUT_PERSON_MASTER_ID]: "",
            [FIELDS.INPUT_DNI]: "",
            [FIELDS.INPUT_TITLE]: "",
            [FIELDS.INPUT_BODY]: ""
        };
    };

    const getVideocallValidationInitialState = () =>{
        return {
            [FIELDS.INPUT_PERSON_MASTER_ID]: true,
            [FIELDS.INPUT_DNI]: true,
            [FIELDS.INPUT_TITLE]: true,
            [FIELDS.INPUT_BODY]: true
        };        
    }

    const [videocall, setVideocall] = useState(getVideocallInitialState());
    const [videocallValidation, setVideocallValidation] = useState(getVideocallValidationInitialState());
    const [response, setResponse] = useState("");
    const [dniBase64, setDniBase64] =  useState("");
    const [disabledInput, setDisabledInput] = useState("");

    const handleVideocallInputChange = (event) => {
        const { name, value} = event.target;
        setVideocallInputValue(name, value)
        setVideocallValidationValue(name, value);
    };

    const setVideocallInputValue = (name, value) => {        
        if (value.length <= MAX_LENGTH[name]) {
            setVideocall({...videocall, [name]: value});
        }
    }

    const setVideocallValidationValue = (name, value) =>{

        if(name == FIELDS.INPUT_DNI) {
            if(value.length >= 1){
                setDisabledInput(FIELDS.INPUT_PERSON_MASTER_ID);
                setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_PERSON_MASTER_ID]: true }));
            }else{
                setDisabledInput("");
            }

            if(value.length < MAX_LENGTH[name]){
                setDniBase64("");
            } else if(value.length == MAX_LENGTH[name]){
                setDniBase64(btoa(value));
            }
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_DNI]: true }));
        }

        if(name == FIELDS.INPUT_PERSON_MASTER_ID) {
            if(value.length >= 1){
                setDisabledInput(FIELDS.INPUT_DNI);
            }else{
                setDisabledInput("");
            }
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_PERSON_MASTER_ID]: true }));
        }

        if(name == FIELDS.INPUT_TITLE && value !== ""){
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_TITLE]: true }));
        }
        if(name == FIELDS.INPUT_BODY && value !== ""){
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_BODY]: true }));
        }
    }

    const onClickGuardar = () => {
        setVideocallValidation(getVideocallValidationInitialState());
    
        let isRequiredFieldsValidate = true;
    
        if (videocall[FIELDS.INPUT_PERSON_MASTER_ID] === "" && videocall[FIELDS.INPUT_DNI] === "") {
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_PERSON_MASTER_ID]: false }));
            isRequiredFieldsValidate = false;
        }

        if (videocall[FIELDS.INPUT_DNI] !== "" && videocall[FIELDS.INPUT_DNI].length < MAX_LENGTH[FIELDS.INPUT_DNI]) {
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_DNI]: false }));
            isRequiredFieldsValidate = false;
        }

        if(videocall[FIELDS.INPUT_TITLE] === ""){
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_TITLE]: false }));
            isRequiredFieldsValidate = false;
        }
        if(videocall[FIELDS.INPUT_BODY] === ""){
            setVideocallValidation(prev => ({ ...prev, [FIELDS.INPUT_BODY]: false }));
            isRequiredFieldsValidate = false;
        }

        if (isRequiredFieldsValidate) {
            sendVideocallNotice(videocall)
                .then(result =>{
                    console.log("result:");
                    console.log(result);
                    setResponse(result.data);
                })
                .catch(error => {
                    console.log("error:");
                    console.log(error);
                    setResponse(error.data);
                });
        }
    }


    return(
    <>
    {/* Contenedor principal */}
    <div className='mainDiv' style={{paddingTop: 20, paddingRight: 15}}>

        {/* Titulo */}
        <label className="title">
            <i className="flask icon"/>
            <FormattedMessage id="biblioteca.titlee" defaultMessage="Aviso previo videollamada" />
        </label>

        <Grid container spacing={5}>
            {/* Parámetros petición */}
            <Grid item xs={5}>
                <div className='input-column-container' style={{paddingTop: 25, paddingBottom: 20}}>
                    <label className="" style={{color: '#4C4C4C', fontSize: 18, fontWeight: 'bold'}}> 
                        <FormattedMessage id="biblioteca.subtitle.temporadas" defaultMessage="Parámetros petición" />
                    </label>
                </div>

                <div className='ui form input-column-container'>
                    <div className='input-column-auto-width'>
                        <label className='label baseline required' htmlFor={FIELDS.INPUT_PERSON_MASTER_ID}>
                            <FormattedMessage 
                                id="biblioteca.label.fechaInicios" 
                                defaultMessage={FIELDS.INPUT_PERSON_MASTER_ID}/>
                        </label>
                        <input 
                            type="text" 
                            id={FIELDS.INPUT_PERSON_MASTER_ID}
                            name={FIELDS.INPUT_PERSON_MASTER_ID}
                            value={videocall[FIELDS.INPUT_PERSON_MASTER_ID]}
                            className={(videocallValidation[FIELDS.INPUT_PERSON_MASTER_ID])?'':'required-field'}
                            onChange={handleVideocallInputChange}
                            disabled={(disabledInput == FIELDS.INPUT_PERSON_MASTER_ID) ? true : false}
                        />     
                        <span className={(videocall[FIELDS.INPUT_PERSON_MASTER_ID].length < MAX_LENGTH[FIELDS.INPUT_PERSON_MASTER_ID]) ? 'input-length-counter' : 'input-length-counter-red'}>
                            {videocall[FIELDS.INPUT_PERSON_MASTER_ID].length}/{MAX_LENGTH[FIELDS.INPUT_PERSON_MASTER_ID]}
                        </span>                                                     
                    </div>
                    <div className='input-column-auto-width'>
                        <label className='label' htmlFor={FIELDS.INPUT_DNI}>
                            <FormattedMessage 
                                id="biblioteca.label.fechaInicios" 
                                defaultMessage={FIELDS.INPUT_DNI}/>
                            <span style={{color: "teal", fontSize: "0.75em", paddingLeft: 2, opacity: "80%"}}>
                                {"* alternativa a bupPersonMasterId"}
                            </span>
                        </label>
                        <input 
                            type="text" 
                            id={FIELDS.INPUT_DNI}
                            name={FIELDS.INPUT_DNI}
                            value={videocall[FIELDS.INPUT_DNI]}
                            className={(videocallValidation[FIELDS.INPUT_DNI])?'':'required-field'}
                            onChange={handleVideocallInputChange}
                            disabled={(disabledInput == FIELDS.INPUT_DNI) ? true : false}
                        />     
                        <div className={(videocall[FIELDS.INPUT_DNI].length < MAX_LENGTH[FIELDS.INPUT_DNI]) ? 'input-length-counter-detail' : 'input-length-counter-red-detail'}>
                            <div className='base64-info'>
                                {(dniBase64 != "") ? "Base64: " + dniBase64 : ""}
                            </div>
                            <div>
                                {videocall[FIELDS.INPUT_DNI].length}/{MAX_LENGTH[FIELDS.INPUT_DNI]}
                            </div>
                        </div>                                                     
                    </div>                    
                </div>

                <div className='ui form input-column-container'>
                    <div className='input-column-auto-width'>
                        <label className='label baseline required' htmlFor={FIELDS.INPUT_TITLE}>
                            <FormattedMessage 
                                id="biblioteca.label.fechaFins" 
                                defaultMessage={FIELDS.INPUT_TITLE}/>
                        </label>
                        <input 
                            type="text" 
                            id={FIELDS.INPUT_TITLE}
                            name={FIELDS.INPUT_TITLE}
                            value={videocall[FIELDS.INPUT_TITLE]}
                            className={(videocallValidation[FIELDS.INPUT_TITLE])?'':'required-field'}
                            onChange={handleVideocallInputChange}
                        />
                        <span className={(videocall[FIELDS.INPUT_TITLE].length < MAX_LENGTH[FIELDS.INPUT_TITLE]) ? 'input-length-counter' : 'input-length-counter-red'}>
                            {videocall[FIELDS.INPUT_TITLE].length}/{MAX_LENGTH[FIELDS.INPUT_TITLE]}
                        </span>                                                
                    </div>                    
                </div> 

                <div className='ui form input-column-container'>
                    <div className='input-column-auto-width'>
                        <label htmlFor='titulo' className='label required'>
                            <FormattedMessage 
                                id="biblioteca.label.spanishs" 
                                defaultMessage={FIELDS.INPUT_BODY} />
                        </label>
                        <textarea 
                            id={FIELDS.INPUT_BODY}
                            name={FIELDS.INPUT_BODY}
                            value={videocall[FIELDS.INPUT_BODY]}
                            className={(videocallValidation[FIELDS.INPUT_BODY])?'':'required-field'}
                            onChange={handleVideocallInputChange} 
                            style={{resize: 'none'}}
                            rows="4" 
                            cols="50" 
                        />
                        <span className={(videocall[FIELDS.INPUT_BODY].length < MAX_LENGTH[FIELDS.INPUT_BODY]) ? 'input-length-counter' : 'input-length-counter-red'}>
                            {videocall[FIELDS.INPUT_BODY].length}/{MAX_LENGTH[FIELDS.INPUT_BODY]}
                        </span>
                    </div>
                </div>
            </Grid>

            {/* Output */}
            <Grid item xs={7}>
                <div className='input-column-container' style={{paddingTop: 25, paddingBottom: 20}}>
                    <label className="" style={{color: '#4C4C4C', fontSize: 18, fontWeight: 'bold'}}> 
                        <FormattedMessage id="biblioteca.subtitle.temporadas" defaultMessage="Output" />
                    </label>
                </div>                
                <Box
                sx={{ border: '1px solid #ccc', padding: 2, height: '470px', 
                    overflowY: 'scroll', whiteSpace: 'pre-wrap'
                }}>
                {response}
                </Box>
            </Grid>
        </Grid>                
        
        {/* Botón enviar */}
        <div style={{ marginTop:5, marginLeft:14, marginBottom: 15}}>
            <button className="ui lantik-color-button button" onClick={() => onClickGuardar()}>
                <FormattedMessage id="biblioteca.button.guardasr" defaultMessage="Enviar" />
            </button>
        </div>
        
    </div>
    </>
    )
  }
export default LabVideocallNotice;