import {FormattedMessage} from 'react-intl';
import React, { useState} from "react";
import { Box, Grid } from '@mui/material';
import { getSolidusCloudantDevicesList } from '../../services/VideocallService'; 
import { color } from 'echarts';


const LabSolidusCloudantDevices = (props) => {

    const [response, setResponse] = useState("");

    const onClickGuardar = () => {
        getSolidusCloudantDevicesList()
            .then(result =>{
                console.log("result:");
                console.log(result);
                setResponse(result.data);
            })
            .catch(error => {
                console.log("error:");
                console.log(error);
                setResponse(error.data);
            });
    }

    return(
    <>
    <div className='mainDiv' style={{paddingTop: 20, paddingRight: 15}}>

        <label className="title">
            <i className="flask icon"/>
            <FormattedMessage id="biblioteca.titlee" defaultMessage="Solidus lista dispositivos" />
        </label>

        <Grid container spacing={5}>
            <Grid item xs={7} >
                <div className='input-column-container' style={{paddingTop: 25, paddingBottom: 20}}>
                    <label className="" style={{color: '#4C4C4C', fontSize: 18, fontWeight: 'bold'}}> 
                        <FormattedMessage id="biblioteca.subtitle.temporadas" defaultMessage="Output" />
                    </label>
                </div>                
                <Box 
                sx={{ border: '1px solid #ccc', marginLeft: 2, marginBottom: 2, padding: 2, height: '400px', 
                    overflowY: 'scroll', whiteSpace: 'pre-wrap'
                }}>
                {response}
                </Box>
            </Grid>
        </Grid>                

        <div style={{ marginTop:5, marginLeft:14, marginBottom: 15}}>
            <button className="ui lantik-color-button button" onClick={() => onClickGuardar()}>
                <FormattedMessage id="biblioteca.button.guardasr" defaultMessage="Enviar" />
            </button>
        </div>
        
    </div>
    </>
    )
  }
export default LabSolidusCloudantDevices;