import React, { useState, useContext}   from 'react';
import {useHistory} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import { blue } from '@mui/material/colors';
import {requestUpdateConfiguration, requestGetConfiguration} from '../../services/BeachesService';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import es from "date-fns/locale/es";
import {registerLocale} from "react-datepicker";
import { langContext } from '../../context/langContext';

const ConfBeaches = () => {

    const FIELDS = {
        ID: "id",
        INPUT_MENSAJE_CASTELLANO: "warningEs",
        INPUT_MENSAJE_EUSKERA: "warningEu",
        INPUT_FECHA_INICIO: "initialDate",
        INPUT_FECHA_FIN: "finalDate",
    };

    const MAX_LENGTH = {
        [FIELDS.INPUT_MENSAJE_CASTELLANO]: 200,
        [FIELDS.INPUT_MENSAJE_EUSKERA]: 200
    }

    const INFO_ICONS = {
        INFO_CIRCLE: "info-circle",
        EXCLAMATION_TRIANGLE: "exclamation-triangle",
        EXCLAMATION_CIRCLE: "exclamation-circle",
        CALENDAR: "calendar"
    }

    const getBeachConfigInitialState = () => {
        return {
            [FIELDS.ID]: 1, // Hardcode ID biblioteca
            [FIELDS.INPUT_MENSAJE_CASTELLANO]: "",
            [FIELDS.INPUT_MENSAJE_EUSKERA]: "",
            [FIELDS.INPUT_FECHA_INICIO]: "",
            [FIELDS.INPUT_FECHA_FIN]: "",
        };
    };

    const getBeachConfigValidationInitialState = () =>{
        return {
            [FIELDS.INPUT_MENSAJE_CASTELLANO]: true,
            [FIELDS.INPUT_MENSAJE_EUSKERA]: true,
            [FIELDS.INPUT_FECHA_INICIO]: true,
            [FIELDS.INPUT_FECHA_FIN]: true,
        };        
    }

    const getInfoIconsInitialState = () => {
        return {
            [INFO_ICONS.INFO_ICONS]: '',
            [INFO_ICONS.EXCLAMATION_TRIANGLE]: '',
            [INFO_ICONS.EXCLAMATION_CIRCLE]: '',
            [INFO_ICONS.CALENDAR]: ''
        };
    };


    const { establecerLenguaje } = useContext(langContext);
    const [infoIcons, setInfoIcons] = useState(getInfoIconsInitialState());
    const [dayNumberDifference, setDayNumberDifference] = useState(0);
    const [minRangeEndDate, setMinRangeEndDate] = useState("");
    const [maxRangeStartDate, setMaxRangeStartDate] = useState("");
    const [infoMsgTextColor, setInfoMsgTextColor] = useState("black");
    const [infoMessageId, setInfoMessageId] = useState("biblioteca.null");
    const [beachConfig, setBeachConfig] = useState(getBeachConfigInitialState());
    const [beachConfigValidation, setBeachConfigValidation] = useState(getBeachConfigValidationInitialState());
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    
    let history = useHistory();

    React.useEffect(() => {
        establecerLenguaje('es-ES');
        registerLocale("es", es);
        loadBeachConfig();
    },[])

    const loadBeachConfig = () => {
        requestGetConfiguration()
            .then(result => {
                console.log("getBeachConfig(): ");
                console.log(result);
                let beachConfigData = result.data[0];

                const formattedStartDate = moment(beachConfigData[FIELDS.INPUT_FECHA_INICIO]).format('YYYY-MM-DD');

                beachConfigData[FIELDS.INPUT_FECHA_INICIO] = formattedStartDate;
                setMinRangeEndDate(beachConfigData[FIELDS.INPUT_FECHA_INICIO]);
                
                if(beachConfigData[FIELDS.INPUT_FECHA_FIN] != null && beachConfigData[FIELDS.INPUT_FECHA_FIN] != ""){
                    const formattedEndDate = moment(beachConfigData[FIELDS.INPUT_FECHA_FIN]).format('YYYY-MM-DD');
                    beachConfigData[FIELDS.INPUT_FECHA_FIN] = formattedEndDate;
                    setMaxRangeStartDate(beachConfigData[FIELDS.INPUT_FECHA_FIN] );
                }
        
                setBeachConfig(beachConfigData);
                setSeasonMessage(beachConfigData);

            }).catch(error => {
                showErrorAlert(error, "Error al obtener la configuración de playas: ");                  
            });
    };



    const getInfoIcons = (icon) => {
        const newIcons = getInfoIconsInitialState();
        switch (icon) {
            case INFO_ICONS.INFO_CIRCLE:
                newIcons[INFO_ICONS.INFO_CIRCLE] = 'info circle icon blue';
                setInfoMsgTextColor("#2185d0");
                break;
            case INFO_ICONS.EXCLAMATION_TRIANGLE:
                newIcons[INFO_ICONS.EXCLAMATION_TRIANGLE] = 'exclamation triangle icon darkorange';
                setInfoMsgTextColor("darkorange");
                break;
            case INFO_ICONS.EXCLAMATION_CIRCLE:
                newIcons[INFO_ICONS.EXCLAMATION_CIRCLE] = 'exclamation circle icon red';
                setInfoMsgTextColor("#db2828");
                break;
            case INFO_ICONS.CALENDAR:
                newIcons[INFO_ICONS.CALENDAR] = 'calendar alternate icon violet';
                setInfoMsgTextColor("#6435c9");
                break;                
            default:
                break;
        }
        return newIcons;
    };  

    const onClickGuardar = () => {
        setBeachConfigValidation(getBeachConfigValidationInitialState());
    
        let isRequiredFieldsValidate = true;
    
        if (beachConfig[FIELDS.INPUT_MENSAJE_CASTELLANO] === "") {
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_CASTELLANO]: false }));
            isRequiredFieldsValidate = false;
        }
        if(beachConfig[FIELDS.INPUT_MENSAJE_EUSKERA] === ""){
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_EUSKERA]: false }));
            isRequiredFieldsValidate = false;
        }
        if(beachConfig[FIELDS.INPUT_FECHA_INICIO] === ""){
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_INICIO]: false }));
            isRequiredFieldsValidate = false;
        }
        if(beachConfig[FIELDS.INPUT_FECHA_FIN] === ""){
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_FIN]: false }));
            isRequiredFieldsValidate = false;
        }

        if (isRequiredFieldsValidate) {

            // let formattedStartDate = new Date(beachConfig[FIELDS.INPUT_FECHA_INICIO]).toISOString().replace("Z", "+00:00");
            // beachConfig[FIELDS.INPUT_FECHA_INICIO] = formattedStartDate;

            // if(beachConfig[FIELDS.INPUT_FECHA_FIN] != ""){
            //     let formattedEndDate = new Date(beachConfig[FIELDS.INPUT_FECHA_FIN]).toISOString().replace("Z", "+00:00");
            //     beachConfig[FIELDS.INPUT_FECHA_FIN] = formattedEndDate;
            // }
            
            requestUpdateConfiguration(beachConfig)
                .then(result =>{
                    setSeasonMessage(beachConfig);
                    showSuccessAlert("Se ha actualizado la configuración de playas correctamente");                 
                })
                .catch(error => {
                    showErrorAlert(error, "Error al actualizar la configuración de playas: ");                  
                });
        }
            

    }

    const showSuccessAlert = (successMsg) => {
        setSuccessMessage(successMsg);
        setShowSuccessMessage(true);
        // Timer para ocultar el mensaje tras 5 segundos
        setTimeout (() => {
            setShowSuccessMessage(false);
        }, 5000); 
    }

    const showErrorAlert = (error, errorMsg) => {
        console.log(error);
        if(error.response.status == 0){
            errorMsg += error.code + " " + error.message;
        }else{
            errorMsg +=  error.response.status + " " + error.response.statusText;
        }
        setErrorMessage(errorMsg);
        setShowErrorMessage(true); 
    }


    const handleBeachConfigInputChange = (event) => {
        const { name, value} = event.target;
        setBeachConfigInputValue(name, value)
        setBeachConfigValidationValue(name, value);
    };

    const setBeachConfigInputValue = (name, value) => {
        if(name == FIELDS.INPUT_MENSAJE_CASTELLANO || name == FIELDS.INPUT_MENSAJE_EUSKERA ){
            if (value.length <= MAX_LENGTH[name]) {
                setBeachConfig({...beachConfig, [name]: value});
            }
        }else{
            setBeachConfig({...beachConfig, [name]: value});
        }
    }

    const setBeachConfigValidationValue = (name, value) =>{
        if(name == FIELDS.INPUT_MENSAJE_CASTELLANO && value !== "") {
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_CASTELLANO]: true }));
        }
        if(name == FIELDS.INPUT_MENSAJE_EUSKERA && value !== ""){
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_MENSAJE_EUSKERA]: true }));
        }
        if(name == FIELDS.INPUT_FECHA_INICIO && value !== ""){
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_INICIO]: true }));
            setMinRangeEndDate(value);
        }
        if(name == FIELDS.INPUT_FECHA_FIN && value !== ""){
            setBeachConfigValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_FIN]: true }));
            setMaxRangeStartDate(value);
        }        
    }

    const setSeasonMessage = (beachConfigData) => {
        console.log("setSeasonMessage()");
        console.log(beachConfigData);
        let momentCurrentDate = moment().startOf('day');
        let momentStartDate = moment(beachConfigData[FIELDS.INPUT_FECHA_INICIO]).startOf('day');
        let momentEndDate = moment(beachConfigData[FIELDS.INPUT_FECHA_FIN]).startOf('day');

        if(momentCurrentDate.isBefore(momentStartDate)){
            //  actual < inicio *** false 
            let dayNumberDifference = momentStartDate.diff(momentCurrentDate, 'days');
            if(dayNumberDifference > 1){
                setInfoMessageId("biblioteca.infoMsg.inicioPlanificadoDiasRestantes");
            }else {
                setInfoMessageId("biblioteca.infoMsg.inicioPlanificadoDiaRestante");
            }            
            setDayNumberDifference(dayNumberDifference);
            setInfoIcons(getInfoIcons(INFO_ICONS.CALENDAR));
        } else if(momentCurrentDate.isAfter(momentStartDate)){
            if(momentEndDate != null){
                if(momentCurrentDate.isSame(momentEndDate)){
                    //  actual > inicio & actual = fin *** true
                    setInfoMessageId("biblioteca.infoMsg.finalizaHoy");
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
                } else if(momentCurrentDate.isBefore(momentEndDate)){
                    //  actual > inicio & actual < fin *** true
                    let dayNumberDifference = momentEndDate.diff(momentCurrentDate, 'days');
                    if(dayNumberDifference > 1){
                        setInfoMessageId("biblioteca.infoMsg.diasRestantes");
                    }else{
                        setInfoMessageId("biblioteca.infoMsg.diaRestante");
                    }                    
                    setDayNumberDifference(dayNumberDifference);
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));                       
                } else if(momentCurrentDate.isAfter(momentEndDate)){
                    //  actual > fin *** false
                    setInfoMessageId("biblioteca.infoMsg.finalizado");
                    setInfoIcons(getInfoIcons(INFO_ICONS.EXCLAMATION_CIRCLE));
                }
            } else{
                //  actual > inicio & fin = null *** true
                setInfoMessageId("biblioteca.infoMsg.indefinida");
                setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
            }
        } else if(momentCurrentDate.isSame(momentStartDate)){
            if(momentEndDate != null){
                if(momentCurrentDate.isSame(momentEndDate)){
                    //  actual = inicio & actual = fin *** true
                    setInfoMessageId("biblioteca.infoMsg.finalizaHoy");
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
                }else if(momentCurrentDate.isBefore(momentEndDate)){
                    //  actual = inicio & actual < fin *** true
                    let dayNumberDifference = momentEndDate.diff(momentCurrentDate, 'days');
                    setDayNumberDifference(dayNumberDifference);
                    setInfoMessageId("biblioteca.infoMsg.diasRestantes");
                    setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));                    
                }
            } else{
                //  actual = inicio & fin = null *** true 
                setInfoMessageId("biblioteca.infoMsg.indefinida");
                setInfoIcons(getInfoIcons(INFO_ICONS.INFO_CIRCLE));
            }
        }
    }

    function cancel(){
        history.goBack()
    }

    function handleSelect(){
        history.push("/beaches");
    }

    return (
        <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="playas.tituloMinus" defaultMessage="Playas"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" ><FormattedMessage id="playas.playasMayus" defaultMessage="Configurar playas"/></i>
        </div>

        <div className='mainDiv' style={{paddingTop: 15, paddingRight: 15}}>

            {/* Mensaje alerta error */}
            <Collapse in={showErrorMessage}>
                <Alert 
                    style={{marginLeft: 10, marginRight: 10,  marginBottom: 15, display: 'flex', alignItems: 'center'}} 
                    onClose={() => {setShowErrorMessage(false)}} 
                    severity="error"
                >{errorMessage}
                </Alert>
            </Collapse>

            {/* Mensaje alerta exito */}
            <Collapse in={showSuccessMessage}>
                <Alert 
                    style={{marginLeft: 10, marginRight: 10, marginBottom: 15, display: 'flex', alignItems: 'center'}} 
                    onClose={() => {setShowSuccessMessage(false)}} 
                    severity="success"
                >{successMessage}
                </Alert>
            </Collapse>

            {/* Titulo */}
            <label className="title" >
                <FormattedMessage id="playas.configuracionPlayas" defaultMessage="Configuración de playas" />
            </label>


            {/* Subtitulo - Temporada */}
            <div className='input-column-container' style={{paddingTop: 25}}>
                <label className="" style={{color: '#4C4C4C', fontSize: 18, fontWeight: 'bold'}}> 
                    <FormattedMessage id="playas.temporada" defaultMessage="Temporada" />
                </label>
                
                <span className="" style={{color: infoMsgTextColor}}>
                    <i className={infoIcons[INFO_ICONS.INFO_CIRCLE]}></i>
                    <i className={infoIcons[INFO_ICONS.EXCLAMATION_TRIANGLE]}></i>
                    <i className={infoIcons[INFO_ICONS.EXCLAMATION_CIRCLE]}></i>
                    <i className={infoIcons[INFO_ICONS.CALENDAR]}></i>
                    <FormattedMessage
                        id={infoMessageId}
                        defaultMessage=""
                        values={{ dayNumberDifference: dayNumberDifference}}
                            />
                </span>
            </div>

            {/* Sección - Temporada */}
            <div className='ui form input-group-container'>
                {/* Fecha inicio */}
                <div className='input-group'>
                    <label className='label baseline required' htmlFor={FIELDS.INPUT_FECHA_INICIO}>
                        <i className="flag icon green" style={{color: blue}}/>
                        <FormattedMessage 
                            id="biblioteca.label.fechaInicio" 
                            defaultMessage="Fecha inicio:"/>
                    </label>
                    <input 
                        type="date" 
                        id={FIELDS.INPUT_FECHA_INICIO}
                        name={FIELDS.INPUT_FECHA_INICIO}
                        value={beachConfig[FIELDS.INPUT_FECHA_INICIO]}
                        className={(beachConfigValidation[FIELDS.INPUT_FECHA_INICIO])?'':'required-field'}
                        onChange={handleBeachConfigInputChange}
                        style={{width:150}}
                        max={maxRangeStartDate}
                    />                                
                </div>
                {/* Fecha fin */}
                <div className='input-group'>
                    <label className='label baseline required' htmlFor={FIELDS.INPUT_FECHA_FIN}>
                        <i className="flag checkered icon" style={{color: blue}}/>
                        <FormattedMessage 
                            id="biblioteca.label.fechaFin" 
                            defaultMessage="Fecha fin:"/>
                    </label>
                    <input 
                        type="date" 
                        id={FIELDS.INPUT_FECHA_FIN}
                        name={FIELDS.INPUT_FECHA_FIN}
                        value={beachConfig[FIELDS.INPUT_FECHA_FIN]}
                        className={(beachConfigValidation[FIELDS.INPUT_FECHA_FIN])?'':'required-field'}
                        onChange={handleBeachConfigInputChange}
                        style={{width:150}}
                        min={minRangeEndDate}
                    />                        
                </div>                    
            </div> 

            {/* Subtitulo - Mensaje */}
            <label className="sub-title title-flx"> 
                <FormattedMessage id="biblioteca.subtitle.mensaje" defaultMessage="Mensaje" />
            </label>

            {/* Sección - Mensajes español y euskera */}
            <div className='ui form input-column-container'>
                {/* Español */}
                <div className='input-column'>
                    <label htmlFor='titulo' className='label required'>
                        <img 
                            src={require('../../images/es-flag.png')} 
                            alt='mySvgImage' 
                            width={20}
                            style={{marginRight: 5, marginLeft: 3, marginBottom: 4}}
                        />
                        <FormattedMessage 
                            id="biblioteca.label.spanish" 
                            defaultMessage="Español:" />
                    </label>
                    <textarea 
                        id={FIELDS.INPUT_MENSAJE_CASTELLANO}
                        name={FIELDS.INPUT_MENSAJE_CASTELLANO}
                        value={beachConfig[FIELDS.INPUT_MENSAJE_CASTELLANO]}
                        className={(beachConfigValidation[FIELDS.INPUT_MENSAJE_CASTELLANO])?'':'required-field'}
                        onChange={handleBeachConfigInputChange} 
                        style={{resize: 'none'}}
                        rows="4" 
                        cols="50" 
                    />
                    <span className={(beachConfig[FIELDS.INPUT_MENSAJE_CASTELLANO].length < MAX_LENGTH[FIELDS.INPUT_MENSAJE_CASTELLANO]) ? 'input-length-counter' : 'input-length-counter-red'}>
                        {beachConfig[FIELDS.INPUT_MENSAJE_CASTELLANO].length}/{MAX_LENGTH[FIELDS.INPUT_MENSAJE_CASTELLANO]}
                    </span>
                </div>
                {/* Euskera */}
                <div className='input-column'>
                    <label htmlFor='descripcion' className='label required'>
                        <img 
                            src={require('../../images/eu-flag.png')} 
                            alt='mySvgImage' 
                            width={20}
                            style={{marginRight: 5, marginLeft: 3, marginBottom: 4}}
                        />
                        <FormattedMessage 
                            id="biblioteca.label.basque" 
                            defaultMessage="Euskera:" />                        
                    </label>
                    <textarea 
                        id={FIELDS.INPUT_MENSAJE_EUSKERA}
                        name={FIELDS.INPUT_MENSAJE_EUSKERA}
                        value={beachConfig[FIELDS.INPUT_MENSAJE_EUSKERA]}
                        className={(beachConfigValidation[FIELDS.INPUT_MENSAJE_EUSKERA])?'':'required-field'}
                        onChange={handleBeachConfigInputChange} 
                        style={{resize: 'none'}}
                        rows="4" 
                        cols="50" 
                    />
                    <span className={(beachConfig[FIELDS.INPUT_MENSAJE_EUSKERA].length < MAX_LENGTH[FIELDS.INPUT_MENSAJE_EUSKERA]) ? 'input-length-counter' : 'input-length-counter-red'}>
                        {beachConfig[FIELDS.INPUT_MENSAJE_EUSKERA].length}/{MAX_LENGTH[FIELDS.INPUT_MENSAJE_EUSKERA]}
                    </span>
                </div>
            </div>   

            {/* Botón Guardar */}
            <div style={{ marginTop:5, marginLeft:14, marginBottom: 15}}>
                <button className="ui lantik-color-button button" onClick={() => onClickGuardar()}>
                    <FormattedMessage id="biblioteca.button.guardar" defaultMessage="Guardar" />
                </button>
                <button className="ui noBorder button" onClick={() => cancel()}>
                    <FormattedMessage id="playas.volver" defaultMessage="Volver" />
                </button>
            </div>
          </div>
        </>
    )
}

export default ConfBeaches