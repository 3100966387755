import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import * as Constantes from '../../helpers/constantes';
import PersonAdd from '@mui/icons-material/PersonAdd'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TransferListRoles from './TransferListRoles';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';


const GroupForm = () => {

    const datos = {
        "id": "1",
        "nombre": "Grupo 1",
        "roles": "offline_access, admin, default-roles-backoffice-devm, uma_authorization"
    }

    const onClickGuardar= (event) => {

        var descripcion;
        if(action == Constantes.ACTION_CREAR){
            descripcion = "El grupo se ha creado correctamente";
        }else if(action == Constantes.ACTION_MODIFICAR){
            descripcion = "El grupo se ha actualizado correctamente";
        }

        Swal.fire({
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#3085d6",
            icon: "success",
            title: "Completado",
            text: descripcion,
          })
    }

    const {action} = useParams();

    let history = useHistory();

    function handleSelect(){
        history.push("/groups");
    }

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    return (
    <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="menu.grupos" defaultMessage="Grupos"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" >{
                (action == Constantes.ACTION_CONSULTAR ? "Consultar grupo" :
                action == Constantes.ACTION_CREAR ? "Crear grupo" :
                action == Constantes.ACTION_MODIFICAR ? "Modificar grupo" : "")
            }</i>
        </div>

        <div className='mainDiv' style={{paddingTop: 15, paddingRight: 15}}>
            <label className="title" style={{ display: 'flex'}} > 
            {action === Constantes.ACTION_CONSULTAR ? (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="grupos.consultar" defaultMessage="Crear usuario" />
                    <ManageSearchIcon/>
                </div>
            ) : action === Constantes.ACTION_MODIFICAR ? (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="grupos.modificar" defaultMessage="Crear usuario" />
                    <EditNoteIcon/>
                </div>
            ) : (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="grupos.crear" defaultMessage="Crear usuario" />
                    <PlaylistAddIcon/>
                </div>
            )}
            </label>

            <div className='ui form' style={{marginTop: 15, marginLeft: 15, display: 'grid'}}>
                
                <div style={{gridColumn: '1', gridRow: '1', display: 'flex', alignItems: 'center', marginTop: 5, marginRight: 25}}>
                    <label htmlFor='id'>Nombre del grupo:</label>
                    <input 
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 180}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value={(action == Constantes.ACTION_CREAR ? "" : datos.nombre)}
                        placeholder=""/>
                </div>

                <div style={{gridColumn: '1', gridRow: '2',justifySelf: 'start' , marginTop: 30, marginRight: 25}}>
                    <TransferListRoles/>
                </div>
            </div>

            {action === Constantes.ACTION_CONSULTAR ? (
                <div style={{ marginTop:40, marginLeft:14, marginBottom:  20}}>
                    <button className="ui lantik-color-button button" onClick={() => handleSelect()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Volver" />
                    </button>
                </div>
            ) : (
                <div style={{ marginTop:40, marginLeft:14, marginBottom:  20}}>
                    <button className="ui lantik-color-button button" onClick={() => onClickGuardar()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Guardar" />
                    </button>
                    <button className="ui noBorder button" onClick={() => handleSelect()}>
                        <FormattedMessage id="notificaciones.limpiar" defaultMessage="Cancelar" />
                    </button>
                </div>
            )} 
        </div>
    </>
    );
};

export default GroupForm;