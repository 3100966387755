import React,{ useState} from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {newId, requestPostNew} from "../../services/NewsService";
import {requestGetCategories} from "../../services/CategoriesService";
import DatePicker,{registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";
import es from "date-fns/locale/es";
import SelectFile from "../media/SelectFile"
import {FormattedMessage} from "react-intl";
import {requestSendNotification} from '../../services/NotificationService'





const CreateNews = () => {

  const [text, setText] = useState("");
/*   const [createNew, setCreateNew] = useState(""); */

let selImage = "";
let selImageEu = "";
const fieldsMessage = 
<div >
<i className="big exclamation red circle icon"></i>
<p><FormattedMessage id="noticias.anadir.error.camposObligatorios" defaultMessage="Los campos marcados con asterisco son obligatorios. Por favor revise que esten completos los dos idiomas."/></p>
</div>

const dateMessage = 
<div >
<i className="big exclamation red circle icon"></i>
<p>  <FormattedMessage id="noticias.anadir.error.fechayhoraIncorrectas" defaultMessage="La fecha y hora seleccionada es incorrecta"/></p>
</div>

const statusMessagePublished =

<div >
<i className="big exclamation red circle icon"></i>
<p><FormattedMessage id="noticias.anadir.error.estadoaPublicado" defaultMessage="El estado ha pasado a PUBLICADO"/></p>
</div>  

const statusMessageSchedule =
<div >
<i className="big exclamation red circle icon"></i>
<p><FormattedMessage id="noticias.anadir.error.estadoaProgramado" defaultMessage=" El estado ha pasado a PROGRAMADO"/></p>
</div>

  const confirmationNotificationMessage =
    <div>
      <i className="big check green circle icon"></i>
      <p><FormattedMessage id="noticias.anadir.noticiaNotificada" defaultMessage="La noticia ha sido notificada" /></p>
    </div>

  const [status, setStatus] = useState(""); 
  
  const [publishingDate, setPublishingDate] = useState();
  const [expirationDate, setExpirationDate] = useState();

  const [titleEs, setTitleEs] = useState("");
  const [titleEu, setTitleEu] = useState("");

  const [bodyEs, setBodyEs] = useState("");
  const [bodyEu, setBodyEu] = useState("");
  const [news, setNews] = useState([]);



  
  const [hideEsComponent, setHideEsComponent] = useState(false);
  const [hideEuComponent, setHideEuComponent] = useState(true);

  const [hideErrorComponent, setHideErrorComponent] = useState(true);
  const [errorMsg, setErrorMsg] = useState();
  const [categories, setCategories] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [hideGaleryComponent, setGaleryComponent] = useState(true);
  const [hideEuGaleryComponent, setGaleryEuComponent] = useState(true);
  const [show, setShow] = useState(false);
  const [showEu, setShowEu] = useState(false);
  const [hideImageOptionsComponent, setHideEsImageOptionsComponent] = useState(true);
  const [hideEuImageOptionsComponent, setHideEuImageOptionsComponent] = useState(true);
  const [hideEsImageComponent, setHideEsImageComponent] = useState(true);
  const [hideEuImageComponent, setHideEuImageComponent] = useState(true);
  const [hideNotificationComponent, setHideNotificationComponent] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlEu, setImageUrlEu] = useState("");
  const [hideEsNoImageComponent, setHideEsNoImageComponent] = useState(false);
  const [hideEuNoImageComponent, setHideEuNoImageComponent] = useState(true);
  const [statusNotification, setStatusNotification] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hideConfirmationMessageComponent, setHideConfirmationMessageComponent] = useState(true);

 

  React.useEffect(() => {

     
    
      requestGetCategories(setCategories);
      registerLocale("es", es);
      setImageUrl(selImage);
      setImageUrlEu(selImageEu);

},[selImage, selImageEu])



  function validateFields () {

    let valError = false;
    if( 
        titleEs === "" || titleEs === undefined ||
        titleEu === "" || titleEu === undefined ||
        bodyEs  === "" || bodyEs  === undefined ||
        bodyEu  === "" || bodyEu  === undefined ||
        status  === "" || status  === undefined
      
      )
    {

        valError = true;

    }

    if(valError){
      setErrorMsg(fieldsMessage);
      setHideErrorComponent(false);
      
  }else {
    createNew();
  } 
} 

  var idNew;

  function createNew () {


      window["urlImage"] = ""
   
    const body = {

      titleEs: titleEs,
      titleEu: titleEu,
      bodyEs: bodyEs,
      bodyEu: bodyEu,
      categories: arr,
      imageUrl: imageUrl,
      imageUrlEu: imageUrlEu,
      status: status,
      publishingDate: publishingDate,
      expirationDate: expirationDate

    } 

  
    requestPostNew(body)
    showConfirmationMessage()
    if(status === "PUBLICADO"){
      setStatusNotification(true);
    }

    

  }

  function statusMessage(action){
    
    if(action === "PUBLICADO"){
      setErrorMsg(statusMessagePublished);
      setHideErrorComponent(false);

    }else if(action === "PROGRAMADO"){
      setErrorMsg(statusMessageSchedule);
      setHideErrorComponent(false);
    }

    setStatus(action)
  }

  function showConfirmationMessage(){
    setShowConfirmation(true);
    setHideConfirmationMessageComponent(false);

  }

  function hideConfirmationMessage(){
    setShowConfirmation(false);
    setHideConfirmationMessageComponent(true);
    if(status === "PUBLICADO"){
      setStatusNotification(true);
    }
  }

 

 
  function setLang(lang){
    if(lang === "ES"){
        setHideEsComponent(false)
        setHideEuComponent(true)
       
      if(imageUrl == ''){
        setHideEsNoImageComponent(false)
        setHideEuNoImageComponent(true)
        
        setHideEsImageComponent(true)
        setHideEuImageComponent(true)
      }else{
        setHideEsNoImageComponent(true)
        setHideEuNoImageComponent(true)
        
        setHideEsImageComponent(false)
        setHideEuImageComponent(true)
      }
        
        
       
    
    }else{
        setHideEsComponent(true)
        setHideEuComponent(false)

        if(imageUrlEu == ''){
          setHideEsNoImageComponent(true)
          setHideEuNoImageComponent(false)

          setHideEsImageComponent(true)
          setHideEuImageComponent(true)
        }else{
          setHideEsNoImageComponent(true)
          setHideEuNoImageComponent(true)

          setHideEsImageComponent(true)
          setHideEuImageComponent(false)
        } 
    }

}

function selectedImage(){
  selImage = window["urlImage"]
  setImageUrl(selImage)
  setHideEsImageComponent(false)
  setHideEsNoImageComponent(true)
  hideGallery()
}

function selectedImageEu(){
  selImageEu = window["urlImage"]
  setImageUrlEu(selImageEu)
  setHideEuImageComponent(false)
  setHideEuNoImageComponent(true)
  hideGalleryEu()
}


function hideGallery(){
  setShow(false);
  setGaleryComponent(true);
  setHideEsImageOptionsComponent(true)
}


function showGallery(){
  setShow(true)
  setGaleryComponent(false);
}

function hideGalleryEu(){
  setShowEu(false);
  setGaleryEuComponent(true);
  setHideEuImageOptionsComponent(true)
}


function showGalleryEu(){
  setShowEu(true)
  setGaleryEuComponent(false);
}
  

  
    let history = useHistory();

    function handleClick() {

      history.push("/news");

    }

    const BackDrop = ({show}) => 
    <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>

    const BackDropConfirmation = ({showConfirmation}) => 
          <div className="fullscreen-container" 
          style={{display:  showConfirmation ? "block" : "none"}}></div>
    
   const BackDropEu = ({show}) =>
<div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>


    const categorieNameEs =
       categories.map(cat => (
      {
        
      value: cat.id,
      label: cat.nameEs
      
    
        }

      ))

      const selectedCategoriesEs = (e) => {
        setSelectedValue(Array.isArray(e) ? e : []);
       
    
      }

      const categorieNameEu =
      categories.map(cat => (
     {
       
     value: cat.id,
     label: cat.nameEu
     
   
       }

     ))

     const selectedCategoriesEu = (e) => {
      setSelectedValue(Array.isArray(e) ? e : []);

  
    }
     
    
        const arr = selectedValue.map(item => {
          return {id: item.value, nameEs: item.label, id: item.value, nameEu: item.label}
        }) 

        function sendNotification(){

         
         const id = newId();

          if(statusNotification === true){
              const data = {
              
                id: id,
                title: titleEs,
                token: "",
                topic:process.env.REACT_APP_SPANISH_TOPIC_NOTIFICATION
                
              }
      
           requestSendNotification(data);
      
           const dataEu = { 
            
            id: id,
            title: titleEu,
            token: "",
            topic: process.env.REACT_APP_BASQUE_TOPIC_NOTIFICATION
            
          }  
      
           requestSendNotification(dataEu);
           
           setErrorMsg(confirmationNotificationMessage);
           setHideErrorComponent(false);
      
          }
        }
    
      
   
      

      function handlePushNavigation(){
          history.push("/news");
      }

      function changePublishingDate(date){
        let valError = false;
        if(date < Date.now()){
          valError = true;

        }if(valError){
          setErrorMsg(dateMessage);
          setHideErrorComponent(false);
          setPublishingDate().setDate(0)
        }
        setPublishingDate(date)

        let newExpirationDate = new Date(date);
        var d = newExpirationDate.getDate();
        newExpirationDate.setMonth(newExpirationDate.getMonth() + + 6);

        if (newExpirationDate.getDate() !== d) {
            newExpirationDate.setDate(0);
        }   
        setExpirationDate(newExpirationDate)
    }

    //Text editor configuration
      ClassicEditor.defaultConfig = {
        toolbar: {
          items: [
            
         'link',
         '|',
            'bold',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo'
          ]
        },
        image: {
          toolbar: [
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative'
          ]
        },
        table: {
          contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        },
        language: 'es'
      };

     


  return (
    <>
      <div className="ui large breadcrumb" style={{paddingBottom:15}}>
        <i className="home red icon"></i>
        <a className="section" style={{color: "red"}} onClick={handlePushNavigation}><FormattedMessage id="noticias.anadir.noticias" defaultMessage="Noticias"/></a>
        <i className="right large angle icon divider"></i>
        <i className="section" ><FormattedMessage id="noticias.anadir.anadirNoticia" defaultMessage="Añadir noticia"/></i>
      </div>
    
      <div className="mainDiv"> 
        <div className="div-inLine">
          <label className="title" ><FormattedMessage id="noticias.anadir.anadirNoticia" defaultMessage="Añadir noticia"/></label>  
          <div className="red-box">
            <select className="ui red-Select select" style={{backGroundColor:"red"}} name="selectType" onChange={(e) => setLang(e.target.value)}>
              <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                {(message) => <option value="ES">{message}</option>}
              </FormattedMessage>
                <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                {(message) => <option value="EU">{message}</option>}
              </FormattedMessage>
            </select>
          </div>
        </div>
        <div className="ui form">
          {/* <button className='ui black button' style={{ float: "right", height: 40, marginRight: 50, marginTop:15 }} disabled={statusNotification === false} onClick={() => sendNotification()}><FormattedMessage id="noticias.editar.notificar" defaultMessage="Notificar" /></button> */}
          <div className="ui grid" style={{height:100}}>
            <div className= "six wide column" style={{paddingLeft:17}}>
              <label><b><FormattedMessage id="noticias.anadir.estado" defaultMessage="Estado"/> *</b></label> 
                <select value={status} className="ui select " name="selectState" onChange={(e) => statusMessage(e.target.value)}>
                  <FormattedMessage id="noticias.anadir.estado.seleccione" defaultMessage="Seleccione...">
                    {(message) => <option value="">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="noticias.anadir.estado.publicado" defaultMessage="Publicado">
                    {(message) => <option value="PUBLICADO">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage id="noticias.anadir.estado.programado" defaultMessage="Programado">
                    {(message) => <option value="PROGRAMADO" style={{color:"black"}}>{message}</option>}
                  </FormattedMessage>
                    <FormattedMessage id="noticias.anadir.estado.noPublicado" defaultMessage="No Publicado">
                    {(message) => <option value="BORRADOR" style={{color:"black"}}>{message}</option>}
                  </FormattedMessage>
                </select>
            </div>
            <div className="four wide column" >
              <div className={`${hideEsNoImageComponent   ? "hide-Component" : "imageSpan"}`} >
                <span>
                    {/* <button className='noImage' onClick={() => setHideEsImageOptionsComponent(!hideImageOptionsComponent)}><FormattedMessage id="noticias.anadir.anadirImagen" defaultMessage="Añadir imagen"/></button> */}
                    <button className='noImage' onClick={() => showGallery(false)}><FormattedMessage id="noticias.anadir.anadirImagen" defaultMessage="Añadir imagen"/></button>
                </span>
               
              </div>
              <div className={`${hideEsImageComponent ? "hide-Component" : "imageSpan"}`}>
                <span>
                  <button className="pencil-icon" style={{marginLeft:50}} onClick={() => showGallery(false)}><i className="ui white pencil icon"></i></button>
                  <img className="formImage" style={{marginLeft:50, width:200}} src={imageUrl} alt="Imagen" />
                </span> 
              </div>

              <div className={`${hideEuNoImageComponent ? "hide-Component" : "imageSpan"}`} >
                <span>
                    {/* <button className='noImage' onClick={() => setHideEuImageOptionsComponent(!hideEuImageOptionsComponent)}><FormattedMessage id="noticias.anadir.anadirImagen" defaultMessage="Añadir imagen"/></button> */}
                    <button className='noImage' onClick={() =>showGalleryEu(false)}><FormattedMessage id="noticias.anadir.anadirImagen" defaultMessage="Añadir imagen"/></button>
                </span>
              </div>
              <div className={`${hideEuImageComponent ? "hide-Component" : "imageSpan"}`}>
                <span>
                  <button className="pencil-icon" style={{marginLeft:50}} onClick={() => showGalleryEu(false)}><i className="ui white pencil icon"></i></button>
                  <img className="formImage" style={{marginLeft:50, width:200}} src={imageUrlEu} alt="Imagen" />
                </span>  
              </div>
            </div>
          {/*   <div className="four wide column" style={{float:"left"}} >
              <div className={`${hideImageOptionsComponent ? "hide-Component" : "optionsComponent"}`}  >
                    <span>
                      <ul className="optionsList">
                        <li onClick={() => showGallery(false)}><FormattedMessage id="noticias.anadir.seleccionarImagen" defaultMessage="Seleccionar Imagen"/></li>
                        <li><FormattedMessage id="noticias.anadir.imagenPredefinida" defaultMessage="Imagen predefinida"/></li>
                      </ul>
                    </span>
              </div>
              <div className={`${hideEuImageOptionsComponent ? "hide-Component" : "optionsComponent"}`}  >
                <span>
                  <ul className="optionsList">
                    <li onClick={() => showGalleryEu(false)}><FormattedMessage id="noticias.anadir.seleccionarImagen" defaultMessage="Seleccionar Imagen"/></li>
                    <li><FormattedMessage id="noticias.anadir.imagenPredefinida" defaultMessage="Imagen predefinida"/></li>
                  </ul>
                </span>
              </div>
            </div> */}
           
          </div>
          <div className="ui grid">   
            <div className= "three wide column" style={{paddingLeft:30}}>
              <label><FormattedMessage id="noticias.anadir.fechPublica" defaultMessage="Fecha de publicación"/> </label>                
              <DatePicker 
                  locale="es"
                  dateFormat="dd/MM/yyyy HH:mm:ss" 
                  showTimeSelect
                  minDate={new Date()}
                  selected={publishingDate} 
                  onChange={(date) => changePublishingDate(date)} />
            </div>
            <div className="three wide column">
              <label><FormattedMessage id="noticias.anadir.fechaExpira" defaultMessage="Fecha de expiración"/></label>
              <DatePicker 
                  locale="es"
                  dateFormat="dd/MM/yyyy HH:mm:ss" 
                  showTimeSelect
                  minDate={publishingDate}
                  selected={expirationDate} 
                  onChange={(date) => setExpirationDate(date)}
                />
              <div style={{marginLeft:265}}>
                
              </div>
             
            </div>   
          </div>
          <div className="ui grid">
            <div className="fifteen wide column" style={{paddingLeft:30}}>
              <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}><FormattedMessage id="noticias.anadir.tituloEs" defaultMessage="Título Castellano *"/></label>
              <input required type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`} 
                  onChange={(e) => setTitleEs(e.target.value)}
                  value={titleEs}>  
              </input>
              <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}><FormattedMessage id="noticias.anadir.tituloEus" defaultMessage="Título Euskera *"/></label>
              <input className={`${hideEuComponent ? "hide-EuComponent" : ""}`} 
                  onChange={(e) => setTitleEu(e.target.value)}
                  value={titleEu}>
              </input>
            </div>
          </div>
      {/*     <div className="ui grid">
            <div className="fifteen wide column" style={{paddingLeft:30}}>
              <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                <label><FormattedMessage id="noticias.anadir.categoriaEs" defaultMessage="Categoría Castellano"/> </label>
                <Select placeholder="agregar categoría ..."  isMulti options={categorieNameEs} onChange={selectedCategoriesEs}></Select> 
              </div>
            </div>
            <div className="fifteen wide column" style={{paddingLeft:30}}>
              <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                <label><FormattedMessage id="noticias.anadir.categoriaEus" defaultMessage="Categoría Euskera"/> </label>
                <Select placeholder="agregar categoría ..."  isMulti options={categorieNameEu} onChange={selectedCategoriesEu}></Select> 
              </div>
            </div> 
          </div>   */}        
          <div className='ui grid'>
            <div className='fifteen wide column'>
              <div className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                <div  style={{paddingLeft:17, paddingBottom:15}}>
                  <label><FormattedMessage id="noticias.anadir.contenidoEs" defaultMessage="Contenido Es *"/> </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setBodyEs(data)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='ui grid'>
            <div className='fifteen wide column'>
              <div className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                <div style={{ paddingLeft: 17, paddingBottom: 15 }}>
                  <label><FormattedMessage id="noticias.anadir.contenidoEus" defaultMessage="Contenido Eu *" /></label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setBodyEu(data)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{paddingLeft:15, paddingTop:18, paddingBottom:5}}>
            <button className="ui lantik-color-button button" onClick={() => validateFields()}><FormattedMessage id="noticias.anadir.guardar" defaultMessage="Guardar"/></button>
            <button className="ui noBorder button" onClick={() => handleClick()}><FormattedMessage id="noticias.anadir.cancelar" defaultMessage="Volver"/></button>  
          </div> 
          <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
            <div><span>{errorMsg}</span></div>
            <div><button className="ui lantik-color-button button" style={{marginTop:5}} onClick={() => setHideErrorComponent(true)}><FormattedMessage id="noticias.anadir.aceptar" defaultMessage="Aceptar"/></button></div>
          </div> 
          {/* <p><FormattedMessage id="noticias.anadir.notificarAviso" defaultMessage="* Para poder notificar la noticia debe de estar guardada y en estado publicado"/></p> */}
        </div>
      </div>
      <BackDrop show={show}></BackDrop> 
      <div className={`${hideGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
          <SelectFile></SelectFile>
          <div>
              <button className="ui lantik-color-button button" onClick={() => selectedImage()}><FormattedMessage id="noticias.anadir.guardar" defaultMessage="Guardar"/></button>
              <button className="ui noBorder button" onClick={() => hideGallery(true)}><FormattedMessage id="noticias.anadir.cancelar" defaultMessage="Volver"/></button>
              
          </div>   
      </div>
      <div className={`${hideEuGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
          <SelectFile></SelectFile>
          <div>
              <button className="ui lantik-color-button button" onClick={() => selectedImageEu()}><FormattedMessage id="noticias.anadir.guardar" defaultMessage="Guardar"/></button>
              <button className="ui noBorder button" onClick={() => hideGalleryEu(true)}><FormattedMessage id="noticias.anadir.cancelar" defaultMessage="Volver"/></button>
          </div>  
            
      </div>

      <BackDropConfirmation showConfirmation={showConfirmation}></BackDropConfirmation> 
      <div className={`${hideConfirmationMessageComponent ? "hide-ConfirmationMessageComponent" : "ConfirmationMessageComponent"}`}>
        <div><span className='msgHead'><i className="ui headIcon check icon"></i></span></div>
        <div className='msgBody'>
          <span>
            <label><FormattedMessage id="noticias.anadir.noticiaGuardada" defaultMessage="La noticia ha sido guardada correctamente"/></label><br></br>
          </span>
        </div>
        <div className='msgBottom'>
          <span>               
            <button className="ui noBorder button" onClick={() => handleClick()}><FormattedMessage id="noticias.anadir.listado" defaultMessage="Ir al listado"/></button>
            <button className="ui lantik-color-button button" onClick={() => hideConfirmationMessage()}><FormattedMessage  id="noticias.anadir.continuarEditando" defaultMessage="Continuar editando"/></button>
          </span>
        </div>
      </div>
    </>
  )
}

export default CreateNews;
