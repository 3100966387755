import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import * as Constantes from '../../helpers/constantes';
import PersonAdd from '@mui/icons-material/PersonAdd'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'


import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';



const AppTextsForm = () => {

    const datos = {
        "id": "1",
        "nombre": "Grupo 1",
        "roles": "offline_access, admin, default-roles-backoffice-devm, uma_authorization"
    }

    const onClickGuardar= (event) => {

        var descripcion;
        if(action == Constantes.ACTION_CREAR){
            descripcion = "El texto se ha creado correctamente";
        }else if(action == Constantes.ACTION_MODIFICAR){
            descripcion = "El texto se ha actualizado correctamente";
        }

        Swal.fire({
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#3085d6",
            icon: "success",
            title: "Completado",
            text: descripcion,
          })
    }

    const {action} = useParams();

    let history = useHistory();

    function handleSelect(){
        history.push("/appTexts");
    }

    const onClickGenerarPassword= (event) => {
        
    }

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };


    return (
    <>
         {/* breadcrumb - migas de pan */}
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}> <FormattedMessage id="menu.appTexts" defaultMessage="Textos App"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" >{
                (action == Constantes.ACTION_CONSULTAR ? "Consultar texto" :
                action == Constantes.ACTION_CREAR ? "Crear texto" :
                action == Constantes.ACTION_MODIFICAR ? "Modificar texto" : "")
            }</i>
        </div>

        {/* Contenedor principal */}
        <div className='mainDiv' style={{paddingTop: 15, paddingRight: 15}}>

            {/* Titulo filtro dispositivos */}
            <label className="title" style={{ display: 'flex'}} > 
            {action === Constantes.ACTION_CONSULTAR ? (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="appTexts.consultar" defaultMessage="Consultar texto" />
                    <SearchIcon/>
                </div>
            ) : action === Constantes.ACTION_MODIFICAR ? (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="appTexts.modificar" defaultMessage="Modificar texto" />
                    <EditIcon/>
                </div>
            ) : (
                <div style={{ display: 'flex', gap: '0.3rem'}}>
                    <FormattedMessage id="appTexts.crear" defaultMessage="Crear texto" />
                    <AddIcon/>
                </div>
            )}
            </label>

            <div className='ui form' style={{marginTop: 15, marginLeft: 15, display: 'flex', flexWrap: 'wrap'}}>
                
               {/* Filtro ID */}
               <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                    <label htmlFor='id'>ID:</label>
                    <input 
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 250}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value=""
                        placeholder=""/>
                </div>

                {/* Filtro SO */}
                <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                    <label htmlFor='servicio' style={{marginRight:10}} >Idioma:</label>
                    <select id='servicio' className="ui select" style={{width:185}} 
                        value="" defaultValue="" name="selectTown">
                        <option key="0"  value="Seleccione un SO">Seleccione un idioma</option>
                        <option key="1" value="Android">Español</option>
                        <option key="2" value="IOS">Euskera</option>
                    </select>
                </div> 

                {/* Filtro Analytic Label */}
                <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                    <label htmlFor='id'>Texto:</label>
                    <input 
                        className=''
                        id='id'
                        style={{marginLeft: 10, width: 500}}
                        type="text" 
                        maxlength="3" 
                        size="3"
                        value=""
                        placeholder=""/>
                </div> 



            </div>



            {/* Botones */}   
            {action === Constantes.ACTION_CONSULTAR ? (
                <div style={{ marginTop:40, marginLeft:14, marginBottom:  20}}>
                    <button className="ui lantik-color-button button" onClick={() => handleSelect()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Volver" />
                    </button>
                </div>
            ) : (
                <div style={{ marginTop:40, marginLeft:14, marginBottom:  20}}>
                    <button className="ui lantik-color-button button" onClick={() => onClickGuardar()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Guardar" />
                    </button>
                    <button className="ui noBorder button" onClick={() => handleSelect()}>
                        <FormattedMessage id="notificaciones.limpiar" defaultMessage="Cancelar" />
                    </button>
                </div>
            )} 


        </div>
    </>
    );
};

export default AppTextsForm;