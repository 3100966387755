import React, { useState}   from 'react';
import {NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import Moment from 'moment';
import {requestGetSwimmingPoolsSearch,requestDeletePool,getTotalNumberOfFiles, requestGetTown} from '../../services/PoolsService';
import {langContext} from '../../context/langContext';
import UserService from '../../services/UserService';
import axios from "axios";


const SwimmingPools = () => {

const [hideMessageComponent, setHideMessageComponent] = useState(true);
const [pools, setPools] = useState([]);
const [totalPost, setTotalPost] = useState();
const [page, setPage] = useState(0);  
const [dinamicStatus, setDinamicStatus] = useState();
const [size] = useState(8);
const [show, setShow] = useState(false);
const [search, setSearch] = useState("");
const [delId,setDelId] = useState("");
const [town,setTown] = useState();
const [townsBBDD, setTownsBBDD] = useState([]);
const idioma = React.useContext(langContext);
const [lastLanguage, setlastLanguage] = useState(idioma.localePorDefecto);
const [hideInSeassonComponent, setHideInSeassonComponent] = useState(true);
const [hideOutSeassonComponent, setHideOutSeassonComponent] = useState(false);
const [warningTextEs, setWarningTextEs] = useState("");
const [warningTextEu, setWarningTextEu] = useState("");
const [checkTemporada, setCheckTemporada] = useState(false);


React.useEffect(() => {

  requestGetSwimmingPoolsSearch(setPools,setDinamicStatus, page, size,"","");
  requestGetTown(setTownsBBDD);
  getConfiguration();
  getTotalNumberOfFiles(setTotalPost, "","");

},[])


const getConfiguration = async () => {
  const headers = {
      "Content-Security-Policy": "frame-ancestors none",
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + UserService.getToken()
  }

  const url = process.env.REACT_APP_URL_FULL_POOLS + `/configuration`; 
  const data = await (await axios.get(url, { 'headers':  headers })).data;
  const dataConfiguration = await data;
  
  setWarningTextEs(dataConfiguration[0].avisoEs);
  setWarningTextEu(dataConfiguration[0].avisoEu);
  setCheckTemporada(dataConfiguration[0].state);
}


function nextList() {
  var pageNumber = pageNumbers.length + 1;
  if(pageNumber === Number(page)+1){
    goToPage(Number(page));
  }else{
    goToPage(Number(page)+1);
  }
}

function prevList() {
  goToPage(Number(page)-1);
}

function goToPage(nPage) {

  if(nPage < 0){
    nPage = 0
  }

  requestGetSwimmingPoolsSearch(setPools,setDinamicStatus, nPage, size,"", town);
  getTotalNumberOfFiles(setTotalPost,"", town);
  setPage(nPage);
 
}


function deletePool(id){
  requestDeletePool(id,setPools,setDinamicStatus, page, size, "", town);
  setShow(false)
  setHideMessageComponent(true);
}

function showDeleteMessage(id){
  setShow(true)
  setHideMessageComponent(false);
  setDelId(id);
}

function hideDeleteMessage(){
  setShow(false)
  setHideMessageComponent(true);
}

const handleChangeBuscaTitulo = e => {
  setSearch(e.target.value);
  filterTitle(e.target.value);
}

const filterTitle = (searchTerms) => {
  var searchResult = dinamicStatus.filter((e) => {
      if(e.poolNameEs.toString().toLowerCase().includes(searchTerms.toLowerCase())) {
        return e;
      }
  })
  setPools(searchResult);
  getTotalNumberOfFiles(setTotalPost, searchTerms,town);
}

const pageNumbers = [];
for (let i = 1; i <= Math.ceil(totalPost / size); i++) {
  pageNumbers.push(i);
}

const BackDrop = ({show}) => 
<div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>

function searchByTown(nPage,townFilter){
  if(nPage < 0)
    nPage = 0

  if(town !== townFilter)
    nPage = 0

  setPage(nPage);
  setPools(pools);
  setTown(townFilter);
  requestGetSwimmingPoolsSearch(setPools,setDinamicStatus, nPage, size,"", townFilter);
  getTotalNumberOfFiles(setTotalPost, search,townFilter);
}

let emptyTable;

if(pools.length > 0){
  emptyTable = 
      pools.map(data => (
        <tbody >   
          <tr>
            <td className='center aligned row' key={data.id}>{lastLanguage === 'eus-ES' ? data.poolNameEu : data.poolNameEs}</td>
            <td className='center aligned row'>{data.cityName}</td> 
            <td className='center aligned row'>{data.state ? <FormattedMessage id="piscinas.abierta" defaultMessage="Abierta"/> : <FormattedMessage id="piscinas.cerrada" defaultMessage="Cerrada"/>}</td> 
            <td className='center aligned row'>{data.maxOccupation == null || data.actualOccupation == null ? <FormattedMessage id="piscinas.sinDatos" defaultMessage="Sin datos"/> : data.actualOccupation+"/"+data.maxOccupation }</td> 
            <td className='center aligned row'> <label>{Moment(data.updateDate).format("DD/MM/YYYY hh-mm")}</label></td> 
            <td> 
              <div className="ui small basic icon buttons">
                <NavLink to={`viewSwimmingPool/${data.id}`}>  
                  <button className="ui button" >
                    <i className="ui black eye icon"></i>
                  </button>
                </NavLink>
                <NavLink to={`/editSwimmingPool/${data.id}`}>  
                  <button className="ui button"><i className="ui black pencil alternate icon"></i></button>
                </NavLink>
                <button className="ui button" onClick={() => showDeleteMessage(data.id)}><i className="ui black trash alternate outline icon"></i></button>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      ));

  }else  if(null !== town && town !== ""){
    
    pageNumbers.splice(1);
    emptyTable = <tbody><tr><td colSpan="5" style={{textAlign: 'center', fontSize: '25px', padding: '20px'}}> <FormattedMessage id="piscinas.noExistenPiscinasMunicipio" defaultMessage={"No existen piscinas para el municipio de "+ town}  values={{town: town}}/></td></tr></tbody>;
  }else{
    
    pageNumbers.splice(1);
    emptyTable = <tbody><tr><td colSpan="5" style={{textAlign: 'center', fontSize: '25px', padding: '20px'}}> <FormattedMessage id="piscinas.noExistenPiscinas" defaultMessage="No existen piscinas"/></td></tr></tbody>;
  }

  function limpiaBusqueda(){
    setPage(0);
    setSearch("");
    setTown("");
    requestGetSwimmingPoolsSearch(setPools,setDinamicStatus, page, size,"","");
    getTotalNumberOfFiles(setTotalPost, "","");
  }

  function cambiolistaIdioma(){
    if(lastLanguage !== idioma.localePorDefecto || lastLanguage === ""){
      setlastLanguage(idioma.localePorDefecto);
    }
   
  }

  return (
    
    <>
      <div className='mainDiv'>
        <label className="title" style={{paddingTop: 10}}> <FormattedMessage id="piscinas" defaultMessage="Piscinas" onChange={cambiolistaIdioma()}/></label>  

        <div className="ui  icon input search" style={{paddingTop:20, marginRight:20}}>
          <select className="ui select" style={{width:230,marginRight:15}} value={town} defaultValue="" name="selectTown"  onChange={e => searchByTown(page,e.target.value)} >
            <FormattedMessage id="piscinas.selectMunicipio" defaultMessage="Selecciona un municipio">
                {(message) => <option disabled value="">{message}</option>}
            </FormattedMessage>
          
            {townsBBDD.map(data => (
                <option key={data.idTown} value={data.townNameEs}>{data.townNameEs}</option>
              ))
            }
            
            
          </select>
          <div className="ui icon input" style={{marginRight:15}}>
            <FormattedMessage id="piscinas.buscarPorTitulo" defaultMessage="Buscar por título">
              {placeholder=> <input value={search} type="text" placeholder={placeholder} onChange={handleChangeBuscaTitulo}/> }
            </FormattedMessage> 
            <i className="search link black icon"></i>
          </div>
          <button className="ui lantik-color-button button" onClick={() => limpiaBusqueda()}>
              <FormattedMessage id="piscinas.limpiarBusqueda" defaultMessage="Limpiar"/>
          </button>
        </div>
    
        <div style={{paddingLeft:10}}>
          <NavLink to={"create-pool"}>
          <button className="ui lantik-color-button button" style={{marginTop:30}}><FormattedMessage id="piscinas.anadirpiscina" defaultMessage="AÑADIR PISCINAS" /></button>
          </NavLink>
          
          <NavLink to={"conf-pools"}>
            <button className="ui lantik-color-button button">
              <FormattedMessage id="piscinas.configurarPiscina" defaultMessage="CONFIGURAR"/>
            </button>
          </NavLink>
          <div style={{paddingLeft: 5}} className={`${checkTemporada ? "hide-AppComponent" : "outSeasson-pools icon input"}`} >
            <i className="icon-alert yellow icon" style={{paddingTop: 35}}></i>
            <label style={{position: 'relative', top: -12}}><FormattedMessage id="piscinas.fueraTemporada" defaultMessage="Fuera de temporada"/></label>
          </div>
          <div style={{paddingLeft: 10}} className={`${!checkTemporada ? "hide-AppComponent" : "inSeasson-pools icon input"}`} >
            <i className="icon-check-green icon" style={{paddingTop: 20}}></i>
            <label style={{position: 'relative', top: -10}}><FormattedMessage id="piscinas.enTemporada" defaultMessage="En temporada" /></label>
          </div>
        </div>
        <div style={{paddingTop:10, marginLeft:10}}>
          <label>
            <b><FormattedMessage id="piscinas.avisoSecundario" defaultMessage="Aviso secundario" /></b>
            <br></br>
              {lastLanguage === 'eus-ES' ? warningTextEu : warningTextEs}
          </label>
        </div>
        <div className='paddingTableNews '>    
          <table className="ui  table">       
            <thead >
              <tr className='center aligned row'>
                <th><FormattedMessage id="piscinas.nombre" defaultMessage="Nombre" /></th>
                <th><FormattedMessage id="piscinas.municipio" defaultMessage="Municipio" /></th>
                <th><FormattedMessage id="piscinas.estado" defaultMessage="Estado" /></th> 
                <th><FormattedMessage id="piscinas.ocupaciónActual" defaultMessage="Ocupación actual" /></th>
                <th><FormattedMessage id="piscinas.fechaActualizacionDatos" defaultMessage="Fecha actualización datos" /></th>
                <th> </th>
                <th> </th>
              </tr>
            
         
            </thead>

            {emptyTable}
        
          </table>
    
          <div className="content" style={{textAlign:"center"}}>
            <div>
              <button className="button-icon" onClick={prevList}><i className="ui chevron left icon"></i></button>
              {pageNumbers.map(number => (
                page === (number-1) ? <button className="ui lantik-color-button button" style={{padding:'auto'}} key={{number}} onClick={() => goToPage(number-1)}>{number}</button> : <button className="button-icon" key={{number}} onClick={() => goToPage(number-1)}>{number}</button> 
              ))} 
              <button className="button-icon" onClick={nextList} ><i className="ui chevron right icon"></i></button>
            </div>
          </div>
          <BackDrop show={show}></BackDrop> 
          <div className={`${hideMessageComponent ? "hide-MessageComponent" : "MessageComponent"}`}>
            <div><span className='msgHead'><i className="ui headIcon trash alternate outline icon"></i></span></div>
            <div className='msgBody'>
              <span>
                <label><FormattedMessage id="piscinas.preguntaBorrado" defaultMessage="¿Seguro que quiere eliminar esta piscina?" /></label><br></br>
                <FormattedMessage id="piscinas.avisoBorrado" defaultMessage="Esta acción es irrversible, por lo que se perderán todos los datos." />
              </span>
            </div>
              <div className='msgBottom'>
                <span>               
                  <button className="ui noBorder button" onClick={() => hideDeleteMessage(true)}><FormattedMessage id="piscinas.cancelar" defaultMessage="Cancelar"/></button>
                  <button className="ui lantik-color-button button"  onClick={() => deletePool(delId)}><FormattedMessage id="piscinas.eliminar" defaultMessage="Eliminar" /></button>
                </span>
              </div>
      
          </div>
        </div>
      </div>

        
    </>
    
  ) 
     
} 
    
export default SwimmingPools;


