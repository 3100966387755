import UserService from './UserService';
import axios from "axios";

export const sendVideocallNotification = (videocall) => {
    console.log("sendVideocallNotification()");
    console.log(videocall);

    const url = process.env.REACT_APP_URL_FULL_VIDEOCALL;
  
    const request = axios.create({
      baseURL: url,
      headers: {
        "Content-Security-Policy": "frame-ancestors none",
        "Content-type": "application/json",
        "Authorization": "Bearer " + UserService.getToken()
      },
    });
  
    return request.post(url, videocall);
}

export const sendVideocallNotice = (videocall) => {
  console.log("sendVideocallNotice()");
  console.log(videocall);

  const url = process.env.REACT_APP_URL_FULL_VIDEOCALL + "/notice";

  const request = axios.create({
    baseURL: url,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    },
  });

  return request.post(url, videocall);
}

export const getCloudantDataPersonas = (videocall) => {
  console.log("getCloudantDataPersonas()");
  console.log(videocall);

  const url = process.env.REACT_APP_URL_FULL_VIDEOCALL + "/cloudant";

  const request = axios.create({
    baseURL: url,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    },
  });

  return request.post(url, videocall);
}

export const getSolidusCloudantDevicesList = () => {
  console.log("getSolidusCloudantDevicesList()");

  const url = process.env.REACT_APP_URL_FULL_VIDEOCALL;

  const request = axios.create({
    baseURL: url,
    headers: {
      "Content-Security-Policy": "frame-ancestors none",
      "Content-type": "application/json",
      "Authorization": "Bearer " + UserService.getToken()
    },
  });

  return request.get(url);
}