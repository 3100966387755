import React, {useState} from 'react'; 
import {useParams, useHistory} from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import {requestGetNewById} from '../../services/NewsService';
import es from "date-fns/locale/es"; 
import axios from 'axios';
import UserService from '../../services/UserService';
import "react-datepicker/dist/react-datepicker.css";
import SelectFile from "../media/SelectFile";
import SelectNews from "../featured/SelectNews";
import {FormattedMessage} from 'react-intl';


const EditFeature = () => {
    let selImage = "";
    let selNews = "";

    const [type, setType] = useState();
    const [titleEs, setTitleEs] = useState("");
    const [titleEu, setTitleEu] = useState("");
    const [linkUrlEs, setLinkUrlEs] = useState("");
    const [linkUrlEu, setLinkUrlEu] = useState("");
    const [news, setNews] = useState("");
    const [fun, setFun] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [active, setActive] = useState("");
    const [oriActive, setOriActive] = useState("");
    const [priority, setPriority] = useState("1");
    const [txtAndroid, setTxtAndroid] = useState("");
    const [txtIos, setTxtIos] = useState("");
    const [publishingDate, setPublishingDate] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [hideEsComponent, setHideEsComponent] = useState(false);
    const [hideEuComponent, setHideEuComponent] = useState(true);
    const [hideNoticiaComponent, setHideNoticiaComponent] = useState(true);
    const [hideOtroComponent, setHideOtroComponent] = useState(true);
    const [hideVideoComponent, setHideVideoComponent] = useState(true);
    const [hideFunComponent, setHideFunComponent] = useState(true);
    const [hideAppComponent, setHideAppComponent] = useState(false);
    const [hideErrorComponent, setHideErrorComponent] = useState(true);
    const [hideGaleryComponent, setGaleryComponent] = useState(true);
    const [hideNewsComponent, setNewsComponent] = useState(true);
    const [hideImageComponent, setHideImageComponent] = useState(false);
    const [show, setShow] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
   

    const {id} = useParams();

    let history = useHistory();

    React.useEffect(() => {
        
        registerLocale("es", es);
        getData();
    },[id])  

    function validateValues(){

        let valError = false;
        let valErrorLink = false;
        let valErrorDataExpired = false;
        if( type === "" || type === undefined ||
            titleEs === "" || titleEs === undefined ||
            titleEu === "" || titleEu === undefined ||
            active === "" || titleEu === undefined ||
            priority === "" || priority === undefined){

            valError = true;

        }else{            
            if(type === "NOTICIA"){
                if(news === "" || news === undefined) valError = true;
            }else if(type === "OTRO" || type === "VIDEO"){
                if(!isValidHttpUrl(linkUrlEs) || !isValidHttpUrl(linkUrlEs)) valErrorLink = true;           
            }else if(type === "FUN_APP"){
                if(fun === "" || fun === undefined) valError = true;
            }else if(type === "APP"){
                if(txtAndroid === "" || txtAndroid === undefined ||
                txtIos === "" || txtIos === undefined) valError = true;
            }
            if(active === "PUBLICADO" && expirationDate < new Date()){
                valErrorDataExpired = true;
            }
        }
       
        
        if(valError){
            setErrorMsg(<FormattedMessage id="destacados.editar.error.camposVacios" defaultMessage="Por favor, rellene los campos vacios."/>)
            setHideErrorComponent(false)
        }else if(valErrorLink){
            setErrorMsg(<FormattedMessage id="destacados.editar.error.errorLink" defaultMessage="El campo Link tiene un valor erroneo."/>)
            setHideErrorComponent(false)
        }else if(valErrorDataExpired){
            setErrorMsg(<FormattedMessage id="destacados.editar.error.dataErrorExpired" defaultMessage="No se puede publicar un destacado con fecha EXPIRADA."/>)
            setHideErrorComponent(false)
        }else{
            updateFeatured();
        }
    }

    function isValidHttpUrl(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ 
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
        '(\\#[-a-z\\d_]*)?$','i');
      return !!pattern.test(str);
    }

    function updateFeatured() {

        window["urlImage"] = "";
        window["urlImageMini"] = "";
        window["selNews"] = "";

        const data = {
            type: type,
            titleEs: titleEs,
            titleEu: titleEu,
            linkUrlEs: linkUrlEs,
            linkUrlEu: linkUrlEu,
            idFun: fun,
            idNoticia: news.id,
            txtAndroid: txtAndroid,
            txtIos: txtIos,
            imageUrl: imageUrl,
            active: active,
            priority: priority,
            publishingDate: new Date(publishingDate),
            expirationDate: new Date(expirationDate)
        }

        const headers = {
            //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
            "Content-Type": "application/json",
            "Authorization": "Bearer " + UserService.getToken()
        }

        const html = axios.create({baseURL: process.env.REACT_APP_URL_FULL_FEATURES});

        html.put(process.env.REACT_APP_URL_FULL_FEATURES+ "/" + id, data, {'headers': headers})
            .then(response => history.push("/featured"));
        
      }

      function getNumberPublished(active){
        const apiUrl = process.env.REACT_APP_URL_FULL_FEATURES;

        const authAxios = axios.create ({
            baseURL: apiUrl,
            headers: {
                //Fix auth problem
               "Content-Security-Policy": "frame-ancestors none",
                //
                "Content-Type": "application/json",
                Authorization: `Bearer ${UserService.getToken()}`
            }
        })

        const data = {
            active: active,
            publishingDate: publishingDate,
            expirationDate: expirationDate
        }

        authAxios.post(process.env.REACT_APP_URL_FULL_FEATURES + "/numFiles", data)
        .then(response => validateCalendar(response.data, active))
        .catch(error => console.log(error));

    }

    function validateCalendar(res, sActive){
        if( res > 2 ){
            if(sActive === "PUBLICADO") setErrorMsg(<FormattedMessage id="destacados.anadir.error.dosPublicados" defaultMessage="Ya hay dos destacados en estado publicado."/>)
            if(sActive === "PROGRAMADO") setErrorMsg(<FormattedMessage id="destacados.anadir.error.dosProgramados" defaultMessage="Ya hay dos destacados programados para esas fechas."/>)
            setHideErrorComponent(false);
        }else setActive(sActive);
    }

    const getData = async () => {

      const headers = {
          //Fix auth problem
           "Content-Security-Policy": "frame-ancestors none",
            //
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + UserService.getToken()
      }
      const url = process.env.REACT_APP_URL_FULL_FEATURES + `/${id}`; 
      const data = await axios.get(url, { 'headers':  headers })   
      const dataFeature = await data.data;
      const selImage = window["urlImage"] 

      setNewType(dataFeature.type)
      setTitleEs(dataFeature.titleEs)
      setTitleEu(dataFeature.titleEu)
      setLinkUrlEs(dataFeature.linkUrlEs)
      setLinkUrlEu(dataFeature.linkUrlEu)
      requestGetNewById(setNews, dataFeature.idNoticia)
      setFun(dataFeature.idFun)
      setActive(dataFeature.active)
      setOriActive(dataFeature.active)
      setPriority(dataFeature.priority)
      setTxtAndroid(dataFeature.txtAndroid)
      setTxtIos(dataFeature.txtIos)
      setPublishingDate(new Date(dataFeature.publishingDate))
      setExpirationDate(new Date(dataFeature.expirationDate))

      if(selImage === undefined || selImage === "") setImageUrl(dataFeature.imageUrl)
      else setImageUrl(selImage)

      window["urlImage"] = ""
      window["urlImageMini"] = ""
      window["selNews"] = ""
    }
    function cancel(){
        window["urlImage"] = ""
        window["urlImageMini"] = ""
        window["selNews"] = ""

        history.goBack()
    }

    function setLang(lang){
        if(lang === "ES"){
            setHideEsComponent(false)
            setHideEuComponent(true)
        }else{
            setHideEsComponent(true)
            setHideEuComponent(false)
        }
    }

    function setNewType(type){
        setType(type)
        if(type === "NOTICIA"){
            setHideNoticiaComponent(false)
            setHideOtroComponent(true)
            setHideAppComponent(true)
            setHideVideoComponent(true)
            setHideFunComponent(true)
            setHideImageComponent(false)
        }else if(type === "OTRO"){
            setHideNoticiaComponent(true)
            setHideOtroComponent(false)
            setHideAppComponent(true)
            setHideVideoComponent(true)
            setHideFunComponent(true)
            setHideImageComponent(false)
        }else if(type === "VIDEO"){
            setHideNoticiaComponent(true)
            setHideOtroComponent(true)
            setHideAppComponent(true)
            setHideVideoComponent(false)
            setHideFunComponent(true)
            setHideImageComponent(true)
        }else if(type === "FUN_APP"){
            setHideNoticiaComponent(true)
            setHideOtroComponent(true)
            setHideAppComponent(true)
            setHideVideoComponent(true)
            setHideFunComponent(false)
            setHideImageComponent(false)
        }else{
            setHideNoticiaComponent(true)
            setHideOtroComponent(true)
            setHideAppComponent(false)
            setHideVideoComponent(true)
            setHideFunComponent(true)
            setHideImageComponent(false)
        }
    }

    function changePublishingDate(date){
        setPublishingDate(date)

        let newExpirationDate = new Date(date);
        var d = newExpirationDate.getDate();
        newExpirationDate.setMonth(newExpirationDate.getMonth() + + 1);
        if (newExpirationDate.getDate() !== d) {
            newExpirationDate.setDate(0);
        }   
        setExpirationDate(newExpirationDate)
    }
    function selectedImage(){
        selImage = window["urlImage"]
        setImageUrl(selImage)
        hideGallery()
    }
    function showGallery(){
        setShow(true)
        setGaleryComponent(false);
    }
    function hideGallery(){
        setShow(false)
        setGaleryComponent(true);
    }
    function selectedNews(){
        selNews = window["selNews"]
        requestGetNewById(setNews, selNews);
        hideNews()
    }
    function showNews(){
        setShow(true)
        setNewsComponent(false);
    }
    function hideNews(){
        setShow(false);
        setNewsComponent(true);
    } 
    function handleSelect(){
        history.push("/featured");
    }
    function changeActive(sActive){
        if(sActive !== oriActive){
            if(sActive === "PUBLICADO") getNumberPublished("PUBLICADO");
            else if(sActive === "PROGRAMADO") getNumberPublished("PROGRAMADO");
            else setActive(sActive);
        }
    }
    const BackDrop = ({show}) => 
    <div className="fullscreen-container" style={{display:  show ? "block" : "none"}}></div>
    return(
        <>
        <div className="ui large breadcrumb" style={{paddingBottom:15}}>
            <i className="home red icon"></i>
            <a className="section" style={{color: "red"}} onClick={handleSelect}><FormattedMessage id="destacados.destacados" defaultMessage="Destacados"/></a>
            <i className="right large angle icon divider"></i>
            <i className="section" ><FormattedMessage id="destacados.editar.editarDestacado" defaultMessage="Editar destacado"/></i>
        </div>
      <div className="mainDiv">
        <div className="div-inLine">
            <label className="title">
                <FormattedMessage id="destacados.editar.editarDestacado" defaultMessage="EDITAR DESTACADO"/>
            </label>
            <div className="red-box">
                <select className="red-Select" name="selectType" onChange={event => setLang(event.target.value)}>
                <FormattedMessage id="castellano" defaultMessage="CASTELLANO">
                    {(message) => <option value="ES">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="euskera" defaultMessage="EUSKERA">
                    {(message) => <option value="EU">{message}</option>}
                </FormattedMessage>
                </select>
            </div> 
        </div>
        <div className="posLeft2">
        <div className="ui form">
            <table>
            <tbody>
            <tr><td colSpan="2">
            <label><FormattedMessage id="destacados.editar.tipo" defaultMessage="Tipo"/> *</label>
                <select value={type} className="ui select" name="selectType" onChange={event => setNewType(event.target.value)}>
                    <FormattedMessage id="destacados.editar.tipo.seleccione" defaultMessage="Seleccione...">
                        {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.tipo.video" defaultMessage="Video (Enlaces externos)">
                        {(message) => <option value="VIDEO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.tipo.otro" defaultMessage="Otro (Enlaces externos)">
                        {(message) => <option value="OTRO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.tipo.funciApp" defaultMessage="Funcionalidad de la App">
                        {(message) => <option value="FUN_APP">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.tipo.appDipu" defaultMessage="App de la Diputación">
                        {(message) => <option value="APP">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.tipo.noticia" defaultMessage="Noticia">
                        {(message) => <option value="NOTICIA">{message}</option>}
                    </FormattedMessage>
                </select>
            </td></tr>
            <tr><td colSpan="2">
            <span className={`${hideAppComponent ? "hide-AppComponent" : ""}`}>
                <label ><FormattedMessage id="destacados.editar.ios" defaultMessage="Ios"/> *</label>
                <input type="text" 
                        onChange={event => setTxtIos(event.target.value)}
                        value={txtIos} name="inpIos"></input>
                <label ><FormattedMessage id="destacados.editar.android" defaultMessage="Android"/> *</label>
                <input type="text" 
                        onChange={event => setTxtAndroid(event.target.value)}
                        value={txtAndroid} name="inpAndoid"></input>
                </span>
                <span className={`${hideFunComponent ? "hide-FunComponent" : ""}`}>
                    <label><FormattedMessage id="destacados.editar.tipo.funcionalidad" defaultMessage="Funcionalidad"/></label>
                    <select 
                        value={fun}
                        onChange={event => setFun(event.target.value)}
                        name="selectApp">
                        <FormattedMessage id="destacados.editar.tipo.funciApp.seleccione" defaultMessage="Seleccione...">
                            {(message) => <option value="">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.agendaCultural" defaultMessage="Agenda Cultural">
                            {(message) => <option value="agendaFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.servicios" defaultMessage="Servicios">
                            {(message) => <option value="servicesFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.playas" defaultMessage="Playas">
                            {(message) => <option value="beachesFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.planesRutas" defaultMessage="Planes y Rutas">
                            {(message) => <option value="planesFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.piscinas" defaultMessage="Piscinas">
                            {(message) => <option value="piscinasFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.noticias" defaultMessage="Noticias">
                            {(message) => <option value="noticiasFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.ultimoBoletin" defaultMessage="Último boletin">
                            {(message) => <option value="boletinUltimoFragment">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id="destacados.editar.tipo.funciApp.aTodosBoletines" defaultMessage="A todos los boletines">
                            {(message) => <option value="boletinTodosFragment">{message}</option>}
                        </FormattedMessage>
                         <FormattedMessage id="destacados.editar.tipo.funciApp.bibliotecaForal" defaultMessage="Biblioteca foral">
                            {(message) => <option value="bibliotecaForalFragment">{message}</option>}
                        </FormattedMessage>
                         <FormattedMessage id="destacados.editar.tipo.funciApp.videollamada" defaultMessage="Videollamada">
                            {(message) => <option value="videollamadaFragment">{message}</option>}
                        </FormattedMessage>
                    </select>
                </span>
                <span className={`${hideNoticiaComponent ? "hide-NoticiaComponent" : ""}`}>
                <label><FormattedMessage id="destacados.editar.tipo.noticia" defaultMessage="Noticia"/> *</label>
                    <div>
                    <input type="text" value={news.titleEs} readOnly style={{width: "60%"}}></input>
                    <button className="ui redBorder button" onClick={() => showNews(true)}>
                    <FormattedMessage id="destacados.editar.tipo.seleccionarNoticia" defaultMessage="Seleccionar Noticia"/>
                    </button>
                    </div>
                </span>
                <span className={`${hideOtroComponent ? "hide-OtroComponent" : ""}`}>
                    <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                        <FormattedMessage id="destacados.editar.enlaceExternoCastellano" defaultMessage="Enlace externo Castellano"/> *
                    </label>
                    <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                        onChange={event => setLinkUrlEs(event.target.value)}
                        value={linkUrlEs} name="inpOtro"></input>
                    <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                        <FormattedMessage id="destacados.editar.enlaceExternoEuskera" defaultMessage="Enlace externo Euskera"/> *
                    </label>
                    <input type="text"  className={`${hideEuComponent ? "hide-EuComponent" : ""}`}
                        onChange={event => setLinkUrlEu(event.target.value)}
                        value={linkUrlEu} name="inpOtro"></input>
                </span>
                <span className={`${hideVideoComponent ? "hide-VideoComponent" : ""}`}>
                    <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                        <FormattedMessage id="destacados.editar.videoCastellano" defaultMessage="Video Castellano"/> *
                    </label>
                    <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`}
                        onChange={event => setLinkUrlEs(event.target.value)}
                        value={linkUrlEs} name="inpVideo"></input>
                    <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                        <FormattedMessage id="destacados.editar.videoEuskera" defaultMessage="Video Euskera"/> *
                    </label>
                    <input type="text" className={`${hideEuComponent ? "hide-EuComponent" : ""}`}
                        onChange={event => setLinkUrlEu(event.target.value)}
                        value={linkUrlEu} name="inpVideo"></input>
                </span>
            </td></tr>
            <tr><td colSpan="2" >
            <label className={`${hideEsComponent ? "hide-EsComponent" : ""}`}>
                    <FormattedMessage id="destacados.editar.tituloEs" defaultMessage="Titulo Castellano"/> *
                </label>
                <input type="text" className={`${hideEsComponent ? "hide-EsComponent" : ""}`} 
                    onChange={event => setTitleEs(event.target.value)}
                    value={titleEs}>                
                </input>
                <label className={`${hideEuComponent ? "hide-EuComponent" : ""}`}>
                    <FormattedMessage id="destacados.editar.tituloEus" defaultMessage="Titulo Euskera"/> *
                </label>
                <input className={`${hideEuComponent ? "hide-EuComponent" : ""}`} 
                    onChange={event => setTitleEu(event.target.value)}
                    value={titleEu}>
                </input>
            </td></tr>
            <tr><td>
            <label><FormattedMessage id="destacados.editar.estado" defaultMessage="Estado"/> *</label>
                <select value={active} className="ui select" name="selectState" onChange={event => changeActive(event.target.value)}>
                    <FormattedMessage id="destacados.editar.estado.seleccione" defaultMessage="Seleccione...">
                        {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.estado.publicado" defaultMessage="Publicado">
                        {(message) => <option value="PUBLICADO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.estado.programado" defaultMessage="Programado">
                        {(message) => <option value="PROGRAMADO">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="destacados.editar.estado.borrador" defaultMessage="Borrador">
                        {(message) => <option value="BORRADOR">{message}</option>}
                    </FormattedMessage>
                </select>
            </td><td>
                <label><FormattedMessage id="destacados.editar.orden" defaultMessage="Orden"/> *</label>
                <select value={priority} className="ui select" name="selectOrder" onChange={event => setPriority(event.target.value)}>
                    <FormattedMessage id="destacados.editar.orden.seleccione" defaultMessage="Seleccione...">
                        {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <option value="1" selected>1</option>
                    <option value="2" >2</option>
                </select>
            </td></tr>
            <tr><td>
                <label><FormattedMessage id="destacados.editar.fechPublica" defaultMessage="Fecha de publicación"/></label>
                <DatePicker 
                    locale="es"
                    dateFormat="dd/MM/yyyy HH:mm:ss" 
                    showTimeSelect
                    minDate={new Date()}
                    selected={publishingDate} 
                    onChange={(date) => changePublishingDate(date)} />
            </td><td>
                <label><FormattedMessage id="destacados.editar.fechaExpira" defaultMessage="Fecha de expiración"/></label>
                <DatePicker 
                    locale="es"
                    dateFormat="dd/MM/yyyy HH:mm:ss" 
                    showTimeSelect
                    minDate={publishingDate}
                    selected={expirationDate} 
                    onChange={(date) => setExpirationDate(date)} />
            </td></tr>
            </tbody>
            </table>
        </div>
        </div>
        <div className="posRight2">
            <div className={`${hideImageComponent ? "hide-Component" : ""}`}>
                <label style={{padding: "20px"}}>
                    <FormattedMessage id="destacados.anadir.imagen" defaultMessage="Imagen"/>
                </label>
                <div>
                    <span style={{paddingLeft:16, paddingTop:30}}>
                    <button className="pencil-icon" onClick={() => showGallery(false)}><i className="ui white pencil icon"></i></button>
                    <img style={{width:300, paddingTop:16}} src={imageUrl} alt="Imagen" />
                    </span>
                </div>
            </div>
        </div>
        <div>
        <button className="ui lantik-color-button button" onClick={() => validateValues()}>
            <FormattedMessage id="destacados.editar.guardar" defaultMessage="Guardar"/>
        </button>
        <button className="ui noBorder button" onClick={() => cancel()}>
            <FormattedMessage id="destacados.editar.cancelar" defaultMessage="Cancelar"/>
        </button>
        </div>
        <div className={`${hideErrorComponent ? "hide-ErrorComponent" : "ErrorComponent"}`}>
            <div><span>{errorMsg}</span></div>
            <div>
                <button className="ui lantik-color-button button" onClick={() => setHideErrorComponent(true)}>
                <FormattedMessage id="destacados.editar.aceptar" defaultMessage="Aceptar"/>
                </button>
            </div>
        </div>
        <BackDrop show={show}></BackDrop> 
        <div className={`${hideGaleryComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>       
            <SelectFile></SelectFile>
            <div>
                <button className="ui lantik-color-button button" onClick={() => selectedImage()}>
                    <FormattedMessage id="destacados.editar.guardar" defaultMessage="Guardar"/>
                </button>
                <button className="ui noBorder button" onClick={() => hideGallery(true)}>
                    <FormattedMessage id="destacados.editar.cancelar" defaultMessage="Cancelar"/>
                </button>
            </div>        
        </div>
        <div className={`${hideNewsComponent ? "hide-PopUpComponent" : "popUpComponent"}`}>
            <SelectNews></SelectNews>  
            <div>
                <button className="ui lantik-color-button button" onClick={() => selectedNews()}>
                    <FormattedMessage id="destacados.editar.guardar" defaultMessage="Guardar"/>
                </button>
                <button className="ui noBorder button" onClick={() => hideNews(true)}>
                    <FormattedMessage id="destacados.editar.cancelar" defaultMessage="Cancelar"/>
                </button>
            </div>        
        </div>
      </div>
      </>
    )
}

export default EditFeature;
