
import React, { useState}   from 'react';
import {FormattedMessage} from "react-intl";
import {langContext} from '../../context/langContext';
import {useHistory} from 'react-router-dom';
import {requestGetSectionBlock,requestPutSectionBlock} from "../../services/ConfigureHomeService";
import { Link } from 'react-router-dom';



const Intro = () => {

    const idioma = React.useContext(langContext);
    
    const [lastLanguage, setlastLanguage] = useState(idioma.localePorDefecto);

    const [sectionBlock, setSectionBlock] = useState([]);

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [hideConfirmationMessageComponent, setHideConfirmationMessageComponent] = useState(true);

    const BackDropConfirmation = ({showConfirmation}) => 
    <div className="fullscreen-container" 
    style={{display:  showConfirmation ? "block" : "none"}}></div>

    React.useEffect(() => { 
        requestGetSectionBlock(setSectionBlock);
    },[])
    
    
    console.log(sectionBlock);

    function updateSectionBlock () {

        var sections = sectionBlock.slice();

        for(let i = 0; sections.length > i; i++){
            const body = {
                idSection : sections[i].idSection,
                name : sections[i].name,
                visible : sections[i].visible,
                block : sections[i].block
            } 
    
            requestPutSectionBlock(sections[i].idSection, body);
        }

        showConfirmationMessage();
        
    }

    function showConfirmationMessage(){
        setShowConfirmation(true);
        setHideConfirmationMessageComponent(false);
    
    }
    
    function hideConfirmationMessage(){
        setShowConfirmation(false);
        setHideConfirmationMessageComponent(true);
        history.push("/configureHome");
    
    }

    let history = useHistory();

    function handleClick() {

        history.push("/");
  
    } 

    function cambiolistaIdioma(){
        if(lastLanguage !== idioma.localePorDefecto || lastLanguage === ""){
          setlastLanguage(idioma.localePorDefecto);
        }
       
    }

    function onChangeCheckLvl1(idlvl1, event){
        var sections = sectionBlock.slice();
        let index = sections.findIndex(item => item.idSection === idlvl1);
        sections[index].visible = event.target.checked;
        setSectionBlock(sections);
    }

    function onChangeCheckLvl2(idlvl1, idlvl2, event){
        var sections = sectionBlock.slice();
        let indexSection = sections.findIndex(item => item.idSection === idlvl1);
        let indexBloque = sections[indexSection].block.findIndex(item => item.idBlock === idlvl2);
        sections[indexSection].block[indexBloque].visible = event.target.checked;
        setSectionBlock(sections);
    }

    function onChangeCheckLvl3(idlvl1, idlvl2, idlvl3, event){
        var sections = sectionBlock.slice();
        let indexSection = sections.findIndex(item => item.idSection === idlvl1);
        let indexBloque = sections[indexSection].block.findIndex(item => item.idBlock === idlvl2);
        let indexMenu = sections[indexSection].block[indexBloque].menu.findIndex(item => item.idMenu === idlvl3);
        sections[indexSection].block[indexBloque].menu[indexMenu].visible = event.target.checked;
        setSectionBlock(sections);
    }

    let sectionTable;

    if(sectionBlock.length > 0){
        sectionTable =  sectionBlock.map(data => (
            <div className='postion-section'>
                <input type='checkbox'
                    className="ui red fitted checkbox"
                    onChange={event => onChangeCheckLvl1(data.idSection, event)}
                    checked={data.visible}>
                </input>
                <label className='label-section'>
                    {data.idSection === 1 ?  <FormattedMessage id="configureHome.destacados" defaultMessage="Destacados"/> : 
                     data.idSection === 2 ?  <FormattedMessage id="configureHome.aldia" defaultMessage="Al día"/> :
                     data.idSection === 3 ?  <FormattedMessage id="configureHome.atencionCiudadana" defaultMessage="Atención ciudadana"/> :
                     data.idSection === 4 ?  <FormattedMessage id="configureHome.reservas" defaultMessage="Reservas"/> :
                     data.idSection === 5 ?  <FormattedMessage id="configureHome.viveBizkaia" defaultMessage="Vive Bizkaia"/> :
                     data.idSection === 6 ?  <FormattedMessage id="configureHome.nuestrasApps" defaultMessage="Nuestras apps"/> :
                     <FormattedMessage id="configureHome.seccionDesconocida" defaultMessage="Sección desconocida"/>}
                    
                </label>
                {data.block.length > 0 ?
                    data.block.map(data2 => (
                        <ul>
                            <li className='position-subsection' style={{listStyleType:'none'}}>
                                <input type='checkbox'
                                    className="ui red fitted checkbox"
                                    onChange={event => onChangeCheckLvl2(data.idSection, data2.idBlock, event)}
                                    checked={data2.visible}
                                    disabled={(data2.idBlock == 9) ? true : false}
                                >
                                </input>
                                <label className='position-subsection'>
                                    {data2.idBlock === 1 ? <FormattedMessage id="configureHome.alDia.noticias" defaultMessage="Noticias"/> : 
                                    data2.idBlock === 2 ? <FormattedMessage id="configureHome.alDia.boletin" defaultMessage="Boletín"/> :
                                    data2.idBlock === 3 ? <FormattedMessage id="configureHome.atencionCiudadana.atencionPresencial" defaultMessage="Atención Presencial"/> :
                                    data2.idBlock === 4 ? <FormattedMessage id="configureHome.atencionCiudadana.atencionVideollamada" defaultMessage="Atención videollamada"/> :
                                    data2.idBlock === 5 ? <FormattedMessage id="configureHome.atencionCiudadana.atencionTelefonica" defaultMessage="Atención telefónica"/>:
                                    data2.idBlock === 6 ? <FormattedMessage id="configureHome.atencionCiudadana.sedeElectronica" defaultMessage="Sede electrónica"/> :
                                    data2.idBlock === 7 ? <FormattedMessage id="configureHome.atencionCiudadana.oficinasAtencion" defaultMessage="Oficinas Atención"/> :
                                    data2.idBlock === 8 ? <FormattedMessage id="configureHome.atencionCiudadana.consultas" defaultMessage="Consultas"/> :
                                    data2.idBlock === 9 ? 
                                    <>
                                        <Link to="/library" className='configure-home-link'>
                                            <FormattedMessage id="configureHome.reservas.bibliotecaForal" defaultMessage="Biblioteca foral"/> 
                                             <i className="edit icon" />
                                        </Link>
                                    </>:
                                    data2.idBlock === 10 ? <FormattedMessage id="configureHome.reservas.gaztelugatxe" defaultMessage="Gaztelugatxe"/> :
                                    data2.idBlock === 11 ? <FormattedMessage id="configureHome.viveBizkaia.playas" defaultMessage="Playas"/> :
                                    data2.idBlock === 12 ? <FormattedMessage id="configureHome.viveBizkaia.piscinas" defaultMessage="Piscinas"/> :
                                    data2.idBlock === 13 ? <FormattedMessage id="configureHome.viveBizkaia.planesYRutas" defaultMessage="Planes y rutas"/>:
                                    data2.idBlock === 14 ? <FormattedMessage id="configureHome.viveBizkaia.agenda" defaultMessage="Agenda"/> :
                                    data2.idBlock === 15 ? <FormattedMessage id="configureHome.reservas.banoAsistido" defaultMessage="Baño asistido"/> :
                                    <FormattedMessage id="configureHome.bloqueDesconocido" defaultMessage="Bloque desconocido"/>}
                                </label>
                                {data2.menu.length > 0 ?
                                    data2.menu.map(data3 => (
                                        <ul>
                                            <li className='position-subsection' style={{listStyleType:'none'}}>
                                                <input type='checkbox'
                                                    className="ui red fitted checkbox"
                                                    onChange={event => onChangeCheckLvl3(data.idSection, data2.idBlock, data3.idMenu, event)}
                                                    checked={data3.visible}>
                                                </input>
                                                <label className='position-subsection'>
                                                    {data3.idMenu === 1 ? <FormattedMessage id="configureHome.alDia.boletin.ultimoBoletin" defaultMessage="Último boletín"/> : 
                                                    data3.idMenu === 2 ? <FormattedMessage id="configureHome.alDia.boletin.aTodosBoletines" defaultMessage="A todos los boletines"/> :
                                                    <FormattedMessage id="configureHome.menuDesconocido" defaultMessage="Menú desconocido"/>}
                                                </label>
                                            </li>
                                        </ul>
                                    ))
                                : "" }    
                            </li>
                        </ul>
                    )) 
                : "" }
            </div >
           ))
    }

    return (
        <div className="mainDiv">
            <div className="title">
                <label><b><FormattedMessage id="configureHome.seccionesHome" defaultMessage="Secciones home" onChange={cambiolistaIdioma()}/></b></label>
            </div>
          
            {sectionTable}

            <div className='postion-section'>
                <button className="ui lantik-color-button button" onClick={() => updateSectionBlock()}><FormattedMessage id="configureHome.guardar" defaultMessage="Guardar" /></button>
                <button className="ui noBorder button" onClick={() => handleClick()} ><FormattedMessage id="configureHome.cancelar" defaultMessage="Cancelar" /></button>
            </div>
            <BackDropConfirmation showConfirmation={showConfirmation}></BackDropConfirmation> 
            <div className={`${hideConfirmationMessageComponent ? "hide-ConfirmationMessageComponent" : "ConfirmationMessageComponent"}`}>
                <div><span className='msgHead'><i className="ui headIcon check icon"></i></span></div>
                <div className='msgBody'>
                    <span>
                        <label><FormattedMessage id="configureHome.seccionGuardada" defaultMessage="La secciones han sido actualizadas correctamente."/></label><br></br>
                    </span>
                </div>
                <div className='msgBottom' style={{padding:38, marginLeft:5}} >
                    <span>               
                        <button className="ui lantik-color-button button" onClick={() => hideConfirmationMessage()}><FormattedMessage  id="configureHome.aceptar" defaultMessage="Aceptar"/></button>
                    </span>
                </div>
            </div> 
        </div>
    );
};

export default Intro;