import React, {useMemo, useState} from 'react';
import { MaterialReactTable} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import data from './data';
import columns from './columns';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FormattedMessage } from 'react-intl';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Visibility from '@mui/icons-material/Visibility'

const NotificationLogs = () => {

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let csv = '';
        for (let i = 0; i < array.length; i++) {
          let row = '';
          for (let index in array[i]) {
            if (row !== '') row += ',';
            row += array[i][index];
          }
          csv += row + '\n';
        }
        return csv;
      };
    
      
      const downloadCSV = () => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      };


    const [selectedRowId, setSelectedRowId] = useState(null);
    const [disableButton, setDisabledButton] = useState(true);
    

    const handleRowClick = (row) => {
        // Deselecciona la fila anteriormente seleccionada si existe
        console.log("Selected row.id = " + row.id);
        if (selectedRowId === row.id) {
            setSelectedRowId(null);
            setDisabledButton(true);
        } else {
            setSelectedRowId(row.id); // Selecciona la nueva fila
            setDisabledButton(false);
        }
    };


    const [showLastConection, setShowLastConection] = useState(false);
    

    // Filtro check dispositivos ultima conexion
    const handleCheckLastConectionChange = (event) => {
        setShowLastConection((showLastConection) => !showLastConection);
    };
    

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    

    const onClickConsultar = (event) => {
        setOpen(true);
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        //border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const [tableData, setTableData] = useState("");
    const onClickFiltrar = (event) => {
        setTableData(data);
    }


    return (
        <>
            {/* Contenedor principal */}
            <div className='mainDiv' style={{paddingTop: 25, paddingRight: 15}}>

                {/* Titulo*/}
                <label className="title"> 
                    <FormattedMessage id="notificacionesRegistros.titulo.filtrar" defaultMessage="Filtrar notificaciones" />
                </label>

                <div className='ui form' style={{marginTop: 15, marginLeft: 15, display: 'flex', flexWrap: 'wrap'}}>

                {showLastConection ? (
                    <>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 20}}>
                            <label htmlFor='fechaDesde'>Fecha desde:</label>
                            <input 
                                id='fechaDesde'
                                style={{marginLeft: 10, width:150}}
                                type="date" 
                                value=""
                                placeholder=""/>
                        </div>
                        
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                            <label  htmlFor='fechaHasta'>Fecha hasta:</label>
                            <input 
                                id='fechaHasta'
                                style={{marginLeft: 10,  width:150}}
                                type="date" 
                                value=""
                                placeholder=""/>
                        </div>
                    </>
                ) : 
                    <>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 15}}>
                        <label htmlFor='fechaDesde'>Fecha:</label>
                        <input 
                            id='fechaDesde'
                            style={{marginLeft: 10, width:150}}
                            type="date" 
                            value=""
                            placeholder=""/>
                        </div>
                    </>
                }

                    <div  style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 30}}>
                        <label >
                            <input 
                            onChange={handleCheckLastConectionChange}
                            checked={showLastConection}
                            style={{marginRight: 5, marginTop: 3}}
                            type="checkbox" 
                            />
                            Rango de fechas
                        </label>
                    </div>
                    
                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                        <label htmlFor='id'>ID:</label>
                        <input 
                            className=''
                            id='id'
                            style={{marginLeft: 10, width: 300}}
                            type="text" 
                            maxlength="3" 
                            size="3"
                            value=""
                            placeholder=""/>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                        <label htmlFor='servicio' style={{marginRight:10}} >SO:</label>
                        <select id='servicio' className="ui select" style={{width:230}} 
                            value="" defaultValue="" name="selectTown">
                            <option key="0"  value="Seleccione un SO">Seleccione un SO</option>
                            <option key="1" value="Android">Android</option>
                            <option key="2" value="IOS">IOS</option>
                            <option key="3" value="Otro">Otros</option>
                        </select>
                    </div> 

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                        <label htmlFor='servicio' style={{marginRight:10}} >Topic:</label>
                        <select id='servicio' className="ui select" style={{width:230}} 
                            value="" defaultValue="" name="selectTown">
                            <option key="0"  value="Seleccione un Topic">Seleccione un Topic</option>
                            <option key="1" value="Otro">noticias_es</option>
                            <option key="2" value="Otro">noticias_eu</option>
                            <option key="3" value="Otro">planes_es</option>
                            <option key="4" value="Otro">planes_eu</option>
                            <option key="5" value="Otro">pl-1</option>
                            <option key="6" value="Otro">pl-3</option>
                            <option key="7" value="Otro">pl-4</option>
                            <option key="8" value="Otro">pl-9</option>
                            <option key="9" value="Otro">pl-12</option>
                            <option key="10" value="Otro">pl-30</option>
                        </select>
                    </div> 

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                        <label htmlFor='servicio' style={{marginRight:10}} >Estado:</label>
                        <select id='servicio' className="ui select" style={{width: 300}} 
                            value="" defaultValue="" name="selectTown">
                            <option key="0"  value="Seleccione un Topic">Seleccione un Estado</option>                            
                            <option data-tooltip-id="MESSAGE_ACCEPTED" key="1" value="Android">MESSAGE_ACCEPTED</option>
                            <ReactTooltip id="MESSAGE_ACCEPTED" place="rigth" content="El servidor de FCM recibió el mensaje y la solicitud es válida."/>
                            <option data-tooltip-id="MESSAGE_DELIVERED" key="2" value="IOS">MESSAGE_DELIVERED</option>
                            <ReactTooltip id="MESSAGE_DELIVERED" place="bottom" content="El SDK de FCM de la app recibió el mensaje en el dispositivo. De forma predeterminada, este campo no se propaga."/>
                            <option key="3" value="Otro">MISSING_REGISTRATIONS</option>
                            <option key="4" value="Otro">UNAUTHORIZED_REGISTRATION</option>
                            <option key="5" value="Otro">MESSAGE_RECEIVED_INTERNAL_ERROR</option>
                            <option key="6" value="Otro">MISMATCH_SENDER_ID</option>
                            <option key="7" value="Otro">QUOTA_EXCEEDED</option>
                            <option key="8" value="Otro">INVALID_REGISTRATION</option>
                            <option key="9" value="Otro">INVALID_PACKAGE_NAME</option>
                            <option key="10" value="Otro">INVALID_APNS_CREDENTIAL</option>
                            <option key="11" value="Otro">INVALID_PARAMETERS</option>
                            <option key="12" value="Otro">PAYLOAD_TOO_LARGE</option>
                            <option key="13" value="Otro">AUTHENTICATION_ERROR</option>
                            <option key="14" value="Otro">INVALID_TTL</option>
                        </select>
                    </div> 

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                        <label htmlFor='servicio' style={{marginRight:10}} >Tipo mensaje:</label>
                        <select id='servicio' className="ui select" style={{width:230}} 
                            value="" defaultValue="" name="selectTown">
                            <option key="0"  value="Seleccione un Topic">Tipo de mensaje:</option>
                            <option key="1" value="Otro">DATA_MESSAGE</option>
                            <option key="2" value="Otro">TOPIC</option>
                        </select>
                    </div> 

                    <div style={{display: 'flex', alignItems: 'center', marginTop: 15, marginRight: 25}}>
                        <label htmlFor='id'>Analytic label:</label>
                        <input 
                            className=''
                            id='id'
                            style={{marginLeft: 10, width: 230}}
                            type="text" 
                            maxlength="3" 
                            size="3"
                            value=""
                            placeholder=""/>
                    </div> 

                </div>

                <div style={{ marginTop:25, marginLeft:14, marginBottom: 35}}>
                    <button className="ui lantik-color-button button" onClick={() => onClickFiltrar()}>
                        <FormattedMessage id="notificaciones.buscar" defaultMessage="Filtrar" />
                    </button>
                    <button className="ui noBorder button">
                        <FormattedMessage id="notificaciones.limpiar" defaultMessage="Limpiar filtro" />
                    </button>
                </div>
                

                <label style={{marginLeft: 10, fontSize: "24px", fontWeight: "bold", color: '#4C4C4C'}}> 
                    <FormattedMessage id="notificacionesRegistros.titulo.listado" defaultMessage="Listado de notificaciones" />
                </label>

                <div style={{ marginTop:10, marginLeft:10, marginBottom: 15}}>
                <MaterialReactTable
                    columns={columns} 
                    data={tableData}
                    enableDensityToggle={false}
                    localization={MRT_Localization_ES}
                    // enableRowSelection={true}
                    // enableSelectAll={false}
                    enableMultiRowSelection={false}
                    positionToolbarAlertBanner='none'
                    getRowId={(row) => row.userId}
                    muiTableBodyRowProps={({ row }) => ({
                      onClick: () => handleRowClick(row),
                      selected: row.id === selectedRowId,
                      sx: {
                        cursor: 'pointer',
                      },
                    })}
                    state={{ selectedRowId }}                  
                    initialState={{
                         columnVisibility: { analytics_label: false },
                         pagination: { pageSize: 5, pageIndex: 0 },
                         density: 'compact',
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                          fontWeight: 'bold',
                          fontSize: '14px',
                          color: '#4C4C4C'
                        },
                      }}
                    muiTableBodyCellProps={({ cell }) => ({
                        onClick: (event) => {
                        console.info(event, cell.id);
                        var columna = cell.id.split("_")[1];
                        console.log("CELL VALUE: " + cell.getValue());
                        //setShowModal(true);
                        },
                    })}
                    renderTopToolbarCustomActions={({ table }) => {
                        return (
                          <div style={{ display: 'flex', gap: '0.5rem', justifyContent: 'flex-start', paddingTop: 5 }}>
                            
                            <Button
                                disabled={disableButton}
                                className="table-button-red"
                                size='small'
                                onClick={onClickConsultar}
                                startIcon={<Visibility />}
                                variant="contained"
                            >
                                Consultar
                            </Button>

                            <Button
                                className="table-button-red"
                                size='small'
                                onClick={downloadCSV}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Exportar listado
                            </Button>

                          </div>
                        );
                      }}

                />
                </div>
                
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >       
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
                            Detalle notificación
                        </Typography>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                                event_timestamp
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 2023-09-26 09:22:46.892361 UTC
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            project_number
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 285084264244
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            message_id
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 1695720166896369
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            instance_id
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : e3T63YhwgU2bjW-wIrybM1
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            message_type
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : DATA_MESSAGE
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            sdk_platform
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : IOS
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            app_name
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : com.lantik.bizkaiup
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            collapse_key
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            priority
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 5
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            ttl
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 2419200
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            topic
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : pl-1
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            bulk_id
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 8114049542586846236
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            device_recently_active
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : false
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            event
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : MESSAGE_ACCEPTED
                            </Typography>
                        </Box>
                        <Box>
                            <Typography display="inline" id="modal-modal-description" sx={{ mt: 2, fontWeight: 'bold' }}>
                            analytics_label
                            </Typography>
                            <Typography display="inline" id="modal-modal-description">
                                : 
                            </Typography>
                        </Box>

                    </Box>
                </Modal>

            </div>
        </>
    );
};

export default NotificationLogs;