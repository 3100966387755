import {FormattedMessage} from 'react-intl';
import React, { useState} from "react";
import {requestSendNotification} from '../../services/NotificationService'

const LabPushNotification = (props) => {

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [topic, setTopic] = useState("All");

  function send() {
    const data = {     
      id:id,
      title: title,
      token: "",
      body: text,
      topic: topic
    }
    requestSendNotification(data);
  }

    return(
      <div className='mainDiv' style={{paddingTop: 15, paddingRight: 15}}>

        {/* Titulo */}
        <label className="title">
            <i className="flask icon"/>
            <FormattedMessage id="biblioteca.titlee" defaultMessage="Notificaciones push (Topic)" />
        </label>

        {/* <h1>Push Notifications</h1> */}
        <div className="ui form" style={{paddingLeft: 15, paddingTop: 25}}>
            <label>Notification ID</label>
            <input 
            type="text"
            value={id}
            onChange= {(e) => setId(e.target.value)}
            />
        </div>
        <div className="ui form" style={{paddingLeft: 15, paddingTop: 15}}>
            <label>Titulo</label>
            <input 
            type="text"
            value={title}
            onChange= {(e) => setTitle(e.target.value)}
            />
        </div>
        <div className="ui form" style={{paddingLeft: 15, paddingTop: 15}}>
            <label>Texto</label>
            <input 
            type="text"
            value={text}
            onChange= {(e) => setText(e.target.value)}
            />
        </div>
        <div className="ui form" style={{paddingLeft: 15, paddingTop: 15}}>
            <label>Topic</label>
            <input 
            type="text"
            value={topic}
            onChange= {(e) => setTopic(e.target.value)}
            />
        </div>
        <div style={{paddingLeft: 15, paddingTop: 25, paddingBottom: 15}}>
          <button className="ui lantik-color-button button"  onClick={() => send() }>Mandar</button>
        </div>
      </div>
    )
  }
export default LabPushNotification;