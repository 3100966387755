import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import UserService from "./services/UserService";
import {LangProvider} from './context/langContext';


const renderApp = () => ReactDOM.render(
                            <LangProvider> 
                                <App/>           
                            </LangProvider>
                        , document.getElementById('root'));

//renderApp();       

const checkSolidusUser = () => {
    let username = UserService.getUsername();
    if(username !== "solidus"){
        renderApp();
    }else {
        UserService.doLogout();
    }
}

UserService.initKeycloak(checkSolidusUser);
